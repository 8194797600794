/**
 * @author Amol Rathod
 * 
 * @description : This hook detects if the web page is being displayed within an iframe on the Canvas LMS (Learning Management System).
 It communicates with the parent window using postMessage to adjust the frame height and sets local storage flags accordingly.
 */

/**
 * @returns {boolean} isCanvas - A boolean indicating whether the web page is displayed within a Canvas iframe.
 *
 */

import { useEffect, useState } from "react";

const useCanvasDetection = () => {
  // State to track if the web page is displayed within a Canvas iframe
  const [isCanvas, setIsCanvas] = useState(
    localStorage.getItem("canvas") === "true"
  );

  useEffect(() => {
    const url = new URL(window.location.href);

    // Use postMessage to communicate with parent window and adjust frame height
    if (window.postMessage) {
      window.top.postMessage(
        {
          subject: "lti.frameResize",
          height: 600,
        },
        "*"
      );

      if (
        sessionStorage.getItem("canvas") &&
        sessionStorage.getItem("canvas") === "true"
        
      ) {
        // Set the value only if it hasn't been set in local storage yet
        //  localStorage.setItem("canvas", "true");
        setIsCanvas(true);
        
        //     localStorage.setItem("qz2.0", "true");
      } else {
        //    localStorage.setItem("canvas", "false");
        setIsCanvas(false);
        //    localStorage.removeItem("qz2.0");
      }
    }
  }, [isCanvas]); // Add isCanvas as a dependency for useEffect

  return isCanvas;
};

export default useCanvasDetection;
