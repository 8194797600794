import axios from "../api/axios";

/**
 * Sends a rating for a document to the server.
 * @param {string} Emailid - The email ID of the user providing the rating.
 * @param {number} ratingGiven - The rating given for the document (e.g., a numeric value).
 * @param {string} doc_id - The ID of the document being rated.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the API request.
 */
export const rateYourQuestions = async (Emailid, ratingGiven, doc_id) => {
  try {
    // Send a POST request to the server to rate the document.
    const response = await axios.post("/rate_question_answer", {
      Emailid,
      ratingGiven,
      doc_id,
    });

    // Return the response object from the server.
    return response; // Alternatively, you can extract and return specific data from the response.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
