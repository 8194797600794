import axios from "../api/axios";

/**
 * @author : Amol Rathod
 *
 * @description : This function sends a POST request to the server to store a set of generated questions, associated with a specific document and user email. The questions to be saved include short questions, multiple-choice questions (MCQs), fill-in-the-blank questions (FIBs), interpretive questions, and additional notes. The server is expected to handle the storage and association of these questions in its database.
 */

/**
 * @param {object} generateQuestions - An object containing the generated questions, including short questions, MCQs, FIBs, interpretive questions, and notes.
 * @param {string} docId - The unique identifier of the document for which the questions are generated.
 * @param {string} email - The email address of the user responsible for generating and saving the questions.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server, indicating the success or status of the save operation.
 * @throws {Error} - If an error occurs during the API request or processing on the server-side.
 */
export const saveQuestions = async (
  short_questions,
  mcqs_questions,
  true_false_questions,
  fib_questions,
  descriptive_questions,
  interpretive_questions,
  notes,
  doc_id,
  email,
  no_ques
) => {
  try {
    // Send a POST request to save questions.
    const response = await axios.post("/save_QuestionAnswer", {
      short_questions,
      mcqs_questions,
      true_false_questions,
      fib_questions,
      descriptive_questions,
      interpretive_questions,
      notes,
      doc_id,
      email,
      no_ques
    });

    // Return the response object from the server.
    return response; // You can handle the response as needed.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
