import React, { useState, useMemo, useCallback } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import StepProgress from "../../components/stepper/StepProgress";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import CanvasLogo from "../../../assets/images/canvasLogo.png";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import "../../../assets/styles/Common.css";
import { useFibQuestions } from "../../hooks/QuestionPage/useFibQuestions";
import { useMcqQuestions } from "../../hooks/QuestionPage/useMcqQuestions";
import {
  useShortQuestions,
} from "../../hooks/QuestionPage/useShortQuestions";
import { useTrueOrFalseQuestions } from "../../hooks/QuestionPage/useTrueOrFalseQuestions";
import { useWhQuestions } from "../../hooks/QuestionPage/useWhQuestions";
import { useInterpretiveQuestions } from "../../hooks/QuestionPage/useInterpretiveQuestions";
import useTotalQuestionIdeas from "../../hooks/QuestionPage/useTotalQuestionIdeas";
import McqQuestionsRenderer from "../../components/questionRenderer/QuestionIdeas/McqQuestionsRenderer";
import FibQuestionsRenderer from "../../components/questionRenderer/QuestionIdeas/FibQuestionsRenderer";
import TrueOrFalseQuestionsRenderer from "../../components/questionRenderer/QuestionIdeas/TrueOrFalseQuestionRenderer";
import WhQuestionsRenderer from "../../components/questionRenderer/WhQuestions/WhQuestionsRenderer";
import InterpretiveQuestionsRenderer from "../../components/questionRenderer/InterpretiveQuestions/InterpretiveQuestionsRenderer";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
} from "../../components/toastify/AllToastifyErrors";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import newQuizzeLog from "../../../assets/images/canvasNewQuizzeimg.png";
import wordLogo from "../../../assets/images/wordLogo.png";
import pdfLogo from "../../../assets/images/pdfLogo1.png";
import textLogo from "../../../assets/images/textLogo.png";
import qtiLogo from "../../../assets/images/QuestionPage/qtiLogo4.png";
import commonCartridge from "../../../assets/images/canvas-zip.png"

import { jsPDF } from "jspdf";
import RateYourQuestionsModal from "../../components/modals/RateYourQuestionsModal";
import { logoBase64 } from "../../../assets/images/base64Images/quillionzBase64.js";
import { useDescriptiveQuestions } from "../../hooks/QuestionPage/useDescriptiveQuestions";
import { saveQuestions } from "../../api/saveQuestions";
import { fetchUsersAllQuestions } from "../../api/fetchUsersAllQuestions";

const Question = () => {
  // State for managing active tab and various sets of questions
  const [activeTab, setActiveTab] = useState("first");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isRateYourQuestionModalOpen, setIsRateYourQuestionModalOpen] =
    useState(false);

  const [isShortAnswerClicked, setIsShortAnswerClicked] = useState(true);

  const [isInterpretiveQuestionsClicked, setIsInterpretiveQuestionsClicked] =
    useState(true);

  const [isNotesClicked, setIsNotesClicked] = useState(true);

  const [isMultipleChoiceClicked, setIsMultipleChoiceClicked] = useState(true);

  const [isOnlyShortQAClicked, setIsOnlyShortQAClicked] = useState(true);

  const [isOnlyRecallQAClicked, setIsOnlyRecallQAClicked] = useState(true);

  // Set the active step value to 4 for StepProgress component
  const activeStep = 4;

  // Access the generatedQuestions from the location
  const location = useLocation();

  const generatedQuestionsFromSubmitContent =
    location.state?.generatedQuestions;

  const cameFromHistoryPage = location.state?.historyPage;

  let title = localStorage.getItem("title");

  const individualTitleFromHistory = location.state?.title;

  const totalQuestionsGenerated = location?.state?.totalQuestionsGenerated;

  const individualDocIdFromHistory = location.state?.doc_id;

  // Used to store the user remaining question sets
  const userQuestionsSets = location.state?.userAttempts;


  localStorage.setItem(
    "UserAttempts",
    JSON.stringify(generatedQuestionsFromSubmitContent?.updatedAttempts) ||
      userQuestionsSets
  );

  const newQuizzes = location.state.newQuizzesjson;

  const userRemainingAttempts = localStorage.getItem("UserAttempts");

  if (userRemainingAttempts == "undefined") {
    localStorage.setItem("UserAttempts", 0);
  }

  let [totalQuestionNumber, setTotalQuestionNumber] = useState("");

  const [totalWh, setTotalWh] = useState("");

  // State for managing the visibility of the custom-scrollbar div
  const [isScrollbarVisible, setScrollbarVisibility] = useState(false);

  // Function to handle tab clicks and change active tab
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setScrollbarVisibility(!isScrollbarVisible);
  };

  // Function to handle deleting questions from questionIdeas

  // Mcq Questions hook
  let {
    mcqsQuestions,
    handleMcqQuestionsEdit,
    handleMcqQuestionDelete,
    handleMcqQuestionChange,
    handleOptionEdit,
    handleOptionChange,
    handleOptionBlur,
    editingOptionIndices,
  } = useMcqQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  // Short Questions hook
  const { shortQuestions } = useShortQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  // Fill in the black Questions hook
  let {
    fibQuestions,
    handleFibQuestionChange,
    handleFibAnswerChange,
    handleFibQuestionsEdit,
    handleFibAnswersEdit,
    handleFibQuestionDelete,
  } = useFibQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  // True or False Questions hook
  let {
    trueFalseQuestions,
    handleTrueOrFalseQuestionsEdit,
    handleTrueOrFalseQuestionDelete,
    handleTrueOrFalseQuestionChange,
  } = useTrueOrFalseQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  // Descriptive Questions Hook
  const { descriptiveQuestions } = useDescriptiveQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  const mergedObjectiveQuestion = useMemo(() => {
    if (!isMultipleChoiceClicked) return fibQuestions;
    if (!isOnlyRecallQAClicked)
      return [...mcqsQuestions, ...trueFalseQuestions];
    return [...mcqsQuestions, ...fibQuestions, ...trueFalseQuestions];
  }, [
    mcqsQuestions,
    fibQuestions,
    trueFalseQuestions,
    isMultipleChoiceClicked,
    isOnlyRecallQAClicked,
  ]);

  // Wh Questions hook
  let {
    whQuestions,
    handleShortWhQuestionChange,
    handleShortWhAnswerChange,
    handleShortWhQuestionsEdit,
    handleShortWhAnswersEdit,
    handleShortWhQuestionDelete,
  } = useWhQuestions(generatedQuestionsFromSubmitContent, setTotalWh, totalWh);
  //  State to manage the notes content
  let [notesContent, setNotesContent] = useState(
    generatedQuestionsFromSubmitContent?.response?.notes ||
      generatedQuestionsFromSubmitContent?.questionset?.notes ||
      ""
  );

  const getUniqueListBy = useCallback((array) => {
    return Array.from(new Set(array.map(JSON.stringify))).map(JSON.parse);
  }, []);

  let mergedDescriptiveQuestion = useMemo(() => {
    const merged = getUniqueListBy(
      [...whQuestions, ...descriptiveQuestions],
      "question"
    );
    return merged;
  }, [whQuestions, descriptiveQuestions]);

  // Extracting questions and answers from generatedQuestionsFromSubmitContent
  //  useEffect(() => {  //   if (!generatedQuestionsFromSubmitContent) return;
  // });
  const [hideInterpretiveQuestions, setHideInterpretiveQuestions] =
    useState(false);

  const handleRecallButtonClick = () => {
    setHideInterpretiveQuestions(true);
  };

  // Interpretive Questions hook
  let {
    interpretiveQuestions,
    handleInterpretiveQuestionChange,
    handleInterpretiveAnswerChange,
    handleInterpretiveQuestionsEdit,
    handleInterpretiveAnswersEdit,
    handleInterpretiveQuestionDelete,
  } = useInterpretiveQuestions(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber,
    totalQuestionNumber
  );

  // Total Number of Questions Ideas
  useTotalQuestionIdeas(
    generatedQuestionsFromSubmitContent,
    setTotalQuestionNumber
  );

  let multipleChoiceQuestions =
    mcqsQuestions.length + trueFalseQuestions.length;

  // Reduce the length of Questions ideas if "Multiple Choice" clicked
  if (!isMultipleChoiceClicked) {
    totalQuestionNumber -= multipleChoiceQuestions;
  }

  // Reduce the length of Questions ideas if "Short Answer" clicked
  if (!isOnlyShortQAClicked) {
    totalQuestionNumber -= shortQuestions.length;
  }

  // Reduce the length of Questions ideas if "Recall" clicked
  if (!isOnlyRecallQAClicked) {
    totalQuestionNumber -= fibQuestions.length;
  }

  const tabData = [
    { id: "first", label: "Objective Questions", total: totalQuestionNumber },
    {
      id: "second",
      label: whQuestions.length <= 1 ? "Wh Question" : "Wh Questions",
      total: isShortAnswerClicked ? whQuestions.length : 0,
    },
    {
      id: "third",
      label:
        interpretiveQuestions.length <= 1
          ? "Interpretive Question"
          : "Interpretive Questions",
      total: isInterpretiveQuestionsClicked ? interpretiveQuestions.length : 0,
    },
    {
      id: "fourth",
      label: "Notes",
    },
  ];

  // Function to handle Rate Your Questions
  const handleRateYourQuestions = () => {
    setIsRateYourQuestionModalOpen(true);
  };

  // localStorage.setItem(
  //   "no_ques",
  //   generatedQuestionsFromSubmitContent?.response?.no_ques
  // );

  // const totalGeneratedQuestions = localStorage.getItem(
  //   "totalQuestionsGenerated"
  // );

  const [questionsSavedCount, setQuestionsSavedCount] = useState(0);
  // localStorage.setItem("totalQuestionsGenerated", generatedQuestionsFromSubmitContent?.response?.no_ques);

  const doc_id = localStorage.getItem("doc_id");
  const email = localStorage.getItem("email");

  const totalQuestions =
    totalQuestionsGenerated || localStorage.getItem("totalQuestionsGenerated");

  // Function to save the questions to database
  const handleQuestionsSaveToDB = async () => {
    try {
      setLoading(true);

      const descriptiveQuestion = descriptiveQuestions.filter((d) => {
        return whQuestions.find((w) => w.question === d.question);
      });

      // Save the questions and call user questions history API
      let totalQues =
        mergedDescriptiveQuestion.length +
        mcqsQuestions.length +
        trueFalseQuestions.length +
        fibQuestions.length +
        interpretiveQuestions.length;

      const saveapi = await saveQuestions(
        whQuestions,
        mcqsQuestions,
        trueFalseQuestions,
        fibQuestions,
        descriptiveQuestion,
        interpretiveQuestions,
        notesContent,
        doc_id,
        email,
        totalQues
      );

      // console.log("Saved Questions : ", saveQuestionsResponse);
      const userQuestionsHistory = await fetchUsersAllQuestions(email);
      setQuestionsSavedCount(questionsSavedCount + 1);

      // console.log("Response of '/fetchUserdata' API : ", userQuestionsHistory);
      // localStorage.setItem("usersQuestionHistory", response.data.doc_ids);
      localStorage.setItem(
        "usersQuestionHistory",
        JSON.stringify(userQuestionsHistory.data.doc_list)
      );

      commonSuccessToastMessage("Success! Your questions have been saved.");

      setLoading(false);
      // window.location.reload();
    } catch (error) {
      console.log("Error while saving the questions : ", error);
      commonErrorToastMessage(
        "Oops! Failed to save your questions. Please try again later."
      );
      setLoading(false);
    }
  };

  localStorage.setItem(
    "totalQuestionsGenerated",
    totalQuestionNumber + whQuestions.length + interpretiveQuestions.length
  );

  const allInterpretiveDeleted = interpretiveQuestions.length === 0;

  localStorage.setItem("allInterpretiveDeleted", allInterpretiveDeleted);

  const exportQuestions = async (e , type) => {
    setLoading(true);
    const { dataset } = e.target;
  const exportType = dataset.exportType || type; 
    let endpoint = "";
    exportType === "qti" && (endpoint = "api/lti/export_to_canvas");
    exportType === "newQuizzes" &&
      (endpoint = "api/lti/export_to_canvas_new_quizze");
    exportType === "doc" && (endpoint = `api/export/${exportType}`);
    exportType === "pdf" && (endpoint = `api/export/${exportType}`);
    exportType === "txt" && (endpoint = `api/export/${exportType}`);
    exportType === "qtiFormat" && (endpoint = `api/export/${exportType}`);
    exportType === "qtiPackageFormat" && (endpoint = `api/lti/export_to_canvas?exportType=${exportType}`);

    if (!isInterpretiveQuestionsClicked) {
      interpretiveQuestions = [];
  }
  
  if (!isMultipleChoiceClicked){
    mcqsQuestions = [];
    trueFalseQuestions=[];
  }

  if(!isNotesClicked) {
    notesContent =[]
  }

  if(!isOnlyRecallQAClicked){
    fibQuestions =[]
  }

  if(!isShortAnswerClicked){
    mergedDescriptiveQuestion=[]
  }
    const allQuestions = [
      ...mcqsQuestions,
      ...fibQuestions,
      ...trueFalseQuestions,
      // ...whQuestions,
      ...mergedDescriptiveQuestion,
      ...interpretiveQuestions,
    ];

    const exportToTxt = () => {
      let txtContent = "Quillionz\n"; // Title
      // txtContent += "Powered by ChatGPT\n"; // Subtitle
      txtContent += "====================\n\n"; // Separator

      allQuestions.forEach((question, index) => {
        txtContent += `Q${index + 1}: ${question.question}\n`;

        if (question.options) {
          question.options.forEach((option, optIndex) => {
            txtContent += `${optIndex + 1}): ${option}\n`;
          });
        }

        txtContent += `Answer: ${question.answer}\n\n`;
      });

      // Add notes section at the end
      txtContent += "Notes:\n";
      txtContent += notesContent + "\n"; // Add the content of notes

      const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        cameFromHistoryPage ? individualTitleFromHistory : title
      }.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    };

    // Export to PDF :
    const exportToPDF = () => {
      const pdf = new jsPDF();

      // Function to add header on the first page
      const addHeader = () => {
        setLoading(true);
        pdf.setFontSize(22);

        // Add a logo to the top middle of the PDF
        const imgWidth = 50; // Set the width of the logo
        const imgHeight = 20; // Set the height of the logo

        // Calculate the x position to center the logo
        const xPosition = (pdf.internal.pageSize.width - imgWidth) / 2;

        const yPosition = 10; // Set the y position of the logo

        pdf.addImage(
          logoBase64,
          "PNG",
          xPosition,
          yPosition,
          imgWidth,
          imgHeight
        ); // Add the logo to the PDF

        const textYPosition = yPosition + imgHeight + 15; // Space between image and title

        pdf.setFontSize(14);
        // Set the title text color to red
        pdf.setTextColor(244, 132, 62); // Title color
        // Calculate maximum width for the title
        const maxWidth = pdf.internal.pageSize.width - 30; // Subtract margins

        // Title
        pdf.setFontSize(13);
        pdf.setTextColor(244, 132, 62); // Title color

        // Split title if it's too long
        const splitTitle = pdf.splitTextToSize(
          `Title: ${cameFromHistoryPage ? individualTitleFromHistory : title}`,
          maxWidth
        );
        pdf.text(splitTitle, 15, textYPosition, { align: "left" });

        // Adjust Y offset for the following elements
        let nextElementYPosition = textYPosition + splitTitle.length * 10; // Adjust this 10 based on your line height

        // Total Questions
        pdf.setFontSize(12);

        const totalQuestionsText = `Total Questions : ${allQuestions.length}`;
        const totalQuestionsWidth = pdf.internal.pageSize.width / 2 - 50; // Adjust width for better positioning
        pdf.text(
          totalQuestionsText,
          pdf.internal.pageSize.width / 2 - 52, // Adjust the x-position
          textYPosition + 15, // Space between title and total questions
          { align: "right" }
        );

        pdf.setTextColor(0, 0, 0); // Red color
        pdf.line(
          15,
          textYPosition + 20,
          pdf.internal.pageSize.width - 15,
          textYPosition + 20
        ); // draw horizontal line
      };

      addHeader();

      // Adjust yOffset to start rendering questions after the horizontal line
      let yOffset = 50 + 4 + 4 + 20 + 4;
      const xOffset = 15; // horizontal offset

      allQuestions.forEach((question, index) => {
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "bold");

        const questionText = `Q${index + 1}. ${question.question}`;

        const splitQuestion = pdf.splitTextToSize(questionText, 165);

        pdf.text(splitQuestion, xOffset, yOffset);
        yOffset += splitQuestion.length * 7;
        pdf.setFont("helvetica", "normal");

        if (question.options) {
          question.options.forEach((option, optIndex) => {
            const optionText = `${optIndex + 1}): ${option}`;
            const splitOption = pdf.splitTextToSize(optionText, 155);
            pdf.text(splitOption, xOffset + 5, yOffset);
            yOffset += splitOption.length * 7;
          });
        }

        pdf.setFont("helvetica", "normal");

        const answerText = `Answer: ${question.answer}`;

        const splitAnswer = pdf.splitTextToSize(answerText, 165);
        pdf.text(splitAnswer, xOffset + 5, yOffset);
        yOffset += splitAnswer.length * 7;

        yOffset += 10; // space between questions

        // Check if we need to add a new page
        if (yOffset > 220) {
          pdf.addPage();
          yOffset = 30;
        }
      });

      // Add Notes Header
      pdf.setFontSize(14);
      pdf.text("Notes:", xOffset, yOffset);
      yOffset += 10; // Adjust space after the Notes header

      // Add Notes Content
      pdf.setFontSize(12);
      const splitNotes = pdf.splitTextToSize(notesContent, 165);
      pdf.text(splitNotes, xOffset, yOffset);
      yOffset += splitNotes.length * 7;

      // Function to add footer on each page
      const addFooter = () => {
        pdf.setFontSize(10);
        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          const footerText = `Page ${i}  - Powered by Quillionz`;
          pdf.text(
            footerText,
            pdf.internal.pageSize.width - 15,
            pdf.internal.pageSize.height - 10,
            { align: "right" }
          );
        }
      };

      addFooter();

      // Save the PDF
      pdf.save(
        `${cameFromHistoryPage ? individualTitleFromHistory : title}.pdf`
      );

      setLoading(false);
      setOpen(false);
      return;
    };

    const exportToQTI = () => {
      let txtContent = "";
      const optionLetters = ["A", "B", "C", "D"];

      allQuestions.forEach((question, index) => {
        txtContent += `${question.question}\n`;

        if (question.options) {
          question.options.forEach((option, optIndex) => {
            // Convert option index to A, B, C, D format
            const optionLetter = optionLetters[optIndex];
            txtContent += `${optionLetter}. ${option}\n`;
          });
        }

        // Convert correct answer index to A, B, C, D format
        const correctAnswerLetter = optionLetters[question.answer];
        txtContent += `ANSWER: ${question.answer}\n\n`;
      });

      // You can save qtiXML to a file or send it as needed.

      // For example, to create a downloadable file:
      const blob = new Blob([txtContent], { type: "application/xml" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        cameFromHistoryPage ? individualTitleFromHistory : title
      }.txt`; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blob);

      setLoading(false);
    };
  
  const exportToQTIFolder = async (zipFileData, packageName, data) => {
    try {
      saveAs(data, `${packageName}.zip`);
      const file = data.split("/");
      setTimeout(async () => {
        await axios.delete(process.env.REACT_APP_API_URL + "api/lti/unlink/" + file[file.length - 1]);
      }, 10000);
      console.log('File generation and download triggered successfully.');
    } catch (err) {
      console.error('Error creating ZIP:', err.message);
    }
  };
    try {
      const canvas = await axios.post(
        process.env.REACT_APP_API_URL + endpoint,
        {
          whQuestions: {
            response: {
              true_false_questions: trueFalseQuestions,
              mcqs_questions: mcqsQuestions,
              fib_questions: fibQuestions,
              wh_questions: mergedDescriptiveQuestion,
              interpretive_questions: interpretiveQuestions,
              notes: notesContent
            }
          },
          docId: localStorage.getItem("doc_id") || individualDocIdFromHistory,
          title: localStorage.getItem("title") || individualTitleFromHistory,
        }
      );

      if ([200].includes(canvas.status)) {
        setLoading(false);
        const { data , config} = canvas;
        if (exportType === "qti") {
          commonSuccessToastMessage("Quizzes created successfully.");
          setOpen(false);
          setLoading(false);
          return;
        }

        //Export to Common Cartridge
        if (exportType === "qtiPackageFormat") {
            exportToQTIFolder(config.data, title, data);
            commonSuccessToastMessage("Common Cartridge successfully exported.");
            setOpen(false);
            setLoading(false);
            return;
        }
        // Export to DOC
        if (exportType === "doc") {
          debugger
          const link = document.createElement("a");
          const blob = new Blob(["\ufeff", data.data.content], {
            type: "application/msword",
          });
          var url = URL.createObjectURL(blob);
          link.setAttribute("download", `${title}.doc`); // Set the title here
          link.setAttribute("href", url);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blob);
        }

        // Export to PDF
        if (exportType === "pdf") {
          exportToPDF();
          commonSuccessToastMessage(
            "Questions are successfully exported to PDF format."
          );
        }

        // Export to TEXT
        if (exportType === "txt") {
          exportToTxt();
          commonSuccessToastMessage(
            "Questions are successfully exported to text format."
          );
        }

        if (exportType === "qtiFormat") {
        
          exportToQTI();
          commonSuccessToastMessage(
            "Questions are successfully exported to .qti format."
          );
        }      
        setOpen(false);

        setLoading(false);
      }
    } catch (error) {
      setOpen(false);
      setLoading(false);
      commonErrorToastMessage("Error while exporting the questions.");
      console.error("Error while exporting : ", error);
    } finally {
      if (exportType === "newQuizzes") {
        commonSuccessToastMessage("New Quizzes created successfully.");
        setOpen(false);

        setLoading(false);
        return;
      }
      setOpen(false);

      setLoading(false);
    }
  };

  const isCanvas = useCanvasDetection();
  return (
    <div className={`${isCanvas ? " h-[75vh]" : " h-[81vh]"}`}>
      <AppNavigation />
      {/* Top-level container */}
      <div className={`flex bg-white ${isCanvas ? " h-[75vh]" : " h-[81vh]"}`}>
        {/* Left section - Question Container */}
        <div
          className={`w-full p-4 rounded-2xl question-container ${
            isCanvas ? " h-[75vh]" : " h-[81vh]"
          }`}
        >
          {/* Question Type Tabs */}
          <div className="question-type ">
            <div className="flex place-content-around ">
              {/* Rendering tabs with labels and total question counts */}
              {tabData.map((tab) => (
                <div>
                  <div
                    key={tab.id}
                    onClick={() => handleTabClick(tab.id)}
                    className={`cursor-pointer px-8 py-2  border border-transparent  font-medium ${
                      activeTab === tab.id
                        ? "border-b-2  border-b-[#0c3f5a] "
                        : "text-[#f4843e] hover:border-gray-300"
                    } transition duration-300 ease-in-out  ${
                      tab.id !== "first" ? "text-[#f4843e] " : "text-[#5E626A] "
                    } `}
                  >
                    {tab.label}
                    {tab.label === "Notes"
                      ? isCanvas
                        ? ""
                        : // <span className="beta-ribbon left-[82%] md:lg:left-[91%] lg:left-[71%]">
                          //   BETA
                          // </span>
                          ""
                      : ""}
                    {tab.label !== "Notes" ? (
                      <>
                        <span
                          className={`w-6 h-6 place-content-center px-3 py-1 text-center rounded-full ml-4 items-center justify-center ${
                            tab.id === "first"
                              ? " bg-[#0c3f5a] text-white"
                              : "bg-[#f4843e] text-white"
                          }`}
                        >
                          {tab.total}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Main content area for displaying questions and answers */}
          <div className={`${isCanvas ? "h-[65vh]" : "h-[55vh]"} pb-2 `}>
            {/* Conditionally rendering content based on the active tab */}
            {/* Section for rendering question ideas */}
            {activeTab === "first" && (
              <div
                className={`border-t-[1px] border-b-[1px] animate-fade-slide-down p-5 mx-auto my-area custom-scrollbar overflow-x-hidden overflow-y-auto   ${
                  isCanvas ? "h-[65vh]" : "h-[55vh]"
                }`}
              >
                {totalQuestionNumber !== 0 ? (
                  <>
                    {mergedObjectiveQuestion.length &&
                      mergedObjectiveQuestion.map((q, i) => (
                        <div key={i}>
                          {/* TODO : DO the same for others */}
                          {/* Rendering MCQ questions */}
                          {q.category === "mcq" && (
                            <div
                              className={`${
                                isMultipleChoiceClicked ? "" : "hidden"
                              }`}
                            >
                              <McqQuestionsRenderer
                                generatedQuestionsFromSubmitContent={
                                  generatedQuestionsFromSubmitContent
                                }
                                setTotalQuestionNumber={setTotalQuestionNumber}
                                totalQuestionNumber={totalQuestionNumber}
                                activeStep={activeStep} // Pass other props here
                                handleMcqQuestionsEdit={handleMcqQuestionsEdit}
                                handleMcqQuestionDelete={
                                  handleMcqQuestionDelete
                                }
                                handleMcqQuestionChange={
                                  handleMcqQuestionChange
                                }
                                handleOptionEdit={handleOptionEdit}
                                handleOptionChange={handleOptionChange}
                                handleOptionBlur={handleOptionBlur}
                                editingOptionIndices={editingOptionIndices}
                                mcqsQuestions={mcqsQuestions}
                                question={q}
                                index={i}
                              />
                            </div>
                          )}

                          {/* Rendering FIB (Recall) questions */}
                          {q.category === "fib" && (
                            <div
                              className={`${
                                isOnlyRecallQAClicked ? "" : "hidden"
                              }`}
                            >
                              <FibQuestionsRenderer
                                generatedQuestionsFromSubmitContent={
                                  generatedQuestionsFromSubmitContent
                                }
                                setTotalQuestionNumber={setTotalQuestionNumber}
                                totalQuestionNumber={
                                  totalQuestionNumber + mcqsQuestions.length
                                }
                                activeStep={activeStep} // Pass other props here
                                handleFibQuestionChange={
                                  handleFibQuestionChange
                                }
                                handleFibAnswerChange={handleFibAnswerChange}
                                handleFibQuestionsEdit={handleFibQuestionsEdit}
                                handleFibAnswersEdit={handleFibAnswersEdit}
                                handleFibQuestionDelete={
                                  handleFibQuestionDelete
                                }
                                fibQuestions={fibQuestions}
                                question={q}
                                index={i}
                              />
                            </div>
                          )}

                          {/* Rendering True or False Questions */}
                          {q.category === "truefalse" && (
                            <div
                              className={`${
                                isMultipleChoiceClicked ? "" : "hidden"
                              }`}
                            >
                              <TrueOrFalseQuestionsRenderer
                                generatedQuestionsFromSubmitContent={
                                  generatedQuestionsFromSubmitContent
                                }
                                setTotalQuestionNumber={setTotalQuestionNumber}
                                totalQuestionNumber={totalQuestionNumber}
                                activeStep={activeStep} // Pass other props here
                                handleTrueOrFalseQuestionsEdit={
                                  handleTrueOrFalseQuestionsEdit
                                }
                                handleTrueOrFalseQuestionDelete={
                                  handleTrueOrFalseQuestionDelete
                                }
                                handleTrueOrFalseQuestionChange={
                                  handleTrueOrFalseQuestionChange
                                }
                                trueFalseQuestions={trueFalseQuestions}
                                question={q}
                                index={i}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </>
                ) : (
                  <div
                    className={`flex flex-col justify-center items-center align-middle  ${
                      isCanvas ? "h-[65vh]" : "h-[48vh]"
                    }`}
                  >
                    {/*
                     */}
                  </div>
                )}
              </div>
            )}
            {/* Section for rendering "Wh" questions (which is the combination of Short and Descriptive Questions) */}
            {activeTab === "second" && (
              <div
                className={`border-t-[1px] border-b-[1px] animate-fade-slide-down p-5   mx-auto my-area custom-scrollbar overflow-x-hidden overflow-y-auto ${
                  isCanvas ? "h-[65vh]" : "h-[55vh]"
                }`}
              >
                {/* Rendering "Wh" questions with editing capabilities */}

                <div className={`${isShortAnswerClicked ? "" : "hidden"}`}>
                  {mergedDescriptiveQuestion.length !== 0 ? (
                    <WhQuestionsRenderer
                      generatedQuestionsFromSubmitContent={
                        generatedQuestionsFromSubmitContent
                      }
                      totalQuestionNumber={totalQuestionNumber}
                      setTotalQuestionNumber={setTotalQuestionNumber}
                      activeStep={activeStep}
                      handleShortWhQuestionChange={handleShortWhQuestionChange}
                      handleShortWhAnswerChange={handleShortWhAnswerChange}
                      handleShortWhQuestionsEdit={handleShortWhQuestionsEdit}
                      handleShortWhAnswersEdit={handleShortWhAnswersEdit}
                      handleShortWhQuestionDelete={handleShortWhQuestionDelete}
                      questions={whQuestions}
                    />
                  ) : (
                    // <div
                    //   className={`flex flex-col justify-center items-center align-middle  ${
                    //     isCanvas ? "h-[65vh]" : "h-[48vh]"
                    //   }`}
                    // >
                    //   <div className=" w-full  h-[81vh] m-auto flex flex-col justify-center align-middle items-center place-content-center  px-6 pt-4 rounded-2xl mx-5">
                    //     <div className=" border rounded-xl p-5 shadow-xl hover:shadow-2xl flex flex-col justify-center align-middle items-center place-content-center w-1/2">
                    //       {/* <img src={alertImage} alt="" className="w-1/5 " /> */}

                    //       <i className="fa-solid fa-triangle-exclamation text-red-400 fa-3x"></i>

                    //       <p className="mt-5 text-center font-semibold text-red-400">
                    //         You have deleted all the Wh Questions.
                    //       </p>
                    //     </div>
                    //   </div>
                    // </div>
                    ""
                  )}
                </div>
              </div>
            )}
            {activeTab === "third" && (
              <div
                className={`border-t-[1px] border-b-[1px] animate-fade-slide-down p-5   mx-auto my-area   custom-scrollbar overflow-x-hidden overflow-y-auto ${
                  isCanvas ? "h-[65vh]" : "h-[55vh]"
                }`}
              >
                {/* Rendering Interpretive Questions with editing capabilities */}
                <div
                  className={`${
                    isInterpretiveQuestionsClicked ? "" : "hidden"
                  }`}
                >
                  {generatedQuestionsFromSubmitContent.response
                    ?.interpretive_questions || cameFromHistoryPage ? (
                    <InterpretiveQuestionsRenderer
                      generatedQuestionsFromSubmitContent={
                        generatedQuestionsFromSubmitContent
                      }
                      totalQuestionNumber={totalQuestionNumber}
                      setTotalQuestionNumber={setTotalQuestionNumber}
                      activeStep={activeStep}
                      handleInterpretiveQuestionChange={
                        handleInterpretiveQuestionChange
                      }
                      handleInterpretiveAnswerChange={
                        handleInterpretiveAnswerChange
                      }
                      handleInterpretiveQuestionsEdit={
                        handleInterpretiveQuestionsEdit
                      }
                      handleInterpretiveAnswersEdit={
                        handleInterpretiveAnswersEdit
                      }
                      handleInterpretiveQuestionDelete={
                        handleInterpretiveQuestionDelete
                      }
                      questions={interpretiveQuestions}
                    />
                  ) : (
                    <div
                      className={`flex flex-col justify-center items-center align-middle  ${
                        isCanvas ? "h-[65vh]" : "h-[48vh]"
                      }`}
                    >
                      <div className=" w-full  h-[81vh] m-auto flex flex-col justify-center align-middle items-center place-content-center  px-6 pt-4 rounded-2xl mx-5">
                        <div className=" border rounded-xl p-5 shadow-xl hover:shadow-2xl flex flex-col justify-center align-middle items-center place-content-center w-1/2">
                          {/* <img src={alertImage} alt="" className="w-1/5 " /> */}
                          <i className="fa-solid fa-circle-exclamation text-red-400 fa-3x"></i>

                          <p className="mt-5 text-center font-semibold text-red-400">
                            Interpretive questions are generated for content
                            with over 1000 words.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Section for rendering notes */}
            {activeTab === "fourth" && (
              <div
                className={`border-t-[1px] border-b-[1px] animate-fade-slide-down p-5 mx-auto  ${
                  isCanvas ? "h-[65vh]" : "h-[55vh]"
                } `}
              >
                <textarea
                  className={`w-full h-full  p-2 hover:border-[#f4833d] border-2 border-white hover:border-dotted rounded outline-none resize-none custom-scrollbar overflow-none ${
                    isNotesClicked ? "" : "hidden"
                  }`}
                  value={notesContent}
                  onChange={(e) => setNotesContent(e.target.value)}
                />
              </div>
            )}
          </div>

          {/* Bottom section with a button to save the questions */}
          <div className="pt-4 h-9">
            <div>
              <p className="text-[#777e8d] font-semibold px-2">
                To edit a question or answer, just click on it.
              </p>
            </div>

            <div className="flex place-content-end gap-5">
              <div className="flex gap-5">
                {!isCanvas && (
                  <div className="flex gap-5">
                    <div>
                      <Tooltip
                        title="Rate the Questions"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          onClick={handleRateYourQuestions}
                          className="rounded border border-[#f4833d] text-[#f4833d] hover:bg-[#f4833d] hover:text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                        >
                          Rate the Questions
                        </button>
                      </Tooltip>
                    </div>

                    {/* Currently we are commenting this. Later we need it. */}
                    {/* <div>
                      <Tooltip
                        title="Create QuilliQuiz"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          // onClick={handleSave}
                          className="rounded bg-[#224575] hover:opacity-80 text-white px-4 py-2  mt-1 transition duration-300 ease-in-out"
                        >
                          {" "}
                          Create QuilliQuiz
                        </button>
                      </Tooltip>
                    </div> */}

                    {/* Currently we are commenting this. Later we need it. */}
                    {/* <div>
                      <Tooltip
                        title="Create Interactive Quiz"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          // onClick={handleSave}
                          className="rounded bg-[#224575] hover:opacity-80 text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                        >
                          Create Interactive Quiz
                        </button>
                      </Tooltip>
                    </div> */}
                  </div>
                )}

                <div className="flex gap-5">
                  <div>
                    <Tooltip
                      title="Export Question"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        onClick={() => setOpen(true)}
                        className="rounded hover:opacity-80 bg-[#f4833d] text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                      >
                        Export Questions
                      </button>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip
                      title="Save your questions"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        onClick={handleQuestionsSaveToDB}
                        className="rounded hover:opacity-80 bg-[#f4833d] text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                      >
                        Save
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right section - Step Progress */}
        <div className="w-auto h-auto step-progress">
          {/* Pass the activeStep value to the StepProgress component */}
          <StepProgress
            activeStep={activeStep}
            mcqQuestion={mcqsQuestions.length + trueFalseQuestions.length}
            whQuestions={whQuestions.length}
            shortAnswerQuestion={shortQuestions.length}
            descriptiveQuestion={interpretiveQuestions.length}
            recallQuestion={fibQuestions.length}
            setHideInterpretiveQuestions={setHideInterpretiveQuestions}
            isShortAnswerClicked={isShortAnswerClicked}
            setIsShortAnswerClicked={setIsShortAnswerClicked}
            isInterpretiveQuestionsClicked={isInterpretiveQuestionsClicked}
            setIsInterpretiveQuestionsClicked={
              setIsInterpretiveQuestionsClicked
            }
            isOnlyShortQAClicked={isOnlyShortQAClicked}
            setIsOnlyShortQAClicked={setIsOnlyShortQAClicked}
            isOnlyRecallQAClicked={isOnlyRecallQAClicked}
            setIsOnlyRecallQAClicked={setIsOnlyRecallQAClicked}
            isNotesClicked={isNotesClicked}
            setIsNotesClicked={setIsNotesClicked}
            isMultipleChoiceClicked={isMultipleChoiceClicked}
            setIsMultipleChoiceClicked={setIsMultipleChoiceClicked}
          />
        </div>
      </div>

      <MainFooter />

      <Dialog
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        className=""
        maxWidth="sm"
        fullWidth={true}
        open={open}
        disableBackdropClick={true}
      >
        <DialogTitle className="text-[10px] ">
          <span>Export Questions : </span>
          <Tooltip
            title="Close"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              // className="text-[#6c757d] bg-transparent text-lg float-right"
              className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 float-right rounded-full "
              onClick={() => setOpen(false)}
            >
              <i className="fa-solid fa-xmark"></i>{" "}
            </button>
          </Tooltip>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Stack
              direction="row"
              gap={4}
              className="mb-2 flex gap-8 align-middle place-content-evenly justify-center"
            >
              {isCanvas ? (
                <div className="flex gap-8 align-middle place-content-center justify-center ">
                  <Tooltip
                    title="Export to Canvas"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <Button 
                    onClick={(e)=>exportQuestions(e ,'qti')}
                    className="bg-pink-200" style={{ padding: '2px 4px' }}>
                      <img
                        src={CanvasLogo}
                        alt="Canvas"
                        className="w-16 h-16 rounded-lg border shadow-xl"
                      />
                    </Button>
                  </Tooltip>

                  {/* Export to New Quizzes*/}
                  <Tooltip
                    title="Export to NewQuizzes1"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <Button 
                    onClick={(e)=>exportQuestions(e ,'newQuizzes')}
                    style={{ padding: '2px 4px' }}>
                      <img
                        src={newQuizzeLog}
                        alt="New Quizzes"
                        className="w-16 h-16 rounded-lg border shadow-xl"
                      />
                    </Button>
                  </Tooltip>

                  {/* Export to QTI Format */}
                  <Tooltip
                    title="Export to QTI"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <Button 
                    onClick={(e)=>exportQuestions(e ,'qtiFormat')}
                    style={{ padding: '2px 4px' }}>
                      <img
                        src={qtiLogo}
                        alt="QTI"
                        className="w-16 h-16 rounded-lg border shadow-xl py-1 qti-image"
                      />
                    </Button>
                  </Tooltip>
                </div>
              ) : null}
            </Stack>

            <Stack direction="row" gap={4}>
              <div className="flex gap-8 p-1">
                {/* Export to Word */}
                <Tooltip
                  title="Export to Word"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <Button 
                  onClick={(e)=>exportQuestions(e ,'doc')}
                  style={{ padding: '2px 4px' }}>
                    <img
                      src={wordLogo}
                      alt="Word"
                      className="w-16 h-16 rounded-lg border shadow-xl py-1"
                    />
                  </Button>
                </Tooltip>

                 {/* EXPORT TO QTI ZIP */}

                 {/* isCanvas? " ":
                <Tooltip
                 title="Export to Common Cartridge"
                 arrow
                 placement="top"
                 TransitionComponent={Zoom}
                 TransitionProps={{ timeout: 300 }}
               >
                 <Button>
                   <img
                     data-export-type="commoncartridge"
                     src={commonCartridge}
                     alt="QTI"
                     className="w-16 h-16 rounded-lg border shadow-xl py-1 qti-image"
                   />
                 </Button>
              </Tooltip> */}

                {/* Export to PDF */}
                <Button
                onClick={(e)=>exportQuestions(e ,'pdf')}
                style={{ padding: '2px 4px' }}>
                  <Tooltip
                    title="Export to PDF"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <img
                      src={pdfLogo}
                      alt="PDF"
                      className="w-16 h-16 rounded-lg py-1 border shadow-xl"
                    />
                  </Tooltip>
                </Button>
              </div>

              <div className="flex gap-8">
                {/* Export to Text */}
                <Tooltip
                  title="Export to Text"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <Button
                  onClick={(e)=>exportQuestions(e ,'txt')}
                   style={{ padding: '2px 4px' }} >
                    <img
                      src={textLogo}
                      alt="Text"
                      className="w-16 h-16 rounded-lg border shadow-xl"
                    />
                  </Button>
                </Tooltip>

                {/* Export to QTI format */}
                {isCanvas ? (
                  ""
                ) : (
                  <Tooltip
                    title="Export to QTI"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <Button 
                    onClick={(e)=>exportQuestions(e ,'qtiFormat')}
                    style={{ padding: '2px 4px' }}>
                      <img
                        src={qtiLogo}
                        alt="QTI"
                        className="w-16 h-16 rounded-lg border shadow-xl py-1 qti-image"
                      />
                    </Button>
                  </Tooltip>
                )}

              {isCanvas ? (
                  ""
                ) : (
                  <Tooltip
                    title="Export to Common Cartridge"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <Button 
                    onClick={(e)=>exportQuestions(e ,'qtiPackageFormat')}
                    style={{ padding: '2px 4px' }}>
                      <img
                        src={commonCartridge}
                        alt="QTI"
                        className="w-16 h-16 rounded-lg border shadow-xl py-1 qti-image"
                      />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Render the Share Your Feedback Modal component */}
      {isRateYourQuestionModalOpen && (
        <RateYourQuestionsModal
          open={handleRateYourQuestions}
          onClose={() => setIsRateYourQuestionModalOpen(false)}
        />
      )}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Question;
