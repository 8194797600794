import React from "react";
import "../../../../assets/styles/Common.css";
import { useState, useRef } from "react";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import quillonz_logo from "../../../../assets/images/quillionz_app_logo.png";
import InviteAFriendModal from "../../modals/InviteAFriendModal";
import LogoutDialog from "../../dialogs/LogoutDialog";
import ShareYourFeedbackModal from "../../modals/ShareYourFeedbackModal";
import useCanvasDetection from "../../../hooks/useCanvasDetection";
import GoPremiumModal from "../../modals/GoPremiumModal";
import GoForUnlimitedQuestionsModal from "../../modals/GoForUnlimitedQuestionsModal";
import { fetchUsersAllQuestions } from "../../../api/fetchUsersAllQuestions";
import LoadingOverlay from "../../overlays/LoadingOverlay";
import { logout } from "../../../api/logout";

const AppNavigation = () => {
  const navigate = useNavigate();
  // State variable to control the visibility of the menu on small devices
  const [showMenu, setShowMenu] = useState(false);

  const [logoutDialog, setLogoutDialog] = useState(false);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const [showGoPremiumModal, setShowGoPremiumModal] = useState(false);

  const attemptsFromDatabase = localStorage.getItem("UserAttempts");

  const [remainingAttempt, setRemainingAttempt] =
    useState(attemptsFromDatabase);

  const [showUnlimitedModal, setShowUnlimitedModal] = useState(false);

  const getCanvas = localStorage.getItem("canvas");

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  // Function to handle opening the "Invite a Friend" modal
  const handleInviteFriendClick = () => {
    setShowInviteModal(true);
  };

  const location = useLocation();

  // Function to navigate to 'submitContent'
  const handleNewContent = () => {
    localStorage.removeItem("title");
    localStorage.removeItem("content");
    localStorage.removeItem("doc_id");
    localStorage.removeItem("domain");
    localStorage.removeItem("keywordsResponse");
    localStorage.removeItem("contentReadiness");
    localStorage.removeItem("reviewContentResponse");
    localStorage.removeItem("selectedKeywordsArray");
    localStorage.removeItem("totalQuestionsGenerated");
    localStorage.removeItem("whQuestionsCount");

    if (location.pathname === "/submitcontent") {
      // sessionStorage.setItem("canvas", "true");
      if (!isCanvas) {
        // window.location.reload();
        window.location.href = "/submitcontent";
      } else {
        navigate("/submitcontent");
      }
    } else {
      navigate("/submitcontent");
    }
  };

  // Function to handle opening the "Share Your Feedback" modal
  const handleFeebackClick = () => {
    setShowFeedbackModal(true);
  };

  // Function to handle opening the "Go Premium" modal
  const handleGoPremiumClick = () => {
    setShowGoPremiumModal(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setLogoutDialog(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  // Getting username and userGPTKey
  const username = localStorage.getItem("username");
  const userGPTKey = localStorage.getItem("userGPTKey");

  // Function to handle clicks outside the dropdown container
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Event listener added for clicks outside the dropdown container
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Function to toggle the visibility of the menu on small devices
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Function to toggle the visibility of the dropdown when clicking on "Username"
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Function to handle the click of each option in the dropdown menu
  const ContactforAPI = () => {
    window.location.href = 'mailto:support@quillionz.com';
  };

  // Function to handle the logout
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await logout(localStorage.getItem("email"));
      localStorage.clear();
      sessionStorage.removeItem("isLoggedIn");
      setIsLoading(false);
      navigate("/");
      setLogoutDialog(true);
    } catch (error) {
      console.log("Error while logout : ", error);
      setLogoutDialog(true);
    }
  };

  // Function to navigate to userinfo page
  const handleAccountInfo = () => {
    navigate("/userinfo");
  };

  // If remaining attempts reach zero, open the UnlimitedModal
  const handleRemainingAttempts = () => {
    setShowUnlimitedModal(true);
  };

  const handleAttemptClick = () => {
    if (remainingAttempt > 0) {
      setRemainingAttempt((prevAttempt) => prevAttempt - 1);
    }
  };

  // Function to navigate to history page which has users all questions sets.
  const handleUsersQuestionsHistory = () => {
    if (!isCanvas) {
      navigate("/history");
    } else {
      navigate("/history");
    }
  };
  const isCanvas = useCanvasDetection();

  // Background color to Attempts Circle icon
  const circleIconStyle = (remainingAttempt) => {
    const percentage = ((25 - remainingAttempt) / 25) * 100;

    return {
      background:
        remainingAttempt <= 5
          ? `linear-gradient(to top, red ${percentage}%, transparent ${percentage}%, transparent 100%)`
          : // : `linear-gradient(to top, #042739 ${percentage}%, transparent ${percentage}%, transparent 100%)`,
            "",
      backgroundSize: "200% 200%", // Added for wave animation
      animation: "wave 0.1s linear infinite", // Added for wave animation
      border: remainingAttempt <= 5 ? "2px solid red" : "2px solid #042739",
    };
  };

  const isPlanExpired = localStorage.getItem("isPlanExpired");

  return (
    <div>
      <div className="">
        {/* Navigatin bar starts here */}

        <nav className="bg-[#042739] h-[12vh] p-4 flex justify-between items-center md:flex sm:grid">
          <div className="flex items-center">
            {/* Quillionz Logo */}
            <Link to={"/submitcontent"}>
              <img src={quillonz_logo} alt="Logo" className=" w-3/4 " />
            </Link>
          </div>

          {/* Hamburger menu button (visible only on small devices) */}
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          {/* Menu items (visible on larger devices) */}
          <div
            className={`md:flex items-center ${showMenu ? "block" : "hidden"}`}
          >
            <ul className="md:flex space-x-4 ">
              {/* Invite a Friend */}
              {isCanvas === true ? (
                ""
              ) : (
                <li className="nav-item ">
                  <button
                    className="nav-button "
                    onClick={handleInviteFriendClick}
                  >
                    <i className="fa-solid fa-user-group "></i> Invite a Friend
                  </button>
                </li>
              )}

              {/* New Content */}
              <li className="nav-item">
                <button className="nav-button" onClick={handleNewContent}>
                  <i className="fa-solid fa-file-circle-plus"></i> New Content
                </button>
              </li>

              {/* History */}
              <li className="nav-item">
                <button
                  className="nav-button"
                  onClick={() => {
                    handleUsersQuestionsHistory();
                  }}
                >
                  <i className="fa-solid fa-clock-rotate-left"></i> History
                </button>
              </li>

              {/* Remaining Question Sets */}
              {userGPTKey === "LIMITED" ? (
                <li className="nav-item bg-[#f4833d] rounded-lg hover:opacity-90 ">
                  {remainingAttempt > 0 && isPlanExpired == "false" ? (
                    <Tooltip
                      title={`Question Sets Left : ${remainingAttempt} of 25`}
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className=""
                        id="go_premium_button"
                        onClick={handleRemainingAttempts}
                      >
                        <div className="flex">
                          <div>Question Sets Left</div>
                          <div
                            className="circle-icon ml-2"
                            style={circleIconStyle(remainingAttempt)}
                          >
                            <span className="count">{remainingAttempt}</span>
                          </div>
                        </div>
                      </button>
                    </Tooltip>
                  ) : isPlanExpired == "true" ? (
                    ""
                  ) : (
                    <Tooltip
                      title={
                        <span>
                          <i
                            className="pe-2 fa-solid fa-triangle-exclamation"
                            style={{ color: "white" }}
                          ></i>
                          You have exhausted your monthly 25 attempts. You can
                          upgrade to Unlimited Plan.
                        </span>
                      }
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className=""
                        id="go_premium_button"
                        onClick={() => setShowUnlimitedModal(true)}
                      >
                        Upgrade to Unlimited
                      </button>
                    </Tooltip>
                  )}
                </li>
              ) : (
                <li className="nav-item  bg-[#c0f0e2] rounded-lg hover:opacity-90 ">
                  <Tooltip
                    title="You have an unlimited access"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <button className="text-green-800 " id="go_premium_button">
                      <div className="flex">
                        <div>
                          <i className="text-green-800 fa-solid fa-star"></i>
                          <span className="text-green-800 px-1 font-semibold">
                            Unlimited Access
                          </span>
                        </div>
                      </div>
                    </button>
                  </Tooltip>
                </li>
              )}

              {/* Dropdown menu for "Username" starts here */}
              {isCanvas ? (
                ""
              ) : (
                <li className="nav-item relative text-start">
                  <div className="relative" ref={dropdownRef}>
                    <button className="nav-button" onClick={toggleDropdown}>
                      <i className="me-1 fa-solid fa-user"></i>
                      {username} <i className="fa-solid fa-caret-down"></i>
                      {/* Dropdown will appear when you click on "Username" */}
                      {showDropdown && (
                        <div className="absolute dropdown top-12 right-0 w-[170px] bg-white text-black rounded-md shadow-lg ]">
                          <ul className="py-2">
                            {/* Share Your Feedback */}
                            <li
                              className="px-4 py-2 hover:text-[#f4833d]   hover:bg-gray-100 hover:rounded-3xl cursor-pointer"
                              onClick={handleFeebackClick}
                            >
                              Share Your Feedback
                            </li>

                            {/* Account Info */}
                            <li
                              className="px-4 py-2 hover:text-[#f4833d]  hover:bg-gray-100 hover:rounded-3xl cursor-pointer"
                              onClick={handleAccountInfo}
                            >
                              Account Info
                            </li>

                            {/* Contact for APIs */}
                            <li
                              className="px-4 py-2 hover:text-[#f4833d]  hover:bg-gray-100 hover:rounded-3xl cursor-pointer"
                              onClick={ContactforAPI}
                            >
                                Contact for APIs
                            </li>

                            {/* Logout */}
                            <li
                              className="px-4 py-2 hover:text-[#f4833d] hover:bg-gray-100 hover:rounded-3xl cursor-pointer"
                              onClick={handleLogout}
                            >
                              Logout
                            </li>
                          </ul>
                        </div>
                      )}
                      {/* Dropdown menu for "Username" ends here */}
                    </button>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </nav>

        {/* Navigation bar ends here */}

        {/* Render the Invite A Friend Modal component */}
        {isCanvas === true ? (
          ""
        ) : (
          <InviteAFriendModal
            open={showInviteModal}
            onClose={() => setShowInviteModal(false)}
          />
        )}

        {/* Render the Share Your Feedback Modal component */}
        {isCanvas ? (
          ""
        ) : (
          <ShareYourFeedbackModal
            open={showFeedbackModal}
            onClose={() => setShowFeedbackModal(false)}
          />
        )}

        {/* Render the Go Premium Modal component */}
        {/* "Go For Unlimited" is currently on hold. We will resume work on it later */}
        <GoPremiumModal
          open={showGoPremiumModal}
          onClose={() => setShowGoPremiumModal(false)}
        />

        <GoForUnlimitedQuestionsModal
          open={showUnlimitedModal}
          onClose={() => setShowUnlimitedModal(false)}
        />

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}
      </div>
    </div>
  );
};

export default AppNavigation;
