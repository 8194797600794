import React, { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { Link, useLocation, useNavigate } from "react-router-dom";
import GenerateQuestionsModal from "./GenerateQuestionsModal";
import LoadingOverlay from "../overlays/LoadingOverlay";
import queryData from "../../data/questionQuery/queryData";
import { reviewContent } from "../../api/reviewContent";
import { getQuestionAnswer } from "../../api/getQuestionAnswer";
import { commonErrorToastMessage } from "../toastify/AllToastifyErrors";
import { getQuestionsDummyResponse } from "../../data/sampleTextDummyResponse/dummyResponsesSampleText";

export default function ReviewContentModal({
  isOpen,
  onClose,
  responseData,
  contentData,

  contentReadiness,
}) {
  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  const handleReviewContentAnyway = async () => {
    setIsModalOpen(false);
    try {
      setIsLoading(true);

      // reviewContent API calling
      // const response = await reviewContent(
      //   contentData.doc_id,
      //   contentData.title,
      //   content
      // );

      // navigate("/review", {
      //   state: { reviewContentResponse: response, contentData: contentData },
      // });

      // // Instead of using navigate with state, build the URL with query parameters
      // const queryString = new URLSearchParams({
      //   reviewContentResponse: JSON.stringify(responseData.data),
      //   contentData: JSON.stringify(contentData),
      //   keywordResponseData: JSON.stringify(responseData),
      //   domainName: JSON.stringify(domainNameKeywordPage),
      //   titleName: JSON.stringify(titleName),
      //   content: JSON.stringify(content),
      //   selectedKeywords: JSON.stringify(selectedKeywords),
      //   contentReadiness: JSON.stringify(contentReadiness),
      // }).toString();

      localStorage.setItem("contentReadiness", contentReadiness);
      // localStorage.setItem("reviewContentResponse", responseData.data);

      localStorage.setItem(
        "reviewContentResponse",
        JSON.stringify(responseData.data)
      );

      navigate(`/review`);

      // navigate('/review')

      setIsLoading(false);
    } catch (error) {
      console.error("Error while making the API request:", error.message);
      commonErrorToastMessage("Error while generating questions.");
      setIsLoading(false);
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      onClose();
    }
  };

  const doc_id = localStorage.getItem("doc_id");
  const content = localStorage.getItem("content");
  const selectedKeywords = JSON.parse(
    localStorage.getItem("selectedKeywordsArray")
  );

  const usergptKey = localStorage.getItem("userGPTKey");
  const email = localStorage.getItem("email");

  const generateQuestionAnswer = async () => {
    try {
      // When user exhausted all userAttempts for question generation for a month
      const reaminingQuestionSets = localStorage.getItem("UserAttempts");

      if (reaminingQuestionSets == 0 && usergptKey === "LIMITED") {
        commonErrorToastMessage(
          "You have exhausted your monthly 25 attempts. You can upgrade to Unlimited Plan."
        );
        onClose();

        return;
      }

      setIsFront(false);
      setIsModalOpen(true);

      // // get_question_answer API calling

      const isSampleTextClicked = localStorage.getItem("isSampleButtonClicked");

      const userRemainingQuestionSets = parseInt(
        localStorage.getItem("UserAttempts"),
        10
      );



      // getQuestionsDummyResponse is the dummy response which contains static questions for Sample Text
      const questionsForSampleText = {
        response: {
          short_questions: {
            questions: [
              {
                question:
                  "How do histone acetylation and DNA methylation contribute to the epigenome's sculpting of gene expression?",
                answer:
                  "Histone acetylation and DNA methylation are key components of the epigenome that modulates gene expression. Histone acetylation modulates chromatin structure making genes more accessible for expression. DNA methylation, on the other hand, adds methyl groups to cytosine residues, creating a dynamic code that influences gene expression by regulating transcriptional access.",
              },
              {
                question:
                  "In the context of evolution, how do molecular clocks contribute to our understanding of genetic divergence and timelines?",
                answer:
                  "Molecular clocks measure the accumulation of genetic changes over time, enabling scientists to decipher evolutionary timelines. By comparing gene sequences across different species, researchers can use molecular clocks to date divergence events and infer evolutionary relationships.",
              },
              {
                question:
                  "How does systems biology transcend traditional reductionist approaches in biology?",
                answer:
                  "Systems biology integrates multiple levels of molecular and cellular information to create holistic models that reveal emergent properties and dynamic behaviors of cellular networks, thereby transcending the reductionist approach which focuses on individual components of a cell.",
              },
              {
                question:
                  "Describe how the fields of comparative genomics and molecular evolution contribute to our understanding of life's evolution.",
                answer:
                  "Comparative genomics and molecular evolution are key tools to understand life's evolution. Comparative genomics reveals the footprints of evolution within the genome by identifying orthologous genes conserved across species, indicating a common ancestry. Regions of conservation and novelty in the genome reflect the ongoing interplay between adaptation and innovation. On the other hand, molecular evolution uses molecular clocks and comparative genomics to elucidate the timelines of genetic divergence. It measures the gradual accumulation of genetic changes, thereby helping date divergence events and infer evolutionary relationships.",
              },
            ],
          },
          mcqs_questions: {
            questions: [
              {
                question:
                  "What is the role of DNA methylation in gene expression?",
                options: [
                  "Prevents transcription by making DNA more compact",
                  "Promotes transcription by loosening chromatin structure",
                  "Regulates transcriptional access by recruiting chromatin-modifying enzymes and transcription factors",
                  "No role in gene expression",
                ],
                answer:
                  "Regulates transcriptional access by recruiting chromatin-modifying enzymes and transcription factors",
              },
              {
                question:
                  "How does histone acetylation affect chromatin structure and accessibility?",
                options: [
                  "Tightens the chromatin structure, preventing transcription.",
                  "Loosens the chromatin structure, promoting transcription.",
                  "Does not affect chromatin structure and accessibility.",
                  "Adds acetyl groups to cytosine residues.",
                ],
                answer:
                  "Loosens the chromatin structure, promoting transcription.",
              },
              {
                question:
                  "What type of genes are conserved across species that bear witness to common ancestry?",
                options: [
                  "Paralogous genes",
                  "Orthologous genes",
                  "Crossologous genes",
                  "All genes are conserved across species",
                ],
                answer: "Orthologous genes",
              },
            ],
          },
          true_false_questions: {
            questions: [
              {
                question:
                  "Comparative genomics do not reveal any footprints of evolution.",
                answer: "False",
              },
            ],
          },
          fib_questions: {
            questions: [
              {
                question:
                  "A dynamic code that influences gene expression is established by the addition of methyl groups to __________ residues.",
                answer: "cytosine",
              },
              {
                question:
                  "Systems biology observes the intricate choreography of signaling cascades, metabolic pathways, and gene regulatory networks through __________ modeling.",
                answer: "computational",
              },
            ],
          },
          descriptive_questions: {
            questions: [],
          },
          notes:
            "Epigenetic changes such as histone acetylation and DNA methylation regulate gene expression by influencing chromatin structure and transcriptional access respectively. Molecular clocks and comparative genomics provide insights into genetic divergence and evolutionary timelines, revealing our relationship with other species. Systems biology, through computational modeling and experimental validation, enables a holistic understanding of cellular interactions and behaviors. Collectively, these approaches are critical in unraveling the intricacies of biology at the molecular, cellular, and evolutionary scales.",
          usage: {
            type: "TextData",
            OEM: "0",
            prompt_tokens: 1196,
            completion_tokens: 831,
            total_tokens: 2027,
          },
          doc_id: "20231128041248549491_4f4661e2f6ae445e90d2f71288e2a1ad",
          email: "agastha@gmail.com",
          no_ques: 10,
        },
        updatedAttempts: userRemainingQuestionSets,
      };

      // This is mechanism is only for Sample Text
      if (isSampleTextClicked === "true") {
        navigate("/question", {
          state: {
            generatedQuestions: questionsForSampleText,
          },
        });
      } else {
        try {
          const response = await getQuestionAnswer(
            doc_id,
            content,
            selectedKeywords,
            usergptKey,
            email
            // queryData
          );

          // Log the API response
          // navigate("/question", {
          //   state: {
          //     generatedQuestions: response.data,
          //   },
          // });

          if (response.data.response.no_ques !== 0) {
            // Log the API response
            navigate("/question", {
              state: { generatedQuestions: response.data },
            });
          } else {
            console.error("Error while generating questions.");
            commonErrorToastMessage("Error while generating questions.");
            onClose();

            setIsModalOpen(false);
            return;
          }
        } catch (error) {
          console.error("Error while making the API request:", error.message);
          commonErrorToastMessage("Error while generating questions.");
          setIsLoading(false);
          setIsModalOpen(false);
          onClose();
        }
      }

      // if (response && response.data) {
      //   // Log the API response
      //   navigate("/question", {
      //     state: {
      //       generatedQuestions: response.data,
      //     },
      //   });

      //   // Reduce UserAttempts by 1 after successful API response
      //   const userAttempts = localStorage.getItem("UserAttempts");
      //   if (userAttempts && !isNaN(userAttempts) && userAttempts > 0) {
      //     localStorage.setItem("UserAttempts", userAttempts - 1);
      //   }
      // }

      setIsModalOpen(false);
    } catch (error) {
      console.error("Error while making the API request:", error.message);
      commonErrorToastMessage("Error while generating questions.");
      setIsLoading(false);
      setIsModalOpen(false);
      onClose();
    }
  };

  const [isFront, setIsFront] = useState(true); // Add this state to manage front/back view

  const handleGoUnlimited = () => {
    setIsFront(false); // Switch to the back view when clicking "Unlimited" button
  };

  const handleGoBack = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlipped = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="bg-[00000080]"
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",

              //   backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // Set the height to 100% of the viewport height
            }}
          >
            <div
              className={`rounded-lg animate-fade-slide-down modal-content   ${
                isFront ? "unflipped" : "flipped"
              }`} /* Updated this line */
            >
              {isFront ? (
                <div className="bg-white readiness-pass-front-content  rounded  animate-fade-slide-down w-3/5">
                  {/* <button
                onClick={onClose}
                className="rounded pt-4 px-4 flex place-content-end text-red-600 font-bold text-xl"
              >
                <i className="fa-regular fa-circle-xmark"></i>
                {""}
              </button> */}
                  <div className=" hover:shadow-myShadow2">
                    <p className=" p-12 text-[18px] text-[#777e8d]">
                      Great news, your content is already almost perfect for the
                      Quillionz AI-engine to process, it hardly needs any
                      review! If you’d like, you can just skip this step and
                      head straight over to the questions and notes.
                    </p>

                    <div className="pb-12 flex place-content-center gap-10">
                      <Tooltip
                        title="Review Content Anyway"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded border border-[#f4833d] text-[#f4833d] hover:bg-[#f4833d] hover:text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                          onClick={handleReviewContentAnyway}
                        >
                          Review Content Anyway
                        </button>
                      </Tooltip>

                      <Tooltip
                        title="Skip Review Step"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded bg-[#f4833d] hover:opacity-80 text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                          onClick={generateQuestionAnswer}
                        >
                          {" "}
                          Skip Review Step
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Render the GenerateQuestionsModal component if the modal is open */}
                </div>
              ) : (
                <div className="back-content">
                  {isModalOpen && (
                    <GenerateQuestionsModal
                      isOpen={isModalOpen} // Pass the state to control whether the modal is open or not
                      onClose={handleModalClose} // Pass the function to close the modal and redirect
                      // Pass the time taken to generate the response as a prop
                    />
                  )}
                </div>
              )}
              {/* Loading overlay */}
              {isLoading && <LoadingOverlay />}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
