import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "../overlays/LoadingOverlay";
import { inviteAFriend } from "../../api/inviteAFriend";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
} from "../toastify/AllToastifyErrors";
import { upgradeToUnlimited } from "../../api/upgradeToUnlimited";
import QuillionzProUpgradeModal from "./QuillionzProUpgradeModal";

const SubmitContentUnlimitedModal = ({
  open,
  onClose,
  onSubmit,
  hideFirstPartForSubmitPage,
  setShowGoForUnlimitedModal,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  // 1. Create two state variables
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [message, setMessage] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [showUnlimitedModal, setShowUnlimitedModal] = useState(false);

  const [openAIKey, setOpenAIKey] = useState("");
  const [confirmOpenAIKey, setConfirmOpenAIKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [upgradeToUnlimitedClicked, setUpgradeToUnlimitedClicked] =
    useState(false);

  // Function to handle the "Close" button click
  const handleModalClose = () => {
    setEmail("");
    setIsFront(true);
    setMessage("");
    onClose(); // Close the modal without processing the input
  };

  const [isFront, setIsFront] = useState(true); // Add this state to manage front/back view

  useEffect(() => {
    if (openAIKey && confirmOpenAIKey) {
      setErrorMessage(
        openAIKey === confirmOpenAIKey
          ? ""
          : "Open AI Key and Confirm Open AI Key does not match."
      );
    }
  }, [openAIKey, confirmOpenAIKey]);
  const handleGoBack = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const handleGoBack2 = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const handleOpenAIKeySubmit = async () => {
    if (openAIKey === confirmOpenAIKey) {
      try {
        setIsLoading(true);
        setIsFront(false); // Switch to the back view when clicking "Unlimited" button

        const response = await upgradeToUnlimited(
          openAIKey,
          confirmOpenAIKey,
          email
        );

        const unlimitedAttempts = 2000;

        localStorage.setItem("userOpenAIKey", openAIKey);
        // localStorage.setItem("UserAttempts", unlimitedAttempts);

        localStorage.setItem("userGPTKey", openAIKey);
        localStorage.setItem("isGptActive", "1");
        navigate("/submitcontent");

        commonSuccessToastMessage(
          "Success! Your OpenAI key has been successfully verified and saved. You now have unlimited questions sets per month."
        );
        setIsLoading(false);
        setErrorMessage("");
        setOpenAIKey("");
        setConfirmOpenAIKey("");
        onClose();
      } catch (error) {
        setIsLoading(false);
        commonErrorToastMessage("Please provide a valid OpenAI key.");

        console.log("Error while submitting your GPT key : ", error);
        setErrorMessage("");
        setOpenAIKey("");
        setConfirmOpenAIKey("");
      }
    } else {
      commonErrorToastMessage(
        "The OpenAI key you entered does not match the confirmed OpenAI key."
      );
    }
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlipped = () => {
    setIsFlipped(!isFlipped);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const dummyPasswordField = (
    <input
      type="password"
      autoComplete="new-password"
      style={{ display: "none" }}
      readOnly
    />
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      // Apply the style to the modal container
      className="flex place-content-center items-center fixed"
      // Disable clicking on the backdrop and Escape key
      BackdropProps={{
        style: { pointerEvents: "none" },
      }}
      disableEscapeKeyDown
    >
      <div
        className={`rounded-lg animate-fade-slide-down  shadow-xl hover:shadow-myShadow2 `} /* Updated this line */
      >
        <div className="bg-white  shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-[35vw]">
          {/* <div className="flex justify-end cursor-pointer text-2xl mb-2 text-red-600  "></div> */}

          <div className="flex place-content-between  pb-5">
            <h2 className="text-xl font-semibold">
              <span>Upgrade to unlimited plan </span>
            </h2>
          </div>

          <div className="pt-1">
            <p className="opacity-60 italic pb-3">
              <span>
                <i className="fa-solid fa-circle-info text-blue-400 pe-2"></i>
              </span>
              For unlimited questions sets, kindly provide your OpenAI Key
              below. This key will be used securely under your existing
              subscription plan.
            </p>

            <p className="mt-3">
              <span className="">Email : </span>
              <span className="px-2 font-semibold">{email}</span>
            </p>
            {dummyPasswordField}
            <p className="flex px-2 mt-5 border border-[#fcd6bf] rounded-lg outline-[#f4833d] ">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter Your Open AI Key"
                className="p-2 outline-none w-full bg-none"
                value={openAIKey}
                onChange={(e) => setOpenAIKey(e.target.value)}
              />

              <Tooltip
                title={isPasswordVisible ? "Hide API Key" : "Show API Key"}
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  onClick={togglePasswordVisibility}
                  className="text-[#f4833d] px-3"
                  key={isPasswordVisible ? "visible" : "hidden"} // add this line
                >
                  {isPasswordVisible ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </button>
              </Tooltip>
            </p>

            <p
              className={`flex mt-5 px-2 border border-[#fcd6bf] rounded-lg outline-[#f4833d] `}
            >
              <input
                type={isConfirmPasswordVisible ? "text" : "password"}
                placeholder="Confirm Your Open AI Key"
                className="p-2 outline-none w-full"
                value={confirmOpenAIKey}
                onChange={(e) => setConfirmOpenAIKey(e.target.value)}
              />

              {/* <Tooltip
                title={
                  isConfirmPasswordVisible ? "Hide API Key" : "Show API Key"
                }
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              > */}
              <button
                onClick={toggleConfirmPasswordVisibility}
                className="text-[#f4833d] px-3"
                key={isConfirmPasswordVisible ? "visible" : "hidden"}
              >
                {isConfirmPasswordVisible ? (
                  <i className="fa-solid fa-eye"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash"></i>
                )}
              </button>
              {/* </Tooltip> */}
            </p>
            {errorMessage && (
              <p className="antialiased animate-fade-slide-down">
                <i className="fa-solid fa-circle-exclamation  text-red-500   text-[13px] mt-2"></i>
                <span className="px-2 text-red-500   text-[13px] mt-2">
                  {errorMessage}
                </span>
              </p>
            )}
          </div>

          <div className="flex place-content-end gap-8">
            <Tooltip
              title="Cancel"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                className={`rounded mt-4 px-6 py-2 hover:opacity-90 text-[#6c757d] hover:bg-[#6c757d] hover:border-[#6c757d] hover:text-white border border-[#6c757d]  `}
                onClick={handleModalClose}
              >
                Cancel
              </button>
            </Tooltip>

            <Tooltip
              title="Submit"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
                onClick={handleOpenAIKeySubmit}
              >
                Submit
              </button>
            </Tooltip>
          </div>
        </div>

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default SubmitContentUnlimitedModal;
