import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { RingLoader } from "react-spinners";
import StepProgress from "../../components/stepper/StepProgress";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import toast, { Toaster } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";

import YoutubeModal from "../../components/modals/YoutubeModal";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import sampleText from "../../data/SubmitContent/sampleText/sampleText";

import { handleFileChange } from "../../utils/SubmitContent/fileHandling";
import {
  handleSubmit,
  handleSubmitContent,
} from "../../utils/SubmitContent/handleSubmit";
import { useWordCount } from "../../utils/SubmitContent/wordCount";
import { navigateToKeywords } from "../../utils/SubmitContent/handleKeywordsAPICall";
import TitleAndDomainInput from "../../components/submitContentComponents/TitleAndDomainInput";
import HelpingPointer from "../../components/submitContentComponents/HelpingPointers";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import { commonErrorToastMessage } from "../../components/toastify/AllToastifyErrors";

import alertImage from "../../../assets/images/SubmitContnet/alert1.png";
import QuillionzProUpgradeModal from "../../components/modals/QuillionzProUpgradeModal";
import GoForUnlimitedQuestionsModal from "../../components/modals/GoForUnlimitedQuestionsModal";

import { franc } from "franc";
import { canvasUserLogin } from "../../api/userLogin";
import { youtubeVideo } from "../../api/youtubeVideo";
import SubmitContentUnlimitedModal from "../../components/modals/SubmitContentUnlimitedModal";
import ConfirmFileUploadModal from "../../components/modals/ConfirmFileUploadModal";

const SubmitContent = ({ hasCanvas }) => {
  const location = useLocation();
  // State variables to manage the selected file and content input
  const [selectedFile, setSelectedFile] = useState(null);
  const [content, setContent] = useState("");

  // New state variable to store the content from the uploaded file
  const [uploadedContent, setUploadedContent] = useState("");

  //  Select domain dropdown
  const [domain, setDomain] = useState("");

  const [isNetworkError, setIsNetworkError] = useState(false);

  // Add this state variable at the beginning of the component
  const [title, setTitle] = useState("");

  // Add this state variable at the beginning of the component
  const [youtubeModalResponseData, setYoutubeModalResponseData] = useState("");

  // Add this state variable at the beginning of the component
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);

  // Open Manage Subscription Modal
  const [showManageSubscription, setShowManageSubscription] = useState(false);

  // State to open GoForUnlimited Modal
  const [showGoForUnlimitedModal, setShowGoForUnlimitedModal] = useState(false);

  // Create a state variable to manage the input value
  const [youtubeLink, setYoutubeLink] = useState("");

  // Create a state variable to manage the error message
  const [errorMessage, setErrorMessage] = useState("");

  const titleInputRef = useRef(null);

  const domainSelectRef = useRef(null);

  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const navigate = useNavigate();

  const [isSampleButtonClicked, setIsSampleButtonClicked] = useState(false);

  // New state variable to store the doc_id received from the API response
  const [doc_id, setDoc_id] = useState("");
  const [responseData, setResponseData] = useState(null);

  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = useState(false);

  // Only for text enter
  const [isTextLoading, setIsTextLoading] = useState(false);

  // Switch button for Express Mode
  const [checked, setChecked] = useState(false);

  const [loadingContent, setLoadingContent] = useState(false);

  // Update Word Count
  const { wordCount, updateWordCount } = useWordCount(content);

  const [isGptActive, setIsGptActive] = useState(
    localStorage.getItem("isGptActive") || ""
  );

  const [showTooltip, setShowTooltip] = useState(true); // Add this line
  // State to store the user attempts i.e Remaining Question Sets
  const [userAttempts, setUserAttempts] = useState(
    localStorage.getItem("UserAttempts") || ""
  );
  // Youtube URL response data :
  // const responseFromYoutubeModal = JSON.parse(
  //   new URLSearchParams(location.search).get("youtubeModalResponse")
  // );

  // const youtubeModalResponse = location.state?.youtubeModalResponse.title;

  // Function to open Manage Subscription Modal
  const handleManageSubscriptionModal = () => {
    setShowManageSubscription(true);
  };

  // Function to open GoForUnlimitedModal
  const handleGoForUnlimitedModal = () => {
    setShowGoForUnlimitedModal(true);
  };

  const content1 = localStorage.getItem("content");
  // const handleYoutubeModalResponse = () => {
  //   // Set the response data in the state
  //   setYoutubeModalResponseData(responseFromYoutubeModal);
  //   const title = location.state?.youtubeModalResponse.title;
  //   const doc_id = localStorage.getItem("doc_id");
  //   // Update the content and title state variables using the response data
  //   setContent(responseFromYoutubeModal?.data?.text);
  //   setTitle(title);
  //   setDomain(domain);
  // };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDropdownChange = (event) => {
    setDomain(event.target.value);
  };

  // Function to open the "Youtube Modal"
  const handleYoutubeModalOpen = () => {
    setIsYoutubeModalOpen(true);
    setShowTooltip(false); 
  };

  // Set the active step value to 1
  const activeStep = 1;

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);

    return words.length;
  };

  // Calculate the word count conditionally and give color to word count text
  const isWordCountLessThan300 =
    (wordCount < 300 || wordCount > 3000) && wordCount >= 1;
  const wordCountClass = isWordCountLessThan300 ? "text-red-500" : "";

  // Function to handle changes in the content input textarea
  const handleTextAreaChange = (event) => {
    const newContent = event.target.value;

    // TODO:We have kept only the first 3000 words of the original content.
    const words = newContent.trim().split(/\s+/);

    // Trim the content if it exceeds 3000 words
    const trimmedContent = words.slice(0, 3000).join(' ');
    
  if (words.length > 3000) {
    setContent(trimmedContent);
    updateWordCount(trimmedContent);
    return;
  } else {
    setContent(newContent);
    updateWordCount(newContent);
  }
  };

  const handleTextAreaChangeForFileUpload = (event) => {
    const newContent = "";

    updateWordCount(newContent);
  };

  const textareaRef = useRef();

  // For Canvas
  useEffect(() => {
    // Set focus on the textarea when the component mounts
    (async () => {
      if (hasCanvas) {
        const { data, status } = await canvasUserLogin();
        if ([200, 201].includes(status)) {
          const {
            token,
            username,
            email,
            remainingQuestionSets,
            currentDate,
            Usergpt_key,
            userId,
            isGptActive,
            Plan_Validity_Subscription,
            ExpiryDate,
            remainingDaysInMonth,
            calculatedDate,
            isPlanExpired   
          } = data;
          localStorage.setItem("token", token);
          localStorage.setItem("email", email);
          localStorage.setItem("username", username);
          localStorage.setItem("Id", userId);
          localStorage.setItem("UserAttempts", remainingQuestionSets);
          localStorage.setItem("UserLoggedIn", currentDate);
          localStorage.setItem("userGPTKey", Usergpt_key);
          localStorage.setItem("isGptActive", isGptActive);
          setIsGptActive(isGptActive);
          setUserAttempts(remainingQuestionSets);
          localStorage.setItem("remainingDaysInMonth",remainingDaysInMonth);
          localStorage.setItem("calculatedDate",calculatedDate);
          localStorage.setItem( "ExpiryDate",ExpiryDate);
          localStorage.setItem("Plan_Validity_Subscription",Plan_Validity_Subscription);
          localStorage.setItem("isPlanExpired",isPlanExpired);
        }
      }
    })();
    textareaRef?.current?.focus();
  }, []);

  // Function to handle the "Try Sample" button click
  const handleSampleButtonClick = () => {
    setIsSampleButtonClicked(true);
    setContent(sampleText);

    setTitle("Epigenetic Landscape and Cellular Identity ");
    setDomain("Biology");
  };

  localStorage.setItem("isSampleButtonClicked", isSampleButtonClicked);

  // Reference to the file input element
  const fileInputRef = useRef(null);

  let pdfResponseData;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

// Handle File Input Change
const handleFileInputChange = async (event) => {
  // Store the file temporarily in the state or a ref
  const file = event.target.files[0];
  if (file) {
    // Check if the file size is less than 10MB
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
   
    if (file.size > maxSize) {
      commonErrorToastMessage("File size must be less than 10MB.");
      // Reset the file input value to allow selecting a different file
      fileInputRef.current.value = '';
      setShowTooltip(false);

      // Trigger a click event to open the file dialog
      fileInputRef.current.click();
      return;
    }

    // Open the confirmation modal
    setIsConfirmModalOpen(true);
    // Store the file in state for later use
    setSelectedFile(file);
  }

  // Further process only if the user confirmed
  if (isConfirmModalOpen) {
    // Reset state and perform additional actions
    setIsConfirmModalOpen(false); // Close the confirmation modal
    setContent('');
    setLoadingContent(true);
    
    // Update content only if word count is less than or equal to 3000
    setContent((prevContent) => {
      const words = event.target.value.trim().split(/\s+/);
      const newContent = event.target.value;
    
      if (words.length <= 3000) {
        return newContent;
      }
    
      return prevContent; // Maintain the previous content if the condition is not met
    });
    
    // Perform other actions as needed
  }
};




  const handleConfirmUpload = async () => {
    setIsConfirmModalOpen(false);
    // Now call handleFileChange with the selected file
    if (selectedFile) {
      try {
        const newContent = await handleFileChange(
          selectedFile,
          title,
          content,
          wordCount,
          updateWordCount,
          setResponseData,
          setSelectedFile,
          setIsFileUploaded,
          setLoadingContent,
          setIsLoading,
          setTitle,
          setContent,
          setDomain,
          setDoc_id,
          domain
        );

        if (newContent === undefined || newContent === null) {
          setSelectedFile(null);
        }

        // Update the textarea content only if the file is valid
        if (newContent !== null) {
          setContent(newContent);
        }
      } catch (error) {
        console.log("Error while uploading PDF Submit Content Page :  ", error);
      }
    }
  };

  const handleCancelUpload = () => {
    setIsConfirmModalOpen(false);
    setSelectedFile(null);
    setTitle("");
    setContent("");
    setLoadingContent(false);
  };

  // Function to trigger the click event on the file input element
const handleUploadButtonClick = () => {
  // Title is coming undefined whenever the user uploads any file without entering the title. For that, we add this logic so that he would get an error message before uploading the file
  if (!title) {
    commonErrorToastMessage(
      "Please provide the title before you upload the file."
    );
    return;
  }

  // Access the file input element
  const fileInput = fileInputRef.current;

  // Check if file input is available
  if (!fileInput) {
    commonErrorToastMessage("File input is not available.");
    return;
  }

  // Check if any file is selected
  if (fileInput.files.length === 0) {
    // Trigger a click event to open the file dialog
    fileInput.click();
  } else {
    // Check if the file size is less than 10MB
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const fileSize = fileInput.files[0].size;

    if (fileSize > maxSize) {
      commonErrorToastMessage("File size must be less than 10MB.");
    } else {
      // If all checks pass, you can proceed with your upload logic here
      // For now, I'm triggering a click event to simulate further steps
      fileInput.click();
    }
  }
};

  // Function to handle the "Proceed" button
  const handleProceedClick = () => {
    if (!title.trim() && !domain.trim() && !content.trim()) {
      commonErrorToastMessage(
        "Please add title, domain and content to proceed."
      );
      return;
    }

    if (!title.trim()) {
      commonErrorToastMessage("Please add title for your content.");
      return;
    }

    if (!domain.trim()) {
      commonErrorToastMessage(
        "Please choose the domain to which your content belongs."
      );
      return;
    }

    if (!content.trim()) {
      commonErrorToastMessage("Please provide the content to proceed.");
      return;
    }
  };

  let myAreaHeight = "60vh";

  // Calculate the height of the textarea based on different conditions
  if (selectedFile) {
    // Height of the textarea when a file is uploaded
    myAreaHeight = "33vh";
  } else if (content) {
    // Height of the textarea when content is inserted
    myAreaHeight = "39vh";
  }

  // Function to handle form submission and API call
  const handleSubmitContentButton = async () => {
    await handleSubmitContent({
      title,
      domain,
      wordCount,
      selectedFile,
      uploadedContent,
      content,
      setContent,
      setIsLoading,
      setIsTextLoading,
      setTitle,
      setIsNetworkError,
      setResponseData,
      titleInputRef,
      domainSelectRef,
      navigate,
    });
  };

  // Function to handle navigation to "keywords" page
  const handleKeywordsNavigation = async () => {
    await navigateToKeywords(
      title,
      domain,
      wordCount,
      doc_id,
      content,
      responseData,
      titleInputRef,
      domainSelectRef,
      setIsLoading,
      setIsTextLoading,
      navigate,
      selectedFile
    );
  };

  const isCanvas = useCanvasDetection();

  const remainingQuestionSets = localStorage.getItem("UserAttempts");
  const remainingDaysInMonth = localStorage.getItem("monthEndDate");

  // Formatting the date
  const getFormattedMonth = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", year: "numeric", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Assuming the value of monthEndDate is in 'MM/DD/YYYY' format
  const formattedMonthEndDate = getFormattedMonth(remainingDaysInMonth);

  // ......Youtube Modal Logic goes here......
  // Function to handle changes in the input value
  const handleInputChange = (event) => {
    setYoutubeLink(event.target.value);
  };

  // State variable to check if response is came from youtube
  const [isYoutubeResopnse, setIsYoutubeResponse] = useState(false);

  // Function to handle the "Submit" button click
  const handleYoutubeLInkSubmit = async () => {
    try {
      setErrorMessage(""); // Clear any previous error messages

      // Validate if the YouTube link is not empty
      if (!youtubeLink.trim()) {
        setErrorMessage("Please enter valid YouTube link.");
        return;
      }

      // Validate if the YouTube link is a valid URL
      const youtubeUrlPattern =
        /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      if (!youtubeUrlPattern.test(youtubeLink)) {
        setErrorMessage(
          "Invalid YouTube link. Please enter a valid YouTube URL."
        );
        return;
      }

      // Validate if the YouTube link contains "youtube.com" in it
      if (!youtubeLink.includes("youtube.com")) {
        setErrorMessage(
          "Invalid YouTube link. The link should contain 'youtube.com'."
        );
        return;
      }

      setIsLoading(true);

      const usergptKey = localStorage.getItem("userGPTKey");
      const email = localStorage.getItem("email");

      // Make the API call
      const response = await youtubeVideo(youtubeLink, usergptKey, email);

      // Call the onSubmit prop with the response data

      if (response?.data?.error) {
        console.log("Please upload a YouTube video with closed captions.");
        setErrorMessage("Please upload a YouTube video with closed captions.");
        commonErrorToastMessage(
          "Please upload a YouTube video with closed captions."
        );

        setIsLoading(false);

        return;
      }

      // Check if the text is in English
      const text = response.data?.text;
      if (text) {
        const languageCode = franc(text, { minLength: 100 }); // Minimum length to consider for language detection
        if (languageCode !== "eng") {
          console.log(
            "Please provide a YouTube link containing content in English."
          );
          setErrorMessage(
            "Please provide a YouTube link containing content in English."
          );
          commonErrorToastMessage(
            "Please provide a YouTube link containing content in English."
          );

          setIsLoading(false);

          return;
        }
      }

      // Check the duration of uploaded YouTube video
      const videoDuration = Math.floor(response.data?.duration);

      if (videoDuration > 1800) {
        console.log(
          "The duration of the YouTube video you provided exceeds 30 minutes."
        );
        setErrorMessage(
          "The duration of the YouTube video you provided exceeds 30 minutes."
        );
        commonErrorToastMessage(
          "The duration of the YouTube video you provided exceeds 30 minutes."
        );

        setIsLoading(false);

        return;
      }

      // TODO:We have kept only the first 3000 words of the original content.
      const words = text.split(/\s+/);
      if (words.length > 3000) {
        response.data.text = words.slice(0, 3000).join(" ");
      }

      localStorage.setItem("content", response.data?.text);
      localStorage.setItem("title", response.data?.title);
      localStorage.setItem("doc_id", response.data?.doc_id);

      setContent(response.data?.text);
      setTitle(response.data?.title);
      setDomain(domain);

      setYoutubeLink("");

      setIsYoutubeResponse(true);
      // onClose();
      setIsYoutubeModalOpen(false);

      setIsLoading(false);
    } catch (error) {
      console.error("Error in YoutubeModal :", error?.message);
      setIsLoading(false);
    }
  };

  // Function to handle the "Close" button click
  const handleModalClose = () => {
    // onClose(); // Close the modal without processing the input
    setIsYoutubeModalOpen(false);
    setYoutubeLink("");
    setErrorMessage("");
  };
  // ......Youtube Modal Logic ends here......

  if (userAttempts == "undefined") {
    localStorage.setItem("UserAttempts", 0);
  }
  // console.log("User Attempts : ", userAttempts);

  let isPlanExpired = localStorage.getItem("isPlanExpired");
  isPlanExpired = !isPlanExpired || isPlanExpired === "false" ? !1 : !0;
  // Plan expired message logic :
  const [subscriptionPlan, setSubscriptionPlan] = useState("");

  // Effect hook to update subscription plan name based on the value
  useEffect(() => {
    const planNumber = parseInt(localStorage.getItem("subscriptionPlan"));
    let planName = "";

    switch (planNumber) {
      case 1:
        planName = "Monthly";
        break;
      case 3:
        planName = "Quarterly";
        break;
      case 12:
        planName = "Annual";
        break;
      default:
        planName = "Unknown Plan";
        break;
    }
    setSubscriptionPlan(planName);
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts.

  return (
    <div>
      {/* {isCanvas ? null : <AppNavigation />} */}
      {/* App Navigation */}
      <AppNavigation />

      <div className={`bg-white h-[81vh] select-none `}>
        <div className=" flex ">
          {/* Conditionally displaying the "TitleAndDomainInput" and "HelperPointer" components */}
          {!isPlanExpired || isPlanExpired == "false" ? (
            remainingQuestionSets != 0 ||
            isGptActive == 1 ||
            localStorage.getItem("userGPTKey") == "LIMITED" ? (
              <div className=" w-full  h-auto px-6 pt-4 rounded-2xl mx-5">
                <TitleAndDomainInput
                  title={title}
                  setTitle={setTitle}
                  titleInputRef={titleInputRef}
                  checked={checked}
                  handleChange={handleChange}
                  domain={domain}
                  setDomain={setDomain}
                  handleDropdownChange={handleDropdownChange}
                  domainSelectRef={domainSelectRef}
                />

                <div className=" pb-2 ">
                  {/* Text area for content input starts here */}
                  <div
                    className={`p-5 mx-auto custom-scrollbar my-area opacity-80 ${
                      isCanvas ? "h-[63vh]" : "h-[56vh] "
                    }`}
                    style={{
                      minHeight: "33vh",
                      maxHeight: "81vh",
                      width: "98%",
                      // If the valid file is uploaded or any text is entered then size of text area changes accordingly (myAreaHeight function)
                      // height: "70vh",
                      overflowY: content ? "hidden" : "auto", // Add this line to enable vertical scrolling
                    }}
                  >
                    {loadingContent ? (
                      <div className="mt-[30%]">
                        <HelpingPointer />
                      </div>
                    ) : (
                      <textarea
                        className={`w-full h-full resize-none   p-2 text-[#212529] font-normal text-lg leading-6 outline-none custom-scrollbar  ${
                          isSampleButtonClicked ? "" : ""
                        }`}
                        value={content}
                        // maxLength={3000}
                        ref={textareaRef}
                        onChange={handleTextAreaChange}
                        // onChange={(e) => setContent(e.target.value)}
                        // Disable the textarea if a valid file is uploaded
                        // disabled={isFileUploaded}
                      ></textarea>
                    )}

                    {/* Pointers */}
                    {!selectedFile && !content && (
                      <div className="">
                        <HelpingPointer
                          isSampleButtonClicked={isSampleButtonClicked}
                          content={content}
                          handleTextAreaChange={
                            handleTextAreaChangeForFileUpload
                          }
                          handleUploadButtonClick={handleUploadButtonClick}
                          handleFileInputChange={handleFileInputChange}
                          handleYoutubeModalOpen={handleYoutubeModalOpen}
                          isFileUploaded={isFileUploaded}
                          textareaRef={textareaRef}
                          fileInputRef={fileInputRef}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <div>
                      <div className="flex  h-[13vh] align-middle justify-center place-content-center border-t-[1px] ">
                        <div className="w-[50%] ">
                          <p
                            className={`font-semibold mt-2  ${wordCountClass} text-[#777e8d] `}
                          >
                            *Total words considered : {wordCount} (Word count
                            must be between 300-3000 for question generation.)
                          </p>

                          <p className="text-[14px] text-[#777e8d]">
                            English Content only.
                          </p>
                        </div>

                        <div className="mt-3  w-[50%] flex justify-end align-middle gap-10">
                          <div>
                            <Tooltip
                              title="Try a Sample Text"
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                              TransitionProps={{ timeout: 300 }}
                            >
                              <button
                                className="rounded py-2 mt-2 h-[7vh] hover:opacity-90 bg-[#f4833d] text-white  px-4  "
                                onClick={handleSampleButtonClick}
                              >
                                Try a Sample Text
                              </button>
                            </Tooltip>
                          </div>

                          <div className="">
                            {selectedFile || content ? (
                              ""
                            ) : (
                              <div className="flex gap-11">
                                <Tooltip
                                  title="Proceed"
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  TransitionProps={{ timeout: 300 }}
                                >
                                  <button
                                    className="rounded py-2 mt-2 h-[7vh]  hover:opacity-90 bg-[#f4833d] text-white  px-4  "
                                    onClick={handleProceedClick}
                                  >
                                    Proceed
                                  </button>
                                </Tooltip>
                              </div>
                            )}

                            {selectedFile ? (
                              <Tooltip
                                title="Submit your PDF content"
                                arrow
                                placement="top"
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 300 }}
                              >
                                <button
                                  className={`rounded mt-2 h-[7vh] hover:opacity-90 ${
                                    isTextLoading
                                      ? "bg-[#0c3f5a] "
                                      : "bg-[#f4833d]"
                                  } text-white px-4 flex place-content-center align-middle justify-center flex-col`}
                                  onClick={handleKeywordsNavigation}
                                >
                                  {isTextLoading ? (
                                    <>
                                      <span className="loader"></span>
                                      <span className=" ms-4">
                                        Processing Content
                                      </span>
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </Tooltip>
                            ) : content ? (
                              <Tooltip
                                title="Submit your content"
                                arrow
                                placement="top"
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 300 }}
                              >
                                <button
                                  className={`rounded mt-2 h-[7vh] hover:opacity-90 ${
                                    isTextLoading
                                      ? "bg-[#0c3f5a] "
                                      : "bg-[#f4833d]"
                                  } text-white px-4 flex place-content-center align-middle justify-center flex-col`}
                                  onClick={handleSubmitContentButton}
                                >
                                  {isTextLoading ? (
                                    <>
                                      <span className="loader "></span>
                                      <span className="ms-5 ">
                                        Processing Content
                                      </span>
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </Tooltip>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Text area for content input ends here */}
              </div>
            ) : (
              // This screen will appear when a user reaches their monthly limit of 25 question sets.
              <div className=" w-full h-[81vh] m-auto flex flex-col justify-center align-middle items-center place-content-center  px-6 pt-4 rounded-2xl mx-5">
                <div className="border rounded-xl p-5 shadow-xl hover:shadow-myShadow2 flex flex-col justify-center align-middle items-center place-content-center">
                  {/* <img src={alertImage} alt="" className="w-1/4 " /> */}

                  <i className="fa-solid fa-triangle-exclamation text-red-400 fa-5x mb-4"></i>

                  <p>
                    You have{" "}
                    <span className="font-semibold text-red-500">
                      0 out of 25
                    </span>{" "}
                    questions sets available for this month till{" "}
                    <span className="font-semibold">
                      {formattedMonthEndDate}.
                    </span>
                  </p>

                  <p className="mt-5">
                    For more questions sets, upgrade to the{" "}
                    <span className="font-semibold">Unlimited plan</span>.
                  </p>

                  <p className="mt-5">
                    To change subscription duration{" "}
                    <Tooltip
                      title="Manage Subscription"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className=" text-[#f4833d]  underline hover:no-underline"
                        onClick={handleManageSubscriptionModal}
                      >
                        Click here
                      </button>
                    </Tooltip>
                    .
                  </p>

                  <div className="flex    ">
                    {/* <p className="">
                    {" "}
                    <Tooltip
                      title="Upgrade to Pro"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className="rounded mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
                        // onClick={handleQuillionzProModal}
                      >
                        Change Subscription
                      </button>
                    </Tooltip>
                  </p> */}

                    <p>
                      <Tooltip
                        title="Upgrade to Unlimited"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
                          onClick={handleGoForUnlimitedModal}
                        >
                          Upgrade to Unlimited
                        </button>
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
            )
          ) : (
            // This screen will appear when a user reaches their monthly limit of 25 question sets.
            <div className=" w-full h-[81vh] m-auto flex flex-col justify-center align-middle items-center place-content-center  px-6 pt-4 rounded-2xl mx-5">
              <div className="border rounded-xl p-5 shadow-xl hover:shadow-myShadow2 flex flex-col justify-center align-middle items-center place-content-center">
                {/* <img src={alertImage} alt="" className="w-1/4 " /> */}

                <i className="fa-solid fa-triangle-exclamation text-red-400 fa-5x mb-4"></i>

                {/* <p>
                  You have{" "}
                  <span className="font-semibold text-red-500">
                    0 out of 25
                  </span>{" "}
                  questions sets available for this month till{" "}
                  <span className="font-semibold">
                    {formattedMonthEndDate}.
                  </span>
                </p> */}

                <p className="mt-5">
                  <span>
                    Your{" "}
                    <span className="text-[#f4833d] font-semibold">
                      {subscriptionPlan}
                    </span>{" "}
                    subscription plan expired on
                  </span>{" "}
                  <span className="text-[#042739] font-semibold">
                    {localStorage.getItem("planExpiryDate")}
                  </span>
                </p>

                {isPlanExpired ? (
                  // <p className="mt-5">
                  //   For more questions sets, upgrade to the{" "}
                  //   <span className="font-semibold">Unlimited plan </span> after
                  //   subscription.
                  // </p>

                  ""
                ) : (
                  <p className="mt-5">
                    For more questions sets, upgrade to the{" "}
                    <span className="font-semibold">Unlimited plan</span>.
                  </p>
                )}

                {isPlanExpired ? (
                  <p className="mt-5">
                    To change your subscription duration, please click the
                    button below.
                  </p>
                ) : (
                  <p className="mt-5">
                    To change your subscription duration, please click the
                    button below
                    <Tooltip
                      title="Manage Subscription"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className=" text-[#f4833d]  underline hover:no-underline"
                        onClick={handleManageSubscriptionModal}
                      >
                        Click here
                      </button>
                    </Tooltip>
                    .
                  </p>
                )}

                <div className="flex    ">
                  {isPlanExpired ? (
                    <p className="">
                      {" "}
                      <Tooltip
                        title="Manage Subscription"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded mt-4 px-6 py-2 bg-[#f4833d] text-white  hover:bg-[#e06a21] "
                          onClick={handleManageSubscriptionModal}
                        >
                          Subscribe Now
                        </button>
                      </Tooltip>
                    </p>
                  ) : (
                    <p>
                      <Tooltip
                        title="Upgrade to Unlimited"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
                          onClick={handleGoForUnlimitedModal}
                        >
                          Upgrade to Unlimited
                        </button>
                      </Tooltip>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Stepper component (present of rightmost side) */}
          <div className="w-auto h-auto step-progress">
            {/* Pass the activeStep value to the StepProgress component */}
            <StepProgress activeStep={activeStep} />
          </div>
        </div>

        {/* Youtube Video Modal */}
        <Modal
          open={isYoutubeModalOpen}
          onClose={() => setIsYoutubeModalOpen(false)}
          aria-labelledby="youtube-modal-title"
          // Apply the style to the modal container
          className=" outline-none flex place-content-center items-center fixed"
          // Disable clicking on the backdrop and Escape key
          BackdropProps={{
            style: { pointerEvents: "none" },
          }}
          disableEscapeKeyDown
        >
          <div className=" outline-none bg-white p-5 rounded-lg animate-fade-slide-down w-[50vw] shadow-2xl hover:shadow-sm">
            <div className="flex place-content-between">
              <h2 className="text-lg   text-[#777E90]">
                <span>
                  Quillionz processes video with length between 2 to 30 minutes.
                  {""}
                </span>
                <span className=" ">
                  <a
                    href="https://support.google.com/youtube/answer/2734796?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#007bff] ms-2 hover:underline opacity-90 text-xl"
                  >
                    <i className="fa-solid fa-circle-question align-middle"></i>{" "}
                    {""}
                  </a>
                </span>
              </h2>

              <Tooltip
                title="Close"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
                  onClick={handleModalClose}
                >
                  <i className="fa-solid fa-xmark"></i>{" "}
                </button>
              </Tooltip>
            </div>

            {/* Input for YouTube Link */}
            <div className="flex align-middle items-center mt-7 mb-1">
              <input
                type="text"
                placeholder="Enter YouTube link here..."
                value={youtubeLink}
                onChange={handleInputChange}
                className="rounded px-2 py-2 border outline-[#007bff]  w-[37vw]"
              />

              {/* "Submit" button */}
              <Tooltip
                title="Submit"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className=" px-4 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] mx-4 transition duration-300 ease-in-out"
                  onClick={handleYoutubeLInkSubmit}
                >
                  Submit
                </button>
              </Tooltip>
            </div>
            {/* Display error message */}
            {errorMessage && (
              <p className="text-red-500 pb-3 pt-2">{errorMessage}</p>
            )}
            <p className="text-lg mb-2 py-5 ">
              <span className=" font-bold">Note:</span> This video link should
              have a closed caption.{" "}
              <a
                href="/closedcaption"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#007bff] me-1 hover:underline"
              >
                Click here
              </a>
              to see if your YouTube video has closed captions. To add closed
              captions,
              <a
                href="https://support.google.com/youtube/answer/2734796?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#007bff] ms-1 hover:underline"
              >
                click here
              </a>
              .
            </p>
          </div>
        </Modal>

        {/* <Modal
          open={isConfirmModalOpen}
          //   onClose={handleDeleteUserModalClose}
          aria-labelledby="deleteUser-modal-title"
          // Apply the style to the modal container
          className="flex place-content-center items-center fixed animate-fadeSlideDown outline-none"
          // Disable clicking on the backdrop and Escape key
          BackdropProps={{
            style: { pointerEvents: "none" },
          }}
          disableEscapeKeyDown
        >
          <div
            className={`bg-white handleDeleteUserModalOpen  shadow-lg text-[#212529] pb-6 px-6 rounded-lg   animate-fadeSlideDown w-max`}
          >
            <div className="text-[70px] text-center   ">
              <p>
                <span>
                  <i className=" text-red-400 fa-solid fa-triangle-exclamation"></i>
                </span>
              </p>
            </div>

            <div className="mb-4">
              <p className="">Are you sure you want to delete the user ?</p>
            </div>

            <div className="flex place-content-center gap-12">
              <Tooltip
                title="Delete this user"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="mt-4 px-6 py-1 bg-[#ffe3e3] text-[#D33A2C] font-semibold rounded-lg  transition-all ease-in-out duration-500 hover:shadow hover:shadow-[#fcaba0]"
                  onClick={handleConfirmUpload}
                >
                  Yes
                </button>
              </Tooltip>

              <Tooltip
                title="Keep this user"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`rounded-lg mt-4 px-6 py-1 hover:opacity-90 font-semibold bg-[#c2fbd7] hover:bg-[#b0fccd] hover:shadow hover:shadow-[#79b992] transition-all ease-in-out  duration-500 text-[#1a582a]`}
                  onClick={handleCancelUpload}
                >
                  No
                </button>
              </Tooltip>
            </div>
          </div>
        </Modal> */}

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}

        {/* Render the Go Premium Modal component */}
        <QuillionzProUpgradeModal
          isOpen={showManageSubscription}
          onClose={() => setShowManageSubscription(false)}
        />

        {/* If a user reaches their monthly limit of 25 question sets, they can open the modal for "Unlimited Question Sets"." */}
        <SubmitContentUnlimitedModal
          open={showGoForUnlimitedModal}
          onClose={() => setShowGoForUnlimitedModal(false)}
          hideFirstPartForSubmitPage={showGoForUnlimitedModal}
          setShowGoForUnlimitedModal={setShowGoForUnlimitedModal}
        />

        <ConfirmFileUploadModal
          isOpen={isConfirmModalOpen}
          onConfirm={handleConfirmUpload}
          onCancel={handleCancelUpload}
          fileName={selectedFile ? selectedFile.name : ""}
        />
      </div>

      {/* Footer */}
      {!isCanvas ? <MainFooter /> : null}
    </div>
  );
};

export default SubmitContent;
