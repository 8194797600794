import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { useFibQuestions } from "../../../hooks/QuestionPage/useFibQuestions";
import useCanvasDetection from "../../../hooks/useCanvasDetection";

function FibQuestionsRenderer({
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber,
  activeStep,
  handleFibQuestionChange,
  handleFibAnswerChange,
  handleFibQuestionsEdit,
  handleFibAnswersEdit,
  handleFibQuestionDelete,
  fibQuestions,
  index,
  question,
}) {
  const isCanvas = useCanvasDetection();

  return (
    <>
      {/* {fibQuestions.map((fibQuestion, index) => ( */}
      <div className="mb-4  border-slate-100 border-2 hover:border-dotted  hover:border-[#f4833d]  rounded question-answer-container p-4">
        <div className="flex place-content-between">
          <p className="flex">
            <span>{index + 1}. </span>{" "}
            {question.editing ? (
              <div
                contentEditable="true"
                id={`question-${question.index}`}
                className={` `}
                onBlur={(e) => {
                  handleFibQuestionChange(question.index, e.target.textContent);
                  handleFibQuestionsEdit(question.index);
                }}
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            ) : (
              <span
                onClick={() => handleFibQuestionsEdit(question.index)}
                className="cursor-pointer"
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            )}
          </p>
          <div className="flex edit-delete-icons">
            <Tooltip
              title="Edit"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleFibQuestionsEdit(question.index)}
                className="text-indigo-500 mr-2"
              >
                <i className="fa-solid fa-pen-to-square"></i>{" "}
              </button>
            </Tooltip>

            <Tooltip
              title="Delete"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleFibQuestionDelete(question.index)}
                className="text-red-500"
              >
                <i className="fa-solid fa-trash"></i>{" "}
              </button>
            </Tooltip>
          </div>
        </div>

        <p className="flex">
          <p className="font-semibold me-2">Answer:</p>{" "}
          {question.editingAnswer ? (
            <div
              contentEditable
              className="editable-answer"
              onBlur={(e) => {
                handleFibAnswerChange(question.index, e.target.textContent);
                handleFibAnswersEdit(index);
              }}
              dangerouslySetInnerHTML={{ __html: question.answer }}
            />
          ) : (
            <span
              onClick={() => handleFibAnswersEdit(question.index)}
              className="cursor-pointer"
              dangerouslySetInnerHTML={{ __html: question.answer }}
            />
          )}
        </p>
      </div>
      {/* // ))} */}
    </>
  );
}

export default FibQuestionsRenderer;
