import { useEffect, useState } from "react";

export const useMcqQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [mcqsQuestions, setMCQsQuestions] = useState([]);

  const [editingOptionIndices, setEditingOptionIndices] = useState({});

  const handleOptionEdit = (questionIndex, optionIndex) => {
    setEditingOptionIndices({
      ...editingOptionIndices,
      [questionIndex]: optionIndex,
    });
  };

  const handleOptionChange = (questionIndex, optionIndex, newValue) => {
    const updatedQuestions = [...mcqsQuestions];
    // Check if the option being edited is the correct answer
    if (
      updatedQuestions[questionIndex].answer ===
      updatedQuestions[questionIndex].options[optionIndex]
    ) {
      updatedQuestions[questionIndex].answer = newValue;
    }
    updatedQuestions[questionIndex].options[optionIndex] = newValue;
    setMCQsQuestions(updatedQuestions);
  };

  const handleOptionBlur = (questionIndex) => {
    const updatedIndices = { ...editingOptionIndices };
    delete updatedIndices[questionIndex];
    setEditingOptionIndices(updatedIndices);
  };

  // const handleMcqQuestionsEdit = (index) => {
  //   const updatedQuestions = [...mcqsQuestions];
  //   if (updatedQuestions[index]) {
  //     updatedQuestions[index].editing = !updatedQuestions[index].editing;
  //     setMCQsQuestions(updatedQuestions);
  //   }
  // };

  const handleMcqQuestionsEdit = (index) => {
    const updatedQuestions = [...mcqsQuestions];
    updatedQuestions[index].editing = !updatedQuestions[index].editing;

    if (updatedQuestions[index].editing) {
      // After setting editing to true, focus on the contentEditable div for the question
      setTimeout(() => {
        const contentEditableElement = document.getElementById(
          `question-${index}`
        ); // Add an ID to your contentEditable element
        if (contentEditableElement) {
          contentEditableElement.focus();
        }
      }, 0);
    }

    setMCQsQuestions(updatedQuestions);
  };

  const handleMcqQuestionDelete = (index) => {
    let updatedQuestions = [...mcqsQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    updatedQuestions = updatedQuestions.map((u, i) => ({ ...u, index: i }));
    setTotalQuestionNumber(totalQuestionNumber - 1);
    setMCQsQuestions(updatedQuestions);
  };

  const handleMcqQuestionChange = (index, newValue) => {
    const updatedQuestions = [...mcqsQuestions];
    updatedQuestions[index].question = newValue;
    setMCQsQuestions(updatedQuestions);
  };

  const handleMCQAnswersEdit = (index) => {
    const updatedQuestions = [...mcqsQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setMCQsQuestions(updatedQuestions);
    }
  };

  const handleMCQAnswerChange = (index, newValue) => {
    const updatedQuestions = [...mcqsQuestions];
    updatedQuestions[index].answer = newValue.trim();
    setMCQsQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      let mcq = generatedQuestionsFromSubmitContent?.response?.mcqs_questions
      ?.questions ||
      generatedQuestionsFromSubmitContent?.response?.mcqs_questions ||
      generatedQuestionsFromSubmitContent?.questionset?.mcqs_questions
        ?.questions ||
      generatedQuestionsFromSubmitContent?.questionset?.mcqs_questions ||
      [];
    mcq = mcq.map((m, index) => ({ ...m, category: 'mcq', index }));
    setMCQsQuestions(mcq);
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    mcqsQuestions,
    handleMcqQuestionsEdit,
    handleMcqQuestionDelete,
    handleMcqQuestionChange,
    handleOptionEdit,
    handleOptionChange,
    handleOptionBlur,
    editingOptionIndices,
    handleMCQAnswersEdit,
    handleMCQAnswerChange,
  };
};
