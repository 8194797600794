import axios from "../api/axios";

/**
 * Retrieves user data from the server based on the email.
 * @param {string} email - The email address of the user.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the API request.
 */
export const fetchUsersProfile = async (email) => {
  try {
    // Send a POST request to the server to fetch user data based on the email.
    const response = await axios.get(`/userinfodata?email=`+email);
    // Return the response object, which contains the user data.
    return response; // Alternatively, you can extract and return specific data from the response.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
