import React, { useState } from "react";

import WebsiteNavigation from "../../components/navigation/websiteNavigation/WebsiteNavigation";
import WebsiteFooter from "../../components/footer/WebsiteFooter";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../api/signup";
import {
  commonSuccessToastMessage,
  signUpSuccessToast,
} from "../../components/toastify/AllToastifyErrors";

import { occupations } from "../../data/Signup/occupationArray";
import SignUpForm from "./SignupForm";
import { validateFormFields } from "../../utils/Validations/signupValidation";
import { Tooltip, Zoom } from "@mui/material";
const SignupPage = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    occupation: occupations[0].value,
    otherOccupation: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    occupation: "",
    otherOccupation: "",
  });

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const handleSignUp = async () => {
    try {
      // Validate First Name and Last Name
      const validationErrors = validateFormFields(
        userData,
        setErrors,
        isChecked
      );

      if (userData.occupation === "") {
        console.error("Occupation");
      }

      if (errors)
        if (
          Object.values(errors).every((errorMessage) => errorMessage === "")
        ) {
          // If there are no errors, proceed with signup
          try {
            setErrors("");
          } catch (error) {
            console.log("Error while Sign up :", error);
          }
        }

      try {
        // sing up API calling
        if (!isAgreementChecked) {
          return;
        }
        const response = await signUp(
          userData.firstName,
          userData.lastName,
          userData.email,
          userData.password,
          userData.confirmPassword,
          userData.occupation
        );


        commonSuccessToastMessage(
          "Bravo! You've successfully signed up. Great to have you with us!"
        );

        navigate("https://www.quillionz.com/SignUp.html");
      } catch (error) {
        console.error("Error:", error.response?.status);

        if (error?.response?.status === 409) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email:
              "Account with this email address already exists. Please try logging in instead.",
          }));
          return;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
        }
      }
    } catch (error) {
      console.error("Error:", error);

      // if (error?.response?.status === 409) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     email:
      //       "Account with this email address already exists. Please try logging in instead.",
      //   }));
      //   return;
      // } else {
      //   setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      // }
    }
  };

  const handleSignupPro = () => {
    navigate("/quillionzpro");
  };

  return (
    <div
      className=" flex flex-col min-h-screen"
      //style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <WebsiteNavigation />

      <div className="flex-grow">
        <div className="flex md:h-[78vh] py-6  custom-scrollbar overflow-x-hidden overflow-y-auto px-[10%] animate-fade-slide-down  mt-1 ">
          <div className=" w-3/5 ">
            <div className="flex place-content-around border-b border-b-[#ccc] pb-4">
              <Tooltip
                title="Sign in with Facebook"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`mt-2 hover:opacity-90 bg-[#4264aa] text-white px-4 rounded flex place-content-center align-middle justify-center items-center`}
                >
                  <span className="text-[28px]">
                    <i className="fa-brands fa-square-facebook"></i>
                  </span>
                  <span className=" ms-6 font-semibold">
                    Sign in with Facebook
                  </span>
                </button>
              </Tooltip>

              <Tooltip
                title="Sign in with Google"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`mt-2 hover:opacity-90 bg-[#dd4b39] text-white px-4 rounded flex place-content-center align-middle justify-center items-center`}
                >
                  <span className="text-[28px]">
                    <i className="fa-brands fa-google"></i>
                  </span>
                  <span className=" ms-6 font-semibold">
                    Sign in with Google
                  </span>
                </button>
              </Tooltip>
            </div>

            <div>
              <p className="text-center italic">OR</p>
            </div>

            {/* Signup Form Starts here */}
            <div>
              <SignUpForm
                userData={userData}
                setUserData={setUserData}
                errors={errors}
                setErrors={setErrors}
              />

              <p className="mb-3 cursor-pointer text-justify">
                <input
                  className="me-2"
                  type="checkbox"
                  name=""
                  id=""
                  checked={isAgreementChecked}
                  onChange={() => setIsAgreementChecked(!isAgreementChecked)}
                />
                <span
                  onClick={() => setIsAgreementChecked(!isAgreementChecked)}
                >
                  Yes, I agree that Harbinger may contact me for news, updates,
                  marketing and sales purposes. I confirm to the processing of
                  my Personal Data as per the terms in the{" "}
                </span>

                <span className="text-[#337ab7] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02] ">
                  <Tooltip
                    title="Terms of use"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <button>
                      <a
                        href="/termsofuse"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=""
                      >
                        Terms of use.
                      </a>
                      {""}
                    </button>
                  </Tooltip>
                </span>
              </p>
              <p className="text-center">
                {errors.checkbox && (
                  <span className="text-red-500 antialiased animate-fade-slide-down text-[13px]">
                    {errors.checkbox}
                  </span>
                )}
              </p>
            </div>

            <div className="text-center">
              <Tooltip
                title="Sign up"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="text-[#112a38] text-lg font-bold border-2 border-[#f15f02] px-4 py-2 mt-7 mb-8 text-center transition ease-in-out delay-150   hover:bg-[#EF7920] hover:text-white"
                  onClick={handleSignUp}
                >
                  Sign Up For Free
                </button>
              </Tooltip>
            </div>
          </div>

          <div className=" w-2/5  ps-10 pt-6">
            <div>
              <h5 className="pb-4 border-b border-b-[#ccc] text-black font-medium text-[1.25rem]">
                Unlock More With Quillionz Pro!
              </h5>

              <p className="text-center font-light py-4 text-[#666666] text-[19px]">
                $14.99 per month
              </p>
            </div>

            <div className="pb-4 border-b border-b-[#ccc]">
              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">Generate Wh questions</span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">
                  Generate unlimited question sets per day
                </span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">
                  Save unlimited question sets
                </span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">
                  Input content as text and PDF
                </span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">
                  Export question sets as text file or QuilliQuiz
                </span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666]">Enjoy ad-free experience</span>
              </p>

              <p className="pb-4 flex items-center">
                <i className="text-xl pe-3 text-[#007bff] fa-regular fa-circle-check"></i>
                <span className="text-[#666] align-middle">
                  View context of a question
                </span>
              </p>
            </div>

            <div>
              <p className="text-center py-3 text-[15px]">
                <span className="text-[#666]">Sign Up For</span>
                <Tooltip
                  title="Quillionz Pro"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button onClick={handleSignupPro}>
                    <span className="ms-1  text-[#1dc8cd] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02] ">
                      Quillionz Pro
                    </span>
                  </button>
                </Tooltip>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <WebsiteFooter />
      </div>
    </div>
  );
};

export default SignupPage;
