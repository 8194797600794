/**
 * Author : Amol Rathod
 */

// Description: This function sends a POST request to the server to process a YouTube video link.

import axios from "../api/axios"; // Importing Axios for making HTTP requests

/**
 * Submits a YouTube video link for processing.
 *
 * @param {string} youtubeLink - The YouTube video URL to be submitted.
 * @returns {Promise} - A Promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the API request.
 */
export const youtubeVideo = async (youtubeLink, usergptKey, email) => {
  try {
    // Send a POST request to the server endpoint with the YouTube URL
    const response = await axios.post("/youtube_video", {
      youtube_url: youtubeLink,
      Usergpt_key: usergptKey, // Include Usergpt_key as a query parameter.
      email: email, // Include email as a query parameter.
    });

    return response; // Return the server response
  } catch (error) {
    throw error; // Re-throw any caught error for handling at a higher level
  }
};
