import React from "react";
import WebsiteNavigation from "../../components/navigation/websiteNavigation/WebsiteNavigation";
import { Tooltip, Zoom } from "@mui/material";
import MainFooter from "../../components/footer/MainFooter";
import WebsiteFooter from "../../components/footer/WebsiteFooter";

const TermsOfUse = () => {
  return (
    <div>
      <div>
        <WebsiteNavigation />
      </div>

      <div className="w-[85vw] mx-auto ">
        <div className=" pt-4 pb-10 border-b border-[#d0d0d0] ">
          <p className="text-[#666] text-[20px] font-medium my-8">
            Terms of Use
          </p>

          <div className="text-[#333] ">
            <p>
              HARBINGER AI INC. MAINTAINS THIS WEB SITE AS A SERVICE TO THE
              VISITORS AND QUILLIONZ CUSTOMERS. BY USING THIS WEBSITE, YOU
              INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THE FOLLOWING TERMS
              OF USE <strong>(“TERMS”)</strong>. PLEASE REVIEW THE FOLLOWING
              TERMS CAREFULLY. IF YOU DO NOT AGREE TO THESE TERMS, YOU SHOULD
              NOT USE THE WEBSITE, OR REVIEW INFORMATION, OR OBTAIN GOODS OR
              PRODUCTS FROM THIS WEBSITE.
            </p>

            <p>
              <strong>"Company"; "Harbinger"; "us"; "we"; "our"</strong> shall
              mean Harbinger AI Inc., having its office at 16770, NE 79th
              Street, Ste 106, Redmond, Washington 98052 USA.
            </p>

            <p>
              <strong>"Website"</strong> shall mean the sites, microsites and
              mobile applications owned, operated and managed by us.
            </p>

            <p>
              <strong>"Member"</strong> shall mean customers (individuals or
              entities) of Harbinger for the purpose of accessing or using
              Website, Software and/or Services (availing the free or paid
              version) on B2B or B2C basis.
            </p>

            <p>
              <strong>"User"</strong> shall include in its ambit users that are
              individuals that access the Website{" "}
              <strong>(“you”; “your”)</strong>.
            </p>

            <p>
              <strong>"User Account"</strong> shall mean the account that the
              User shall be required to create with the Website by providing the
              information sought by us, in order to use the trial and/or paid
              version of the Software.
            </p>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">1. Acceptance of Terms:</p>

          <div className="text-[#333]">
            <p>
              Welcome to the Website. This Website is owned by Harbinger AI Inc.
              Harbinger provides the Website to you, subject to the Terms. If
              you wish to use the Website, even if you only browse the Website,
              you must agree to these Terms. If you do not agree to the Terms,
              any use of the Website by you is unauthorized and you should stop
              accessing the Website immediately. Your continued use of the
              Website will constitute assent to these Terms. For ease of use,
              wherever the word Quillionz appears in this document, it will be
              read as Quillionz®. We may update the Terms periodically with or
              without notice to you. If you are using or accessing the Software
              and/or Services on the Website as an authorised User of a Member,
              you shall be bound by the provisions of the End User License
              Agreement (EULA) along with these Terms.{" "}
              <Tooltip
                title="EULA"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <span className="text-[#337ab7] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02] ">
                  <a href="EULA.html"> Click here to view EULA.</a>
                </span>
              </Tooltip>
            </p>

            <p>
              If you are a Member using or accessing the Software and/ or
              Services on a B2B basis, you shall ensure that your authorised
              representatives use or access the Software and/or Services subject
              to the acceptance and continuous compliance of the Terms and the
              EULA.
            </p>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">
            2. Description of Services:
          </p>

          <p className="ms-8 text-[#333]">
            <ul className="list-disc list-outside">
              <li>
                During the Subscription Term (as defined below) and subject to
                the Terms, Harbinger hereby grants you and you hereby subscribe
                for/to, a non-exclusive, non-assignable, limited license to use,
                access download, and install the Software (as defined below)
                and/or Services (as defined below) for your internal use only{" "}
              </li>
              <li>
                Nothing herein contained shall be construed as granted to you
                any intellectual property right, which includes copyrights,
                regarding the Software and/or Services except as expressly
                provided for hereunder.
              </li>
              <li>
                These Terms shall govern your use of the trial version and/ or
                paid version of our proprietary software for learning management
                viz. Quillionz <strong>("Software")</strong>. Quillionz develops
                tools, solution, information, video, audio, pictures, animation,
                digital conversion, questionnaire or the like on an automated
                basis for the User (the <strong>“Deliverables”</strong>) based
                on the content, information, data, text, blogs, write-ups,
                software, music, sound, photographs, graphics, video, messages
                or other materials uploaded or posted on the Website by the User{" "}
                <strong>(“User Content”)</strong> for effectively managing their
                learning projects (the <strong>"Services"</strong>){" "}
              </li>
              <li>
                The Website may offer access to certain ancillary services such
                as Harbinger guest blog programme, testimonials, newsletters,
                quotes wherein you may voluntarily submit information to the
                Website (in which case, such information would be included in
                the “User Content”).{" "}
              </li>
            </ul>
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">
            3. Your Registration Obligations:
          </p>

          <p className=" text-[#333]">
            As a condition to using Services, you may be required to register
            with us and select a password and enter your email address. You
            agree to ensure that you shall provide Harbinger with accurate,
            complete, and updated registration information. You shall be
            responsible to maintain and promptly update your registration data
            like email, phone number, which you submit to us while signing up
            for the User Account. Failure to do so shall constitute a breach of
            these Terms, which may result in immediate termination of User's
            Account. Harbinger reserves the right to refuse registration of, or
            cancel a User Account of any User in its discretion. User shall be
            responsible for maintaining the confidentiality of User's account
            and password.
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">4. Subscription Term:</p>

          <p className=" text-[#333]">
            <span className="underline">For free version (‘Quillionz’)</span>:
            The license to use, access and evaluate the Services is granted to
            you through our website, and shall be effective from the date of
            your subscription to the free version of the Services until your
            subscription is terminated in accordance with the Terms.
            <span className="underline">
              For paid version (‘Quillionz Pro’)
            </span>
            : The license to use, and access the Services is granted to you for
            a subscription period selected by you from our website{" "}
            <strong>("Initial Subscription Term")</strong> and unless your
            subscription is terminated in accordance with the Terms, your
            subscription shall automatically be renewed for a period of similar
            duration as that of your Initial Subscription Period (each "Renewal
            Term"). The Initial Subscription Term and each{" "}
            <strong>Renewal Term</strong> shall be collectively referred to as
            the <strong>("Subscription Term")</strong>. The Subscription Term
            shall be effective from the actual date of your subscription to the
            paid Services.
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">
            5. Subscription Fees (applicable to paid version):
          </p>

          <div className=" text-[#333]">
            <p>
              In consideration of the Services provided under this Agreement by
              Harbinger, you agree to pay us the Subscription fees
              ("Subscription Fees") in the amount and for the Subscription Term,
              you have opted for and agreed to, on our Website. Unless you
              terminate your subscription any time before the end of your
              billing cycle, you authorize us to charge you the Subscription
              Fees for the next billing cycle, at the then applicable rates.
              Subscription Fees and other prices quoted are exclusive of
              applicable taxes. You shall pay all applicable taxes, duties,
              levies, and other similar charges (and any related interest and
              penalties) imposed, however, designated as a result of the
              existence or operation of this EULA (except for taxes on
              Harbinger’s net income).
            </p>

            <div className="ms-8">
              <p className="py-4 font-bold">
                In the event of non-payment of the Subscription Fees-
              </p>

              <div>
                <p className="pb-3 font-bold">5.1 Account Suspension:</p>

                <p>
                  We may at our discretion suspend your subscription and
                  intimate you about the same. You may at your option, choose to
                  have the subscription reinstated subject to the payment of the
                  Subscription Fees.
                </p>
              </div>

              <div className="mt-4">
                <p className="pb-3 font-bold">5.2 Account Termination:</p>

                <p>
                  We may at our discretion choose to terminate your
                  subscription, in which case, we shall notify you about the
                  same in writing. In the event of termination of your
                  subscription, we reserve our right to delete all the User
                  Content, at our sole discretion.
                </p>
              </div>

              <div className="mt-4">
                <p className="pb-3 font-bold">5.3 Customer Unsubscribe:</p>

                <p>
                  If you wish to unsubscribe for the Services, you may do so
                  during the currency of the plan. In case you unsubscribe the
                  Services, Harbinger will NOT refund the Subscription Fees paid
                  by you as the subscription features shall remain active till
                  the end of the Subscription Term.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">6. Privacy Policy:</p>

          <p className=" text-[#333]">
            Your information on Harbinger is subject to our Privacy Policy. The
            Privacy Policy is available at:{" "}
            <span className="text-[#337ab7] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02] ">
              Privacy Policy.
            </span>
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-6">7. Member Data:</p>

          <p className=" text-[#333]">
            You understand that all the User Content shared by you, and the
            Deliverables as may be provided by Harbinger, on the Website
            (whether in whole or in part shall be together referred to as the{" "}
            <strong>"Data"</strong>), shall belong to you for the purpose of
            this Agreement, irrespective of the author or the person from whom
            such Data has originated. You have the right to delete the Data at
            any point of time at your discretion. Harbinger does not control the
            User Content posted on the Website. Under no circumstances shall
            Harbinger be liable or responsible in any way for any Data,
            including for any errors or omissions in any Data, or for any loss
            or damage of any kind incurred as a result of the use of any Data
            posted, emailed, transmitted or otherwise made available via the
            Website. You understand and acknowledge that Harbinger reserves the
            right to edit, modify or delete any Data on the Website at any point
            of time at its sole discretion with or without notice to you. You
            understand and acknowledge that you post or share such User Content
            with the Website voluntarily on your own account and are solely
            liable for the originality, accuracy of such User Content. Harbinger
            shall not be responsible or liable for any such User Content shared,
            posted or submitted by you.
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] ">
            8. Copyright and Limited Right to Use
          </p>

          <p className="py-5">
            Subject to clause 2, you may only access and use the information for
            your personal purpose. You agree not to:
          </p>

          <div className="ms-8 text-[#333]">
            <ul className="list-disc list-outside">
              <li>
                {" "}
                reproduce, redistribute, duplicate, copy, sell, resell, lease,
                transfer, sub-license, publish or exploit for any commercial
                purposes, any portion of the Website, use of the Website, or
                access to the Website{" "}
              </li>

              <li>
                upload, post, email, transmit or otherwise make available any
                Data that is unlawful, harmful, threatening, abusive, harassing,
                tortious, defamatory, vulgar, obscene, libellous, invasive of
                another's privacy, hateful, or racially, ethnically or otherwise
                objectionable{" "}
              </li>

              <li>
                decompile, disassemble, reverse engineer, and attempt to
                discover the Software's source code or otherwise reduce the
                Software to a human-perceivable form{" "}
              </li>

              <li>
                place the Software onto a server so that it is accessible via a
                public network{" "}
              </li>

              <li>
                export or re-export the Services in any form in violation of any
                applicable laws and regulations regarding export control of the
                country in which you obtained them{" "}
              </li>

              <li>
                use the Services for any purposes that potentially could cause
                or might result into any property damage, death, bodily injury
                or personal injury or any other hazardous application of the
                Services{" "}
              </li>

              <li>impersonate any person or entity </li>

              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any User Content transmitted through the
                Website{" "}
              </li>

              <li>
                upload, post, email, transmit or otherwise make available any
                Data that you do not have a right to make available under any
                law or under contractual or fiduciary relationships (such as
                insider information, proprietary and confidential information
                learned or disclosed as part of employment relationships or
                under non-disclosure agreements){" "}
              </li>

              <li>
                upload, post, email, transmit or otherwise make available any
                Data that infringes any patent, trademark, trade secret,
                copyright or other proprietary rights of any party{" "}
              </li>

              <li>
                upload, post, email, transmit or otherwise make available any
                unsolicited or unauthorized advertising, promotional materials,
                "junk mail," "spam," "chain letters," "pyramid schemes," or any
                other form of solicitation{" "}
              </li>

              <li>
                upload, post, email, transmit or otherwise make available any
                material that contains software virus or any other computer
                code, files or programs designed to interrupt, destroy or limit
                the functionality of any computer software or hardware or
                telecommunications equipment{" "}
              </li>

              <li>
                disrupt the normal flow of or otherwise act in a manner that
                negatively affects other Member’s ability to engage in real time
                exchanges{" "}
              </li>

              <li>
                interfere with or disrupt the Website or servers or networks
                connected to the Website, or disobey any requirements,
                procedures, policies or regulations of networks connected to the
                Website{" "}
              </li>

              <li>
                use automated scripts or programs to access or download pages,
                documents, images or any other content in the application other
                than the provided API{" "}
              </li>

              <li>
                intentionally or unintentionally violate any applicable local,
                state, national or international laws and any regulations having
                the force of law{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] ">9. Disclosures:</p>

          <p className="py-5">
            You acknowledge and agree that Harbinger may disclose the Data if
            required to do so by law or in the good-faith belief that such
            preservation or disclosure is reasonably necessary to:
          </p>

          <div className="ms-8 text-[#333]">
            <ul className="list-disc list-outside">
              <li>comply with legal process </li>
              <li>enforce the Terms </li>
              <li>
                respond to claims that any Data violates the rights of
                third-parties{" "}
              </li>
              <li>
                protect the rights, property, or personal safety of Harbinger,
                its Users and any member(s) of the public{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">
            10. Maintenance and Support:
          </p>

          <p className=" text-[#333]">
            We provide the necessary support to the paid Users through a
            web-based support system. We will take commercially reasonable
            efforts to provide you with the support to resolve all your queries
            within one working day.
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">11. Termination:</p>

          <div className=" text-[#333]">
            <p>
              A Member may terminate the subscription to the paid Services, at
              any time, by selecting the “Cancel Account” link on our website.
              Please refer account info in profile section.
            </p>

            <p>
              You agree that Harbinger, in its sole discretion and subject to
              compliance of the applicable laws, may terminate your User
              Account, subscription and/or use of the Website, and remove and
              discard any Data within the Website if Harbinger believes that you
              have violated or acted inconsistently with the letter or spirit of
              the Terms and you fail to correct the reported breach by Harbinger
              within 7 days from the date of receipt of such notice. Harbinger
              may also in its sole discretion and at any time discontinue
              providing the Website, or any part thereof, with notice to you.
              Further, you agree that Harbinger shall not be liable to you or
              any third-party for any termination of your access to the Website,
              any modification, suspension or discontinuance of the Services by
              Harbinger.
            </p>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">
            12. Links and third party advertisers (applicable to free version):
          </p>

          <p className=" text-[#333]">
            The Website may provide, links, advertisements, promotions of other
            products, services or resources of Harbinger or its affiliates. You
            understand and acknowledge that Harbinger shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with availability or
            non-availability of such products, services or resources, use of or
            reliance on any such links, advertisements or promotions available
            on or through any such site, advertisement or resource. You shall be
            solely responsible to access such links, promotions and/or
            advertisements.
          </p>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">
            13. Proprietary Rights of Harbinger:
          </p>

          <div className=" text-[#333]">
            <p>
              You understand, acknowledge and agree that Harbinger is the sole
              owner of all rights, title and interest, including all
              intellectual property rights in the Website, the Software and any
              other software used in connection with the Website.
            </p>

            <p>
              The name "Quillionz", “Quillionz Pro” and the Quillionz logo are
              registered trademarks of Harbinger and may not be used in
              connection with the products or services of any other person in
              any manner that is likely to cause confusion. All other trademarks
              not owned by Quillionz that appear on this Website are the
              property of their respective owners. You understand and
              acknowledge that Harbinger uses third-party licenses and/ or open
              source tools in the Website, or in the maintenance thereof; that
              Harbinger is an authorised licensee of such third-party licenses
              and/or tools pursuant to the respective license sterms; and that
              Harbinger is compliant with the respective license terms of each
              of such licenses. You agree that by using the Software and/or the
              Services, you give us a permission and a limited license to use
              your name, or your company name and logo in self-promotional
              materials, web-page, proposal and similar matters and indicating
              you as our customer. In case you would like us not to use your
              name, trademarks, service marks, and/or logo on the Website, you
              can write to Harbinger’s grievance redressal email-address at 
              <span className="underline px-1">info@quillionz.com.</span> 
            </p>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] ">
            14. Disclaimer of Warranties:
          </p>

          <p className="py-5">You expressly understand and agree that:</p>

          <div className="ms-8 text-[#333]">
            <ul className="list-disc list-outside">
              <li>
                Harbinger does not warrant that the Site will be available at
                any given time, secure, accurate or free of error{" "}
              </li>
              <li>
                your use of the Website is at your sole risk and you assume the
                risk that the Website may provide incorrect information to you
                or your workers, as well as the risk that any material
                downloaded by you from the Website may cause loss of data or
                damage to your computer system. Harbinger makes no warranty of
                any kind with respect to user-generated User Content and/or
                Deliverables. THE WEBSITE AND/OR DELIVERABLES IS PROVIDED ON AN
                "AS IS" AND "AS AVAILABLE" BASIS. HARBINGER EXPRESSLY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OR
                NON-INFRINGEMENT.{" "}
              </li>
              <li>
                any material downloaded or otherwise obtained through the use of
                the Website is done at your own discretion and risk and that you
                will be solely responsible for any damage to your computer
                system or loss of data that results from the download of any
                such material.{" "}
              </li>
              <li>
                no advice, Deliverables or other information, whether oral or
                written, obtained by you from Harbinger or through or from the
                Website shall create any warranty not expressly stated in the
                Terms.{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] ">
            15. Indemnification; Limitation of Liability:
          </p>

          <p className="pt-5">
            You agree to indemnify, defend and hold harmless Harbinger and its
            officers, directors, and employees (each, an “Indemnified Party”)
            from and against all claims, fines, suits, proceedings, causes of
            action, demands, or liabilities of any kind or of any nature arising
            out of or in connection with your violation of the Terms or for any
            unauthorised use of the Website or for any fraudulent credit card
            claims made by you.
          </p>

          <p className="pb-5">
            You expressly understand and agree that Harbinger shall not be
            liable under or for contract, negligence, strict liability or other
            theory (even if Harbinger has been advised of the possibility of
            such damages) for (a) any indirect, incidental, special,
            consequential or exemplary damages, including but not limited to,
            damages for loss of profits, goodwill, use, data or other intangible
            losses, (b) for direct or indirect loss, claims, liabilities, costs
            arising out of or related to or resulting from:
          </p>

          <div className="ms-8 text-[#333]">
            <ul className="list-disc list-outside">
              <li>
                the use or the inability to use the Website, due to downtime,
                server failure or otherwise
              </li>
              <li>
                unauthorized access to or alteration of your User Content or
                Data through your User Account
              </li>
              <li>
                statements or conduct of any third party on the Website; or
              </li>
              <li className=" list-none ">
                NOTWITHSTANDING ANYTHING CONTAINED HEREIN OR ELSEWHERE, THE
                MAXIMUM LIABILITY OF HARBINGER UNDER THESE TERMS,
                <ol className="list-outside list-[upper-alpha] ms-9">
                  <li>
                    IN CASE OF THE PAID VERSION SHALL NOT EXCEED THE
                    SUBSCRIPTION FEES YOU HAVE PAID US IN THE PRECEDING ONE
                    MONTH IN WHICH THE CLAIM AROSE, AND
                  </li>

                  <li>
                    IN CASE OF FREE VERSION SHALL NOT EXCEED US$ 0.00 PER
                    MEMBER. THIS LIABILITY OF HARBINGER SHALL APPLY IRRESPECTIVE
                    OF THE FORM AND NUMBER OF CLAIMS ARISING OUT OF THE USE OR
                    ACCESS OF THE WEBSITE. THE PARTIES INTEND THAT THE ABOVE
                    LIMITATION OF LIABILITY SHOULD APPLY EVEN IF IT CAUSES ANY
                    WARRANTY TO FAIL OF ITS ESSENTIAL PURPOSE.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>

        <div className="py-6 border-b border-[#d0d0d0]">
          <p className=" font-bold text-[16px] pb-4">
            16. Choice of Law and Jurisdiction:
          </p>

          <div className=" text-[#333]">
            <p>
              This Agreement shall be construed and governed by the laws of
              State of Washington without regard to principles of conflict of
              laws. Any dispute arising, between you and Harbinger, shall be
              submitted to the arbitration to be conducted in Washington, USA in
              English language, in accordance with the applicable rules of
              International Chamber of Commerce by a sole arbitrator, who shall
              be appointed by Harbinger and you mutually; and the award made in
              pursuance thereof shall be binding on the parties. The dispute
              resolution and arbitration process mentioned in this Section will
              not prohibit parties from approaching the courts of competent
              jurisdiction for appropriate interim reliefs. Parties further
              agree that the courts in the State of Washington, USA shall have
              an exclusive jurisdiction over such disputes.
            </p>
          </div>
        </div>

        <div className="py-6 ">
          <p className=" font-bold text-[16px] pb-4">17. Miscellaneous:</p>

          <div className=" text-[#333]">
            <p>
              <span className="italic">Entire Understanding:</span> These Terms,
              along with the Privacy Policy constitute the entire agreement
              between you and Harbinger and governs your use of the Website.
              <span className="italic">Waiver:</span> The failure of Harbinger
              to exercise or enforce any right or provision of the Terms shall
              not constitute a waiver of such right or provision. You agree that
              regardless of any statute or law to the contrary, any claim or
              cause of action arising out of or related to use of the Website or
              the Terms must be filed within one (1) year after such claim or
              cause of action arose or be forever barred.
            </p>

            <p>
              <span className="italic">Severability:</span> If any provision of
              the Terms is found by a court of competent jurisdiction to be
              invalid, the parties nevertheless agree that the court should
              endeavour to give effect to the parties' intentions as reflected
              in the provision, and the other provisions of the Terms shall
              remain in full force and effect.
              <span className="italic">General: </span> You agree to use the
              Services at your own risk. If You have any questions, complaints
              or claims with respect to the Services or Software, you should
              contact to{" "}
              <span className="underline">
                complianceofficer@harbingergroup.com
              </span>
              .
            </p>
          </div>
        </div>
      </div>

      <WebsiteFooter />
    </div>
  );
};

export default TermsOfUse;
