/**
 * @author : Amol Rathod
 * @description : This function sends user feedback to the server using Axios.
 */

import axios from "../api/axios";

/**
 * Sends user feedback to the server.
 *
 * @param {number} UserId - The ID of the user submitting the feedback.
 * @param {number} UserRating - The rating given by the user (e.g., 1 to 5).
 * @param {string} UserFeedback - The feedback text provided by the user.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const shareYourFeedback = async (UserId, UserRating, UserFeedback) => {
  try {
    // Send a POST request to the server to submit the review content.
    const response = await axios.post("/feedback", {
      UserId,
      UserRating,
      UserFeedback,
    });

    // Return the response from the server.
    return response;
  } catch (error) {
    // If an error occurs, throw the error for handling at a higher level.
    throw error;
  }
};
