/**
 * @author : Amol Rathod
 *
 * @description : This function makes an API request to retrieve a question's answer based on provided parameters.It uses the Axios library to perform a POST request to the designated endpoint.
 */

import axios from "../api/axios"; // Importing the Axios library for making HTTP requests.

/**
 * Retrieves an answer for a given question.
 * @param {string} doc_id - The document ID associated with the question.
 * @param {string} text - The text of the question.
 * @param {Array} selectedKeywords - An array of selected keywords relevant to the question.
 * @param {Object} queryData - Additional query data for refining the request.
 * @param {string} usergptKey - The Users GPT key for unlimited question generation..
 * @param {string} email - The user's email.
 * @returns {Promise} - A promise that resolves to the response from the API.
 * @throws {Error} - Throws an error if the API request fails.
 */
export const getQuestionAnswer = async (
  doc_id,
  text,
  selectedKeywords,
  // queryData,
  usergptKey,
  email
) => {
  try {
    // Sending a POST request to the API endpoint to retrieve the question's answer.
    const response = await axios.post(
      "/get_question_answer",
      {
        doc_id,
        text,
        keywords: selectedKeywords,
      },
      {
        params: {
          // Include the title as a query parameter.
          Usergpt_key: usergptKey, // Include Usergpt_key as a query parameter.
          email: email, // Include email as a query parameter.
        },
      }
    );


    // Returning the response to the caller.
    return response;
  } catch (error) {
    // If an error occurs during the API request, rethrow the error to be handled in the caller component.
    throw error;
  }
};
