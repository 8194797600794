import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";

function WhQuestionsRenderer({
  questions,
  handleShortWhQuestionChange,
  handleShortWhAnswerChange,
  handleShortWhQuestionsEdit,
  handleShortWhAnswersEdit,
  handleShortWhQuestionDelete,
}) {
  
  let totalQuestion = 1;
  return (
    <>
      {questions.map((whQuestion, index) => (
        <div
          key={index}
          className="mb-4 border-slate-100 hover:border-[#f4833d] border-2 hover:border-dotted rounded question-answer-container p-4"
        >
          <div className="flex place-content-between">
            <div className="flex ">
              <span>{totalQuestion++}. </span>{" "}
              {whQuestion.editing ? (
                <div
                  contentEditable="true"
                  id={`question-${index}`}
                  className="wh-question-editable "
                  onBlur={(e) => {
                    handleShortWhQuestionChange(index, e.target.textContent);
                    handleShortWhQuestionsEdit(index);
                  }}
                  dangerouslySetInnerHTML={{ __html: whQuestion.question }}
                />
              ) : (
                <span
                  onClick={() => handleShortWhQuestionsEdit(index)}
                  className="cursor-pointer"
                  dangerouslySetInnerHTML={{ __html: whQuestion.question }}
                />
              )}
            </div>
            <div className="flex  edit-delete-icons">
              <Tooltip
                title="Edit"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  onClick={() => handleShortWhQuestionsEdit(index)}
                  className="text-indigo-500 mr-2"
                >
                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                </button>
              </Tooltip>

              <Tooltip
                title="Delete"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  onClick={() => handleShortWhQuestionDelete(index)}
                  className="text-red-500"
                >
                  <i className="fa-solid fa-trash"></i>{" "}
                </button>
              </Tooltip>
            </div>
          </div>
          <p>
            <p className="font-semibold">Answer:</p>{" "}
            {whQuestion.editingAnswer ? (
              <div
                contentEditable="true"
                className="answer-editable"
                onBlur={(e) => {
                  handleShortWhAnswerChange(index, e.target.textContent);
                  handleShortWhAnswersEdit(index);
                }}
                dangerouslySetInnerHTML={{ __html: whQuestion.answer }}
              />
            ) : (
              <span
                onClick={() => handleShortWhAnswersEdit(index)}
                className="cursor-pointer"
                dangerouslySetInnerHTML={{ __html: whQuestion.answer }}
              />
            )}
          </p>
        </div>
      ))}
    </>
  );
}

export default WhQuestionsRenderer;
