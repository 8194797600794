import React, { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { Link, useLocation, useNavigate } from "react-router-dom";
import GenerateQuestionsModal from "./GenerateQuestionsModal";
import LoadingOverlay from "../overlays/LoadingOverlay";
import queryData from "../../data/questionQuery/queryData";
import { reviewContent } from "../../api/reviewContent";
import { getQuestionAnswer } from "../../api/getQuestionAnswer";
import { commonErrorToastMessage } from "../toastify/AllToastifyErrors";

// TODO: Generate Questions are Remaining.

export default function ContentReadinessModal({ isOpen, onClose }) {
  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // Function to handle closing the modal
  const handleModalClose = () => {
    onClose();
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  const handleStartOver = () => {
    navigate("/submitcontent");
  };

  const usergptKey = localStorage.getItem("userGPTKey");
  const email = localStorage.getItem("email");

  const generateQuestionAnswer = async () => {
    try {
      // When user exhausted all userAttempts for question generation for a month
      const reaminingQuestionSets = localStorage.getItem("UserAttempts");

      if (reaminingQuestionSets == 0 && usergptKey === "LIMITED") {
        commonErrorToastMessage(
          "You have exhausted your monthly 25 attempts. You can upgrade to Unlimited Plan."
        );

        return;
      }

      setIsFront(false);
      setIsModalOpen(true);

      const content = localStorage.getItem("content");
      const doc_id = localStorage.getItem("doc_id");

      const selectedKeywords = JSON.parse(
        localStorage.getItem("selectedKeywordsArray")
      );

      // get_question_answer API calling
      const response = await getQuestionAnswer(
        doc_id,
        content,
        selectedKeywords,
        // queryData,
        usergptKey,
        email
      );

      console.log("Response of Questions Generation : ", response.data);

      // localStorage.setItem("UserAttempts", UserAttempts);

      if (response.data.response.no_ques !== 0) {
        // Log the API response
        navigate("/question", {
          state: { generatedQuestions: response.data },
        });
      } else {
        console.error("Error generating questions in ContentReadinessModal.");
        commonErrorToastMessage("Error while generating questions.");
        setIsModalOpen(false);
        onClose();

        return;
      }

      // navigate("/question");

      setIsModalOpen(false);
    } catch (error) {
      console.error(
        "Error generating questions in ContentReadinessModal : ",
        error.message
      );
      commonErrorToastMessage("Error while generating questions.");
      setIsLoading(false);
      setIsModalOpen(false);
      onClose();
    }
  };

  const [isFront, setIsFront] = useState(true); // Add this state to manage front/back view

  const handleGoUnlimited = () => {
    setIsFront(false); // Switch to the back view when clicking "Unlimited" button
  };

  const handleGoBack = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlipped = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="bg-[00000080]"
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              margin: "auto",
              //   backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // Set the height to 100% of the viewport height
            }}
          >
            <div
              className={` rounded-lg animate-fade-slide-down modal-content shadow-xl hover:shadow-2xl  ${
                isFront ? "unflipped" : "flipped"
              }`} /* Updated this line */
            >
              {isFront ? (
                <div className=" bg-white readiness-pass-front-content shadow-2xl rounded  animate-fade-slide-down ">
                  {/* <button
                onClick={onClose}
                className="pt-4 px-4 flex place-content-end text-red-600 font-bold text-xl"
              >
                <i className="fa-regular fa-circle-xmark"></i>
                {""}
              </button> */}
                  <div className="">
                    <p className=" p-12 text-[18px] text-[#777e8d]">
                      Oops, it seems that your content needs some more tweaking.
                      To get the best questions from Quillionz, make sure that
                      your Content Readiness Meter reads at least 90% before you
                      proceed.
                    </p>

                    <div className="pb-12 flex place-content-center gap-10">
                      <Tooltip
                        title="Go Back"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded border border-[#f4833d] text-[#f4833d] hover:bg-[#f4833d] hover:text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                          onClick={handleModalClose}
                        >
                          Go Back
                        </button>
                      </Tooltip>

                      <Tooltip
                        title="Start Over"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded bg-[#f4833d] hover:opacity-80 text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                          onClick={handleStartOver}
                        >
                          {" "}
                          Start Over
                        </button>
                      </Tooltip>

                      <Tooltip
                        title="Continue Anyway"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <button
                          className="rounded bg-[#f4833d] hover:opacity-80 text-white px-4 py-2 mt-1 transition duration-300 ease-in-out"
                          onClick={generateQuestionAnswer}
                        >
                          {" "}
                          Continue Anyway
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Render the GenerateQuestionsModal component if the modal is open */}
                </div>
              ) : (
                <div className="back-content">
                  {isModalOpen && (
                    <GenerateQuestionsModal
                      isOpen={isModalOpen}
                      onClose={handleModalClose}
                    />
                  )}
                </div>
              )}

              {/* Loading overlay */}
              {isLoading && <LoadingOverlay />}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
