import React, { useState } from "react";
import closed_caption_img from "../../../assets/images/closed_caption.jpg";

const ClosedCaption = () => {
  return (
    <div className=" rounded-lg animate-fade-slide-down text-center">
      <div className="">
        <div className="my-5">
          <h1 className="text-3xl font-medium">
            How to spot if your YouTube video has a closed caption
          </h1>
        </div>

        <div className="my-6">
          <p className="text-base font-normal">
            Videos with captions available will have “CC” underneath the video
            summary in search results.
          </p>
        </div>

        <div className="flex justify-center my-4 ">
          <img src={closed_caption_img} alt="" className="h-96 max-w-full" />
        </div>
      </div>
    </div>
  );
};

export default ClosedCaption;
