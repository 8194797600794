/**
 * @author : Amol Rathod
 * @description : Function to reset a user's password.
 */

import axios from "../api/axios";

/**
 * @param {string} newPassword - The new password to set.
 * @param {string} confirmPassword - The confirmation of the new password.
 * @param {string} email - The user's email address.
 * @param {string} token - The reset token received via email.
 * @returns {Promise} A Promise that resolves with the response from the server.
 * @throws {Error} If an error occurs during the reset password process.
 */

export const resetUserPassword = async (
  newPassword,
  confirmPassword,
  email,
  token
) => {
  try {
    // Create an object containing the new password and its confirmation.
    const dataToChangePassword = {
      newPassword,
      ConfirmPassword: confirmPassword,
    };

    // Send a PUT request to reset the user's password.
    const response = await axios.put("/reset_password", dataToChangePassword, {
      params: {
        email,
        token,
      },
    });

    return response;
  } catch (error) {
    // If an error occurs during the password reset process, throw it.
    throw error;
  }
};
