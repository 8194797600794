import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Modal from "@mui/material/Modal";
import HelpingPointer from '../../components/submitContentComponents/HelpingPointers';
export default function ConfirmFileUploadModal({
  isOpen,
  onConfirm,
  onCancel,
  fileName,
}) {
  const [showHelpingPointer, setShowHelpingPointer] = useState(false);
  const truncatedFileName =
    fileName.length > 50 ? fileName.slice(0, 50) + "..." : fileName;

    const handleNoButtonClick = () => {
      // Set the state to true when "No" is clicked
      setShowHelpingPointer(true);
      // Optionally, you can call onCancel() if needed
      onCancel();
    };
  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="deleteUser-modal-title"
        className="flex items-center justify-center fixed animate-fadeSlideDown outline-none"
        BackdropProps={{
          style: { pointerEvents: "none" },
        }}
        disableEscapeKeyDown
      >
        <div className="bg-white shadow-lg hover:shadow-none handleDeleteUserModalOpen text-[#212529] pb-6 px-6 rounded-lg animate-fadeSlideDown w-max transition-all ease-in-out duration-500">
          <div className="text-[60px] text-center">
            <p>
              <span>
                <i className="text-red-400 fa-solid fa-triangle-exclamation"></i>
              </span>
            </p>
          </div>

          <div className="mb-4">
            <p className="text-center">
              Are you sure you want to upload{" "}
              <Tooltip
                title={fileName}
                arrow
                placement="top-start"
                TransitionComponent={Zoom}
                TransitionProps={{
                  timeout: 300,
                  style: {
                    maxWidth: "400px",
                    transformOrigin: "center",
                    // display: "inline-block",
                    whiteSpace: "pre-wrap",
                    marginLeft: "5px"  
                  },
                }}
              >
                <span className="text-[#F4833D]"> "{truncatedFileName}" </span>
              </Tooltip>
              <span> file?</span>
            </p>
          </div>

          <div className="flex justify-center gap-4">
            <button
              className="text-[#f4833d] mt-4 px-6 py-1 font-semibold rounded-lg hover:bg-[#6c757d] hover:border-[#6c757d] border border-[#f4833d] hover:text-white hover:shadow-md hover:shadow-[#6c757d] transition-all ease-in-out duration-500"
              onClick={handleNoButtonClick}
            >
              No
            </button>

            <button
              className={`text-white rounded-lg mt-4 px-6 py-1 border border-[#9b572c] hover:opacity-90 font-semibold bg-[#F4833D] hover:bg-[#e06a21] hover:shadow-md hover:shadow-[#F4833D] transition-all ease-in-out duration-500`}
              onClick={onConfirm}
            >
              Yes
            </button>
          </div>
          {showHelpingPointer}
        </div>
      </Modal>
    </div>
  );
}
