/**
 * @author : Amol Rathod
 */

/**
 * Regular expression pattern for validating email addresses.
 * Author: [Author Name]
 * @type {RegExp}
 */
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * Regular expression pattern for validating passwords.
 * Password must contain at least one lowercase letter, one uppercase letter,
 * one digit, one special character among @$!%*#?&, and be at least 8 characters long.
 * Author: [Author Name]
 * @type {RegExp}
 */
export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
