/**
 * @author : Amol Rathod 
 * @description : This module defines the AuthContext and AuthProvider components for managing authentication state in a React application.
 */

// Import necessary modules from React.
const { createContext, useState } = require("react");

// Create an AuthContext using createContext. It initializes with an empty object.
const AuthContext = createContext({});

// Export the AuthProvider component that manages authentication state.
export const AuthProvider = ({ children }) => {
  // Initialize the auth state using useState hook.
  const [auth, setAuth] = useState({});

  return (
    // Provide the auth state and setAuth function to the context.
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the AuthContext to make it available for other components.
export default AuthContext;
