// Importing necessary modules and components
import React, { useState } from "react";
import quillionzLogoImage from "../../../assets/images/quillionz_app_logo.png";
import quillionzLoginLogoImage from "../../../assets/images/quillionzLoginLogo.png";
import loginHowItWorks1Image from "../../../assets/images/LoginPage/loginHowItWorks1.png";
import loginHowItWorks2Image from "../../../assets/images/LoginPage/loginHowItWorks2.png";
import loginCopyPasteImage from "../../../assets/images/LoginPage/loginCopyPaste.png";
import loginChooseKeywordsImage from "../../../assets/images/LoginPage/loginChooseKeywords.png";
import loginReviewContentImage from "../../../assets/images/LoginPage/loginReviewContent.png";
import loginGetQuestionsImage from "../../../assets/images/LoginPage/loginGetQuestions.png";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { userLogin } from "../../api/userLogin";
import useAuth from "../../hooks/useAuth";

import { handleEmailValidationAndSetErrors } from "../../utils/Errors/Login/emailValidationError";
import { handlePasswordValidationAndSetErrors } from "../../utils/Errors/Login/passwordValidationError";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
  emptyEmailAndPassword,
  forgetPasswordEmailSent,
} from "../../components/toastify/AllToastifyErrors";
import { Tooltip, Zoom } from "@mui/material";
import { forgetPassword } from "../../api/forgetPassword";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";

// LoginPage component
const ForgetPassword = () => {
  // Custom hook for authentication
  const { setAuth } = useAuth();

  // React Router hooks
  const navigate = useNavigate();

  // Loading overlay on Login click
  const [isLoading, setIsLoading] = useState(false);

  // State for user data (email and password)
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  // State for managing form input errors
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // Function to handle email input change
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setUserData({ ...userData, email: value });

    // Validate Email and set errors
    handleEmailValidationAndSetErrors(value, setErrors);
  };

  // Function to handle Forget Password :
  const handleForgetPassword = async () => {
    const email = userData.email;

    if (!email) {
      commonErrorToastMessage("Please enter a your email");
      return;
    }

    try {
      setIsLoading(true);

      const response = await forgetPassword(email);

      commonSuccessToastMessage(
        "Please check your email inbox for a link to complete the reset."
      );

      setIsLoading(false);

      // Set success message
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailSuccess:
          "It's done! We have sent you an email with instructions to reset your password.",
      }));

      // navigate("/");
    } catch (error) {
      console.error("Forget Password Error : ", error);
      setIsLoading(false);
      if (error?.response?.status === 404) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "This email id does not exist in our records.",
        }));
      }
    }
  };

  // Function to cancel forget password request
  const handleCancelForgetPassword = () => {
    navigate("/");
  };

  return (
    // Main login page container
    <div className="">
      {/* Header section */}
      <div className="bg-[#35404b] px-10 py-3 flex justify-between items-center md:flex sm:grid">
        <div className="flex items-center ">
          {/* Quillionz Logo */}
          <img src={quillionzLogoImage} alt="Logo" className="w-[90%]" />
        </div>
      </div>

      {/* Main content */}
      <div className="flex h-[87vh] ">
        {/* Left side content */}
        <div className=" w-1/2 flex bg-[#f17322] overflow-hidden">
          {/* Left side images */}
          <div className="w-1/2 ">
            <div className="">
              <img src={loginHowItWorks1Image} alt="Logo" className="" />
            </div>
            <div className="">
              <img src={loginHowItWorks2Image} alt="Logo" className="" />
            </div>
          </div>

          {/* Explanation text */}
          <div className="w-1/2 text-white flex flex-col align-middle place-content-center justify-center items-center text-center">
            {/* Steps with icons */}
            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginCopyPasteImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">1. Submit Content</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginChooseKeywordsImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">2. Choose Keywords</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginReviewContentImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">3. Review Content</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginGetQuestionsImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">4. Get Question and Notes Ideas</p>
            </div>
          </div>
        </div>

        {/* Right side login form */}
        <div className="w-1/2 flex flex-col justify-center items-center animate-fade-slide-down">
          {/* Quillionz Logo */}
          <img src={quillionzLoginLogoImage} alt="Logo" className="w-[40%]" />

          <div className="w-[65%] text-[#777e8d] mb-12">
            {/* Explanation */}
            <div className="text-center mt-6">
              <h1 className=" text-2xl py-3 font-medium">Reset Password</h1>
              <p className="text-[14px] font-normal">
                If you have forgotten your password, please enter your
                registered email address below and click the 'Reset Password'
                button. You will receive an email that contains a link to set
                new password.
              </p>
            </div>

            {/* Input for email */}
            <div className="flex mt-8 place-content-center align-middle">
              <p className="mb-3 flex flex-col">
                <span className="text-[14px] text-[#777e8d] my-2">
                  Registered Email ID
                </span>
                <input
                  className="w-[25vw] border border-[#ced4da] p-2  "
                  placeholder="Email"
                  value={userData.email}
                  onChange={handleEmailChange}
                />
                {errors.email && (
                  <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
                    {errors.email}
                  </span>
                )}

                {errors.emailSuccess && (
                  <span className="text-green-500 antialiased animate-fade-slide-down text-[13px] signup-success-message">
                    {errors.emailSuccess}
                  </span>
                )}
              </p>
            </div>

            {/* Buttons */}
            <div className="flex gap-4 place-content-center align-middle">
              {/* Forget Password Button */}
              <Tooltip
                title="Reset Password"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`rounded mt-2 py-2 hover:opacity-90 bg-[#f4833d] border-[#f4833d] text-white   `}
                  onClick={handleForgetPassword}
                >
                  <span className=" font-semibold mx-8">Reset Password</span>
                </button>
              </Tooltip>

              {/* Cancel Button */}
              <Tooltip
                title="Cancel"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`rounded mt-2 py-2 hover:opacity-90 text-[#f4833d] hover:bg-[#6c757d] hover:border-[#6c757d] hover:text-white border border-[#f4833d]  `}
                  onClick={handleCancelForgetPassword}
                >
                  <span className=" font-semibold mx-8">Cancel</span>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Copyright section */}
        <div className="copyright w-[30px]">
          <div className="copyright-text">
            © 2023 Harbinger AI Inc. All rights reserved.
          </div>
        </div>
      </div>

      {/* Loading overlay */}
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

export default ForgetPassword;
