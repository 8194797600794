import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { useMcqQuestions } from "../../../hooks/QuestionPage/useMcqQuestions";

function McqQuestionsRenderer({
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber,
  activeStep,
  questionNumber,
  mcqsQuestions,
  handleMcqQuestionsEdit,
  handleMcqQuestionDelete,
  handleMcqQuestionChange,
  handleOptionEdit,
  handleOptionChange,
  handleOptionBlur,
  editingOptionIndices,
  question,
  index,
}) {
  // const {
  //   mcqsQuestions,
  //   handleMcqQuestionsEdit,
  //   handleMcqQuestionDelete,
  //   handleMcqQuestionChange,
  //   handleOptionEdit,
  //   handleOptionChange,
  //   handleOptionBlur,
  //   editingOptionIndices,
  // } = useMcqQuestions(
  //   generatedQuestionsFromSubmitContent,
  //   setTotalQuestionNumber,
  //   totalQuestionNumber
  // );
  let totalQuestion = 1;
  return (
    <>
      {/* {mcqsQuestions.map((mcqQuestion, index) => ( */}
      <div className="mb-4 border-slate-100 hover:border-[#f4833d] border-2 hover:border-dotted rounded question-answer-container p-4">
        <div className="flex place-content-between">
          {/* Questions goes here */}
          <div className="flex">
            <span>{index + 1}. </span>{" "}
            {question.editing ? (
              <div
                contentEditable="true"
                id={`question-${index}`}
                className="wh-question-editable "
                onBlur={(e) => {
                  handleMcqQuestionChange(question.index, e.target.textContent);
                  handleMcqQuestionsEdit(question.index);
                }}
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            ) : (
              <span
                onClick={() => handleMcqQuestionsEdit(question.index)}
                className="cursor-pointer"
                dangerouslySetInnerHTML={{ __html: question.question }}
              />
            )}
          </div>

          <div className="flex edit-delete-icons">
            {/* Edit Icon */}
            <Tooltip
              title="Edit"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleMcqQuestionsEdit(question.index)}
                className="text-indigo-500 mr-2"
              >
                <i className="fa-solid fa-pen-to-square"></i>{" "}
              </button>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip
              title="Delete"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleMcqQuestionDelete(question.index)}
                className="text-red-500"
              >
                <i className="fa-solid fa-trash"></i>{" "}
              </button>
            </Tooltip>
          </div>
        </div>

        <div className=" p-2 rounded">
          {/* Options goes here */}
          {question?.options?.map((option, optionIndex) => (
            <div
              key={optionIndex}
              className={`w-2/3 p-2 mb-1 rounded ${
                option === question.answer ? "bg-[#c0f0e2]" : "bg-[#fdecf6]"
              }`}
            >
              {/* You can adopt this method for editing the options, but a drawback is that the correct option answer isn't updated in real-time; it requires clicking outside for the changes to be reflected. */}
              {/* {editingOptionIndices[question.index] === optionIndex ? (
                <div
                  contentEditable="true"
                  id={`option-${question.index}-${optionIndex}`}
                  className="wh-option-editable"
                  onBlur={(e) => {
                    handleOptionChange(
                      question.index,
                      optionIndex,
                      e.target.textContent
                    );
                    handleOptionEdit(question.index, optionIndex);
                  }}
                  dangerouslySetInnerHTML={{ __html: option }}
                />
              ) : (
                <span
                  onClick={() => handleOptionEdit(question.index, optionIndex)}
                  className="cursor-pointer"
                  dangerouslySetInnerHTML={{ __html: option }}
                />
              )} */}

              {editingOptionIndices[index] === optionIndex ? (
                <input
                  type="text"
                  value={option}
                  // ref={(inputRef) => inputRef && inputRef.focus()}
                  className="w-full "
                  onChange={(e) =>
                    handleOptionChange(
                      question.index,
                      optionIndex,
                      e.target.value
                    )
                  }
                  onBlur={() => handleOptionBlur(question.index)}
                />
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => handleOptionEdit(question.index, optionIndex)}
                >
                  {option}
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Correct Answer */}
        <p>
          <strong>Answer:</strong> {question.answer}
        </p>
      </div>
      {/* ))} */}
    </>
  );
}

export default McqQuestionsRenderer;
