import { useEffect, useState } from "react";

export const useWhQuestions = (generatedQuestionsFromSubmitContent) => {
  const [whQuestions, setWhQuestions] = useState([]);
  const [totalWhQuestions, setTotalWhQuestions] = useState(0);

  const shortQuestionsNotWH =
    generatedQuestionsFromSubmitContent?.response?.short_questions?.questions ||
    generatedQuestionsFromSubmitContent?.questionset?.short_questions
      ?.questions ||
    [];

  useEffect(() => {
    setTotalWhQuestions(whQuestions.length);
  }, [whQuestions]);

  // let totalWhQuestions = whQuestions.length

  const handleShortWhQuestionChange = (index, newValue) => {
    const updatedQuestions = [...whQuestions];
    updatedQuestions[index].question = newValue;
    setWhQuestions(updatedQuestions);
  };

  const handleShortWhAnswerChange = (index, newValue) => {
    const updatedQuestions = [...whQuestions];
    updatedQuestions[index].answer = newValue;
    setWhQuestions(updatedQuestions);
  };

  // const handleShortWhQuestionsEdit = (index) => {
  //   const updatedQuestions = [...whQuestions];
  //   if (updatedQuestions[index]) {
  //     updatedQuestions[index].editing = !updatedQuestions[index].editing;
  //     setWhQuestions(updatedQuestions);
  //   }
  // };

  const handleShortWhQuestionsEdit = (index) => {
    const updatedQuestions = [...whQuestions];
    updatedQuestions[index].editing = !updatedQuestions[index].editing;

    if (updatedQuestions[index].editing) {
      // After setting editing to true, focus on the contentEditable div for the question
      setTimeout(() => {
        const contentEditableElement = document.getElementById(
          `question-${index}`
        ); // Add an ID to your contentEditable element
        if (contentEditableElement) {
          contentEditableElement.focus();
        }
      }, 0);
    }

    setWhQuestions(updatedQuestions);
  };

  const handleShortWhAnswersEdit = (index) => {
    const updatedQuestions = [...whQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setWhQuestions(updatedQuestions);
    }
  };

  const handleShortWhQuestionDelete = (index) => {
    let updatedQuestions = [...whQuestions];
    updatedQuestions.splice(index, 1);
    setWhQuestions(updatedQuestions);
    setTotalWhQuestions(updatedQuestions.length);

  };

  const combineWhQuestions = (shortQuestions, longQuestions) => {
    const combinedQuestions = [];

    if (shortQuestions) {
      combinedQuestions.push(...(shortQuestions?.questions || shortQuestions));
    }

    if (longQuestions) {
      combinedQuestions.push(...(longQuestions?.questions || longQuestions));
    }

    return combinedQuestions;
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      const shortQuestions =
        generatedQuestionsFromSubmitContent?.response?.short_questions
          ?.questions ||
        generatedQuestionsFromSubmitContent?.response?.short_questions ||
        generatedQuestionsFromSubmitContent?.questionset?.short_questions ||
        [];

      const longQuestions =
        generatedQuestionsFromSubmitContent?.response?.descriptive_questions
          ?.questions ||
        generatedQuestionsFromSubmitContent?.questionset
          ?.descriptive_questions ||
        [];

      const combinedQuestions = combineWhQuestions(
        shortQuestions,
        longQuestions
      );
      setWhQuestions(combinedQuestions);
      setTotalWhQuestions(combinedQuestions.length);
    }
  }, [generatedQuestionsFromSubmitContent]);


  localStorage.setItem("whQuestionsCount", totalWhQuestions);

  return {
    totalWhQuestions,
    setTotalWhQuestions,
    whQuestions,
    shortQuestionsNotWH,
    handleShortWhQuestionChange,
    handleShortWhAnswerChange,
    handleShortWhQuestionsEdit,
    handleShortWhAnswersEdit,
    handleShortWhQuestionDelete,
  };
};
