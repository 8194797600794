/**
 * @author : Amol Rathod
 * @description : Handles the submission of content, either from a file upload or text input, and performs various actions based on the input data.
 */

import { getKeywords } from "../../api/getKeywords";
import { uploadText } from "../../api/uploadText";
import { franc } from "franc";

import {
  commonErrorToastMessage,
  emptyDomainNameError,
  emptyTitleNameError,
  wordCountError,
} from "../../components/toastify/AllToastifyErrors";
import {
  dummyContent,
  keywordsDummyResponse,
} from "../../data/sampleTextDummyResponse/dummyResponsesSampleText";

/**
 *
 * @param {string} title - The title of the content.
 * @param {string} domain - The domain of the content.
 * @param {number} wordCount - The word count of the content.
 * @param {File} selectedFile - The selected file, if any.
 * @param {string} uploadedContent - The content uploaded from a file.
 * @param {string} content - The content from text input.
 * @param {function} setContent - A function to set the content.
 * @param {function} setIsLoading - A function to set loading state.
 * @param {function} setIsTextLoading - A function to set text loading state.
 * @param {function} setTitle - A function to set the title.
 * @param {function} setIsNetworkError - A function to set network error state.
 * @param {function} setResponseData - A function to set response data.
 * @param {RefObject} titleInputRef - A reference to the title input element.
 * @param {RefObject} .domainSelectRef - A reference to the domain select element.
 * @param {function} params.navigate - A function for navigation.
 * @param {any} params.response - The response data (if any).
 */
export const handleSubmitContent = async ({
  title,
  domain,
  wordCount,
  selectedFile,
  uploadedContent,
  content,
  setContent,
  setIsLoading,
  setIsTextLoading,
  setTitle,
  setIsNetworkError,
  setResponseData,
  titleInputRef,
  domainSelectRef,
  navigate,
  response,
}) => {
  // Check if title is empty
  if (!title) {
    commonErrorToastMessage("Please add title for your content.");
    titleInputRef?.current.focus();
    return;
  }

  // Check if domain is empty
  if (!domain) {
    commonErrorToastMessage(
      "Please choose the domain to which your content belongs."
    );
    domainSelectRef?.current.focus();
    return;
  }

  // Check if word count is less than 300
  if (wordCount < 300 || wordCount > 3000) {
    commonErrorToastMessage(
      "Word count must be between 300-3000 for question generation."
    );
    return;
  }

  // Check if text is in English or not
  
  const text = content;

  if (text) {
    const languageCode = franc(text, { minLength: 100 }); // Minimum length to consider for language detection
    if (languageCode !== "eng") {
      console.log("Please provide a file containing only English text.");

      commonErrorToastMessage(
        "Kindly input text written in the English language exclusively."
      );

      setIsLoading(false);

      return content; // Return the existing content if the file size exceeds the limit
    }
  }

  try {
    setIsLoading(true);
    window.title = title;

    if (selectedFile) {
      // Handle file upload
      setContent(uploadedContent);
      setTitle(title);
      setResponseData(response.data);
    } else if (content) {
      // Handle text input
      setIsTextLoading(true);

      // This is mechanism is only for Sample Text
      const isSampleTextClicked = localStorage.getItem("isSampleButtonClicked");

      const email = localStorage.getItem("email");

      // Upload text text API call
      let response = "";
      if (isSampleTextClicked === "true") {
        // const dummyResponse = dummyContent;
        // response = dummyResponse;

        // We need to use the "uploadText" API, which will generate a unique doc_id each time, aiding in preserving questions and facilitating their storage on the History Page.
        const uploadTextResponse = await uploadText(content, title, email);
        response = uploadTextResponse;
      } else {
        const uploadTextResponse = await uploadText(content, title, email);
        response = uploadTextResponse;
      }
      setResponseData(response.data);

      // Get keywords for the uploaded content
      let keywordsResponse = "";

      if (isSampleTextClicked === "true") {
        // keywordsDummyResponse is the dummy response which contains static keywords for Sample Text
        const keywordsResponseTemp = keywordsDummyResponse;

        keywordsResponse = keywordsResponseTemp;
      } else {
        const keywordsResponseTemp = await getKeywords(
          response.data.doc_id,
          response.data.title,
          content
        );

        keywordsResponse = keywordsResponseTemp;
      }

      // const keywordsResponseTemp = await getKeywords(
      //   response.data.doc_id,
      //   response.data.title,
      //   content
      // );

      // keywordsResponse = keywordsResponseTemp;

      setIsTextLoading(false);

      // Construct query string for navigation
      // const queryString = new URLSearchParams({
      //   responseData: JSON.stringify(response.data),
      //   responseToKeywordPage: JSON.stringify(keywordsResponse),
      //   domainName: JSON.stringify(domain),
      //   titleName: JSON.stringify(title),
      //   content: JSON.stringify(content),
      // }).toString();

      localStorage.setItem("content", content);
      localStorage.setItem("title", title);
      localStorage.setItem("doc_id", response.data.doc_id);
      localStorage.setItem("domain", domain);
      localStorage.setItem(
        "keywordsResponse",
        JSON.stringify(keywordsResponse)
      );

      // Retrieve and console log keywordsResponse from local storage
      const keywordsResponseFromLocalStorage = JSON.parse(
        localStorage.getItem("keywordsResponse")
      );

      // Navigate to the keywords page
      navigate(`/keywords`);

      setContent("");
      setResponseData(response);
    }

    setIsLoading(false);
  } catch (error) {
    // Handle network error
    setIsNetworkError(true);
    setIsLoading(false);
    setIsTextLoading(false);
    console.error("Error while making the API request:", error);
    commonErrorToastMessage("Error while generating keywords.");
  } finally {
    if (!selectedFile) {
      setIsLoading(false);
    }
  }
};
