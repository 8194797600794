import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography"; // Import Typography
import "../../../assets/styles/Common.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import useCanvasDetection from "../../hooks/useCanvasDetection";

// Define the steps for the Stepper component
const steps = [
  "Processing Content",
  "Processing Questions",
  "Generating Questions",
  "Generating Notes",
];

export default function GenerateQuestionsModal({ responseTime }) {
  // State variable to keep track of the active step in the Stepper
  const [activeStep, setActiveStep] = useState(0);

  const [showCompletionMessage, setShowCompletionMessage] = useState(false);

  // Get the navigate function from react-router-dom to use for navigation
  const navigate = useNavigate();

  // TODO: This logic can be used later for future use to display a message if the generation of questions takes an extended amount of time

  //  ! Ask Malhar to confirm whether we should keep this message.

  // Use effect to update the active step at a fixed interval using the responseTime prop
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveStep((prevStep) => {
  //       // If the previous step is the third one (index 2), do not increment further
  //       if (prevStep === 3) {
  //         return prevStep;
  //       }
  //       return prevStep + 1;
  //     });
  //   }, 4000); // Use responseTime to set the interval duration

  //   return () => clearInterval(interval);
  // }, [responseTime]);

  // Use effect to update the active step at a fixed interval using the responseTime prop
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => prevStep + 1);
    }, 40000); // Use responseTime to set the interval duration

    return () => clearInterval(interval);
  }, [responseTime]);

  // Use effect to apply fade-in animation when the component is mounted
  useEffect(() => {
    // When the component is mounted, add the fadeInAnimation class to the Stepper element
    const stepperElement = document.querySelector(".stepper");
    stepperElement.classList.add("fadeInAnimation");

    // Clean up the class when the component is unmounted
    return () => stepperElement.classList.remove("fadeInAnimation");
  }, []);

  // Use effect to check if all steps are completed and navigate to the desired page (in this case, "/")
  useEffect(() => {
    if (activeStep === steps.length) {
      // Use the navigate function to navigate to the desired page (in this case, "/")
      setShowCompletionMessage(true); // Show the completion message
    }
  }, [activeStep, navigate, steps.length]);

  const isCanvas = useCanvasDetection();

  return (
    <div className="">
      {/* Overlay with a semi-transparent background */}
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          flexDirection: "column", // Add this to arrange the content in a column
          justifyContent: "center",
          alignItems: "center",
          // Set the height to 100% of the viewport height
        }}
      >
        {/* Typography component for "Generating..." text */}
        <div
          className={`bg-white generate-questions-modal   rounded-lg  animate-fade-slide-down shadow-2xl p-4 hover:shadow-myShadow2 `}
        >
          <div className="">
            <p
              className={`text-2xl ${
                activeStep === 3 ? "" : ""
              } pb-2  flex items-center align-middle`}
            >
              <CircularProgress
                color="success"
                size={19}
                className="flex items-center "
              />
              <p className="ms-3">Generating Questions </p>
            </p>

            <div className="completion-message text-sm italic flex align-middle justify-center place-content-center mt-2 pb-8 text-center text-gray-500 w-full">
              <span>
                <i className="fa-solid fa-circle-exclamation"></i>
              </span>
              <span className="ms-2">
                Generating your questions may take some time. Kindly hold on.
              </span>
            </div>

            {/* TODO: This logic can be used later for future use to display a message if the generation of questions takes an extended amount of time. */}

            {/* TODO : Ask Malhar to confirm whether we should keep this message. */}
            {/* {activeStep === 3 && (
              <div className="completion-message flex pb-7 ps-9 mt-2 text-gray-400">
                <span>
                  <i className="fa-solid fa-circle-exclamation"></i>
                </span>
                <span className="ms-2">
                  Generating questions is taking a bit longer, please wait.
                </span>
              </div>
            )} */}
          </div>

          {/* Stepper component with the defined steps */}
          {/* Stepper component with the defined steps */}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="stepper "
          >
            {/* Map through the steps and display each step in the Stepper */}
            {steps.map((label, index) => (
              <Step key={label}>
                {/* Display the step label */}
                <StepLabel className="px-3 ">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Conditionally render the message when on the third step */}
          {/* {activeStep === 2 && (
            <div className="completion-message flex pb-7 px-8 mt-2 text-gray-400">
              <span>
                <i className="fa-solid fa-circle-exclamation"></i>
              </span>
              <span className="ms-2">
                Generating questions is taking a bit longer, please wait.
              </span>
            </div>
          )} */}
        </div>
      </Box>
    </div>
  );
}
