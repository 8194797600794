/**
 * @author : Amol Rathod
 */

// Import necessary dependencies
import { useState } from "react";
import { uploadFile } from "../../api/uploadFile";
import { franc } from "franc";

import {
  commonErrorToastMessage,
  fileLengthError,
  invalidFileError,
} from "../../components/toastify/AllToastifyErrors";

/**
 * Handle the change of a file input, including validation and file upload.
 *
 * @param {File} file - The selected file to be uploaded.
 * @param {string} title - The title for the uploaded file.
 * @param {string} content - The current content.
 * @param {function} updateWordCount - Function to update word count.
 * @param {function} setResponseData - Function to set response data.
 * @param {function} setSelectedFile - Function to set the selected file.
 * @param {function} setIsFileUploaded - Function to set if a file is uploaded.
 * @param {function} setLoadingContent - Function to set loading content state.
 * @param {function} setIsLoading - Function to set loading state.
 * @param {function} setTitle - Function to set the title.
 * @param {function} setContent - Function to set the content.
 * @param {function} setDomain - Function to set the domain.
 * @param {function} setDoc_id - Function to set the document ID.
 * @param {string} domain - The domain of the document.
 *
 * @returns {Promise<string>} - Returns the content from the uploaded file or existing content if errors occur.
 */


export const handleFileChange = async (
  file,
  title,
  content,
  wordCount,
  updateWordCount,
  setResponseData,
  setSelectedFile,
  setIsFileUploaded,
  setLoadingContent,
  setIsLoading,
  setTitle,
  setContent,
  setDomain,
  setDoc_id,
  domain
) => {
  setLoadingContent(true);

  

  // Define the allowed file extensions
  // const allowedExtensions = [".pdf", ".docx", ".pptx"];
  const allowedExtensions = [".pdf", ".docx"];

  // const [isFileUpload, setIsFileUpload] = useState(false);

  // Extract the file extension from the file name and convert to lowercase
  const fileExtension = file.name
    .substring(file.name.lastIndexOf("."))
    .toLowerCase();

  // Check if the file extension is not allowed
  if (!allowedExtensions.includes(fileExtension)) {
    commonErrorToastMessage("Please upload only .pdf or .docx file.");
    setDoc_id("");
    setContent("");
    setDomain("");
    setTitle("");
    setLoadingContent(false); // Make sure to set loading content to false
    return content; // Return the existing content if the file is not valid
  }

  // Define the maximum file size (10MB in bytes)
  const maxSize = 10 * 1024 * 1024;

  // Check if the file size exceeds the maximum allowed size
  if (file.size > maxSize) {
    commonErrorToastMessage("File size should be less than 10MB.");
    setLoadingContent(false); // Make sure to set loading content to false
    return content; // Return the existing content if the file size exceeds the limit
  }

  // Set flags to indicate that a file is uploaded
  setIsFileUploaded(true);
  setSelectedFile(file);

  // Update word count based on the current content
  updateWordCount(content);

  console.log("Content : ", content);

  try {
    setIsLoading(true);

    // Check the file extension to determine the upload process
    if (
      fileExtension === ".pdf" ||
      fileExtension === ".docx" ||
      fileExtension === ".pptx"
    ) {
      const formData = new FormData();
      formData.append("file", file);

      const email = localStorage.getItem("email");

      // alert("Are you sure ?");

      // Upload the file and retrieve response data
      const response = await uploadFile(formData, title, email);

      // console.log("Upload a File Response : ", response);

      // TODO: If we encounter an issue uploading your file, either due to our inability to process it or an unexpected error, we'll display a helpful error message. (Let's discuss with Vikas later to explore solutions.)
      if (!response?.data?.text) {
        commonErrorToastMessage(
          "Unable to process file or file doesn't have text."
        );
        setTitle("");
        setDomain("");
        setContent("");

        // window.location.reload();
        return;
      }

      const text = response.data?.text;

      if (text) {
        const languageCode = franc(text, { minLength: 100 }); // Minimum length to consider for language detection
        if (languageCode !== "eng") {
          console.log("Please provide a file containing only English text.");

          commonErrorToastMessage(
            "Please provide a file containing only English text."
          );

          setLoadingContent(false); // Make sure to set loading content to false
          setSelectedFile(!file);

          return content; // Return the existing content if the file size exceeds the limit
        }
      }

      // Set the response data and other states
      setResponseData(response.data);
      setDoc_id(response.data.doc_id);
      setDomain(domain);
      setLoadingContent(false);
      setIsLoading(false);
      setTitle(title);

      // localStorage.setItem("fileUpload", true);

      // TODO:We have kept only the first 3000 words of the original content.
      const words = text.split(/\s+/);
      if (words.length > 3000) {
        response.data.text = words.slice(0, 3000).join(" ");
      }

      return response.data.text; // Return the content from the uploaded file
    }
  } catch (error) {
    commonErrorToastMessage("Error while uploading the file.");
    console.error("Error while fetching content from PDF:", error.message);
    setTitle("");
    setDomain("");
    setSelectedFile(null);
    // Return the existing content if an error occurs
    // window.location.reload();
  } finally {
    setIsLoading(false);
  }

  setLoadingContent(false);
};
