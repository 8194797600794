/**
 * @author : Amol Rathod
 * @description :  Custom hook to calculate the word count of a given content.
 */

import { useState, useEffect } from "react";

/**
 * @param {string} content - The content for which word count is to be calculated.
 * @returns {Object} - An object containing the word count and a function to update it.
 */
export const useWordCount = (content) => {
  // State to hold the word count
  const [wordCount, setWordCount] = useState(0);

  /**
   * Function to update the word count based on the provided content.
   *
   * @param {string} content - The content for which word count is to be calculated.
   */
  const updateWordCount = (content) => {
    // Trim leading and trailing whitespace
    const trimmedContent = content ? content.trim() : "";

    // Split the content into words using regular expression, filter out empty strings, and count the words
    const count = trimmedContent.split(/\s+/).filter(Boolean).length;

    // Update the word count state
    setWordCount(count);
  };

  // Use useEffect to update the word count whenever the content prop changes
  useEffect(() => {
    updateWordCount(content);
  }, [content]);

  localStorage.setItem("wordCount", wordCount);

  // Return the word count and the updateWordCount function
  return { wordCount, updateWordCount };
};
