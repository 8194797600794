import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GenerateQuestionsModal from "./GenerateQuestionsModal";
import LoadingOverlay from "../overlays/LoadingOverlay";

export default function ReviewContentHelpModal({ isOpen, onClose }) {
  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  return (
    <div className=" ">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="bg-[00000080]"
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="bg-white text-[#777e8d] shadow-2xl rounded-lg  animate-fade-slide-down w-1/2 ">
              <Tooltip
                title="Close"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  // className=" text-lg px-5 pt-3 float-right"
                  className="text-[#6c757d] text-lg mt-2 mx-2 hover:bg-[#edeeef] px-2 rounded-full float-right"
                  onClick={onClose}
                >
                  <i className="fa-solid fa-xmark"></i>{" "}
                </button>
              </Tooltip>

              <div className="p-8">
                <p className="py-3 ">
                  {/* Explanation of the content */}
                  The Quillionz AI identifies important sentences from your
                  content to create quality questions and notes. You can help
                  Quillionz create better, more meaningful output by making sure
                  that your content doesn't have these flags.
                </p>
                <p className="py-3 ">
                  {/* Explanation of lengthy sentences */}
                  <strong>Lengthy sentences:</strong> When a sentence is too
                  long, Quillionz may not be able to create a great question or
                  notes from it. For best output, try to shorten lengthy
                  sentences.
                </p>
                <p className="py-3 ">
                  {/* Explanation of pronoun replacements */}
                  <strong>Pronoun replacements:</strong> Pronouns don't lead to
                  the best questions or notes text! Replace the pronoun with the
                  appropriate noun to make sure that the output you get is
                  perfect. Just click on one of the suggested words to replace
                  the original pronoun or type your own in its place.
                </p>
                <p className="py-3 ">
                  {/* Explanation of subjective sentences */}
                  <strong>Subjective sentences:</strong> Quillionz works best
                  with factual content. If a sentence is too expressive or
                  subjective, Quillionz may not be able to create a question or
                  notes from the same. If you can rephrase it to sound more
                  factual, great. Otherwise, don't worry, Quillionz will just
                  skip it.
                </p>
                <p className="py-3 ">
                  {/* Explanation of incomplete sentences */}
                  <strong>Incomplete sentences:</strong> Quillionz will not be
                  able to create good, meaningful questions or notes from
                  incomplete sentences. If it's an important sentence, make sure
                  that it is complete, it may just be a matter of adding a
                  missing period!
                </p>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
