import { useEffect, useState } from "react";

export const useFibQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [fibQuestions, setFibQuestions] = useState([]);

  const handleFibQuestionChange = (index, newValue) => {
    const updatedQuestions = [...fibQuestions];
    updatedQuestions[index].question = newValue;
    setFibQuestions(updatedQuestions);
  };

  const handleFibAnswerChange = (index, newValue) => {
    const updatedQuestions = [...fibQuestions];
    updatedQuestions[index].answer = newValue;
    setFibQuestions(updatedQuestions);
  };

  // const handleFibQuestionsEdit = (index) => {
  //   const updatedQuestions = [...fibQuestions];
  //   if (updatedQuestions[index]) {
  //     updatedQuestions[index].editing = !updatedQuestions[index].editing;
  //     setFibQuestions(updatedQuestions);
  //   }
  // };

  const handleFibQuestionsEdit = (index) => {
    const updatedFibQuestions = [...fibQuestions];
    updatedFibQuestions[index].editing = !updatedFibQuestions[index].editing;

    if (updatedFibQuestions[index].editing) {
      // After setting editing to true, focus on the contentEditable div for the question
      setTimeout(() => {
        const contentEditableElement = document.getElementById(
          `question-${index}`
        ); // Add an ID to your contentEditable element
        if (contentEditableElement) {
          contentEditableElement.focus();
        }
      }, 0);
    }

    setFibQuestions(updatedFibQuestions);
  };

  

  const handleFibAnswersEdit = (index) => {
    const updatedQuestions = [...fibQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setFibQuestions(updatedQuestions);
    }
  };

  const handleFibQuestionDelete = (index) => {
    let updatedQuestions = [...fibQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    updatedQuestions = updatedQuestions.map((u, i) => ({ ...u, index: i }));
    setTotalQuestionNumber(totalQuestionNumber - 1);
    setFibQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      let fib = generatedQuestionsFromSubmitContent.response?.fib_questions
      ?.questions ||
      generatedQuestionsFromSubmitContent.response?.fib_questions ||
      generatedQuestionsFromSubmitContent.questionset?.fib_questions
        ?.questions ||
      generatedQuestionsFromSubmitContent.questionset?.fib_questions ||
      [];
      fib = fib.map((f, index) => ({ ...f, category: "fib", index }));
      setFibQuestions(fib);
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    fibQuestions,
    handleFibQuestionChange,
    handleFibAnswerChange,
    handleFibQuestionsEdit,
    handleFibAnswersEdit,
    handleFibQuestionDelete,
  };
};
