import axios from "../api/axios";

/**
 * Retrieves user data from the server based on the email.
 * @param {string} doc_id - The document ID.
 * @param {string} title - The title of the document.
 * @param {string} email - The email address of the user.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the API request.
 */
export const extractIndividualQuestionSet = async (doc_id, title, email) => {
  try {
    // Create a URL with query parameters for doc_id, title, and email.
    const url = `/get_user_documents?doc_id=${doc_id}&title=${title}&email=${email}`;

    // Send a POST request to the server with the combined URL.
    const response = await axios.post(url);

    // Return the response object, which contains the user data.
    return response; // Alternatively, you can extract and return specific data from the response.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
