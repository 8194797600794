/**
 * @author : Amol Rathod
 * @description : This module provides email validation and error handling functions in a React application.
 */

import { emailPattern } from "../../Patterns/validationPatterns";

// Common error messages
export const emailErrorMessages = {
  requiredField: "Please enter an email", // Error message for a missing email
  invalidEmail: "Please enter a valid email ID.", // Error message for an invalid email format
};

/**
 * Function to update errors object.
 *
 * @param {object} errors - The current errors object.
 * @param {string} field - The field name to update in the errors object.
 * @param {string} message - The error message to set for the specified field.
 * @returns {object} - A new errors object with the updated field and message.
 */
export const setError = (errors, field, message) => ({
  ...errors,
  [field]: message,
});

/**
 * Function to handle email validation and set errors.
 *
 * @param {string} value - The email value to validate.
 * @param {function} setErrors - The state setter function to update errors in a React component.
 */
export const handleEmailValidationAndSetErrors = (value, setErrors) => {
  const errors = {};

  // Check if the email field is empty
  if (!value) {
    errors.email = emailErrorMessages.requiredField;
  } else if (!emailPattern.test(value)) {
    // Check if the email does not match the email pattern
    errors.email = emailErrorMessages.invalidEmail;
  } else {
    // Clear the error message for a valid email
    errors.email = "";
  }

  // Update errors using the state setter function
  setErrors((prevErrors) => ({
    ...prevErrors,
    ...errors,
  }));
};
