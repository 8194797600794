/**
 * @author : Amol Rathod
 * @description : Layout component serves as a common layout structure for different pages. It includes a route outlet to render child routes and displays the main footer.Additionally, it uses the useCanvasDetection hook to determine if a canvas is present.
 *
 */
import React from "react";
import { Outlet } from "react-router-dom";
import MainFooter from "../components/footer/MainFooter";
import useCanvasDetection from "../hooks/useCanvasDetection";

/**
 
 * @returns {JSX.Element} The JSX for the Layout component.
 */
const Layout = () => {
  // Determine if a canvas is present on the page using the useCanvasDetection hook.
  const isCanvas = useCanvasDetection();

  return (
    <div>
      {/* Render the child routes within the Outlet component. */}
      <Outlet />

      {/* Display the MainFooter component at the bottom of the layout. */}
      {/* <MainFooter /> */}
    </div>
  );
};

export default Layout;
