/**
 * @author Amol Rathod
 * 
 * @description : This function makes an asynchronous call to the server using Axios to retrieve keywords for a document.It sends the document ID, title, and text to the server and handles any errors that may occur.
 */


import axios from "../api/axios";

/**
 * Retrieves keywords for a document from the server.
 * @param {string} docId - The ID of the document.
 * @param {string} title - The title of the document.
 * @param {string} text - The text content of the document.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server, which contains the keywords.
 * @throws {Error} - If an error occurs during the API request.
 */
export const getKeywords = async (docId, title, text) => {
  try {
    // Send a POST request to the server to get keywords.
    const response = await axios.post("/get_keywords", {
      doc_id: docId,
      title: title,
      text: text,
    });

    // Return the response object, which contains the extracted keywords.
    return response; // Alternatively, you can extract and return specific data from the response.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
