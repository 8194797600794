import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { occupations } from "../../data/Signup/occupationArray";

const SignUpForm = ({ userData, setUserData, errors, setErrors }) => {
  return (
    <div>
      <p className="mb-3">
        <input
          className="w-full border border-[#ced4da] p-2 rounded"
          placeholder="First Name (Required)"
          value={userData?.firstName}
          onChange={(e) => {
            setUserData({ ...userData, firstName: e.target.value });
          }}
        />
        {errors.firstName && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors?.firstName}
          </span>
        )}
      </p>

      <p className="mb-3">
        <input
          className="w-full border border-[#ced4da] p-2 rounded"
          placeholder="Last Name (Required)"
          value={userData?.lastName}
          onChange={(e) => {
            setUserData({ ...userData, lastName: e.target.value });
          }}
        />
        {errors.lastName && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors.lastName}
          </span>
        )}
      </p>

      <p className="mb-3">
        <input
          className="w-full border border-[#ced4da] p-2 rounded"
          placeholder="Enter your email address (Required)"
          value={userData?.email}
          onChange={(e) => {
            setUserData({ ...userData, email: e.target.value });
          }}
        />
        {errors.email && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors.email}
          </span>
        )}
      </p>

      <p className="mb-3">
        <input
          type="password"
          className="w-full border border-[#ced4da] p-2 rounded"
          placeholder="Password (Required)"
          value={userData?.password}
          onChange={(e) => {
            setUserData({ ...userData, password: e.target.value });
          }}
        />
        {errors.password && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors.password}
          </span>
        )}
      </p>

      <p className="mb-3">
        <input
          type="password"
          className="w-full border border-[#ced4da] p-2 rounded"
          placeholder="Confirm Password (Required)"
          value={userData?.confirmPassword}
          onChange={(e) => {
            setUserData({
              ...userData,
              confirmPassword: e.target.value,
            });
            // Clear the error message when a valid value is entered
          }}
        />
        {errors.confirmPassword && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors?.confirmPassword}
          </span>
        )}
      </p>

      <p className="mb-3">
        <TextField
          id="outlined-select-occupation"
          select
          size="small"
          className="w-full h-1/2 "
          value={userData.occupation}
          onChange={(e) =>
            setUserData({ ...userData, occupation: e.target.value })
          }
          defaultValue="Select Occupation (Required)"
        >
          {occupations.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {errors.occupation && (
          <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
            {errors.occupation}
          </span>
        )}
      </p>

      {/* Conditionally render the additional text input based on the selected occupation. */}
      {userData.occupation === "Other" && (
        <p className="mb-3">
          <input
            className="w-full border border-[#ced4da] p-2 rounded"
            placeholder="Please specify "
            value={userData?.otherOccupation}
            onChange={(e) => {
              setUserData({
                ...userData,
                otherOccupation: e.target.value,
              });
            }}
          />

          {errors.otherOccupation && (
            <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
              {errors.otherOccupation}
            </span>
          )}
        </p>
      )}
    </div>
  );
};

export default SignUpForm;
