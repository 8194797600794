/**
 * @author : Amol Rathod
 * @description : This function handles the file upload process using Axios to a specific API endpoint.
 */

import axios from "../api/axios";

/**
 * Uploads a file using Axios to a designated API endpoint.
 * @param {FormData} formData - The form data containing the file to be uploaded.
 * @param {string} title - The title associated with the uploaded file.
 * @param {string} email - The email associated with the uploaded file.
 * @returns {Promise} - A promise that resolves with the API response data or rejects with an error.
 */
export const uploadFile = async (formData, title, email) => {
  try {
    const response = await axios.post("/upload_file", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      params: { title, email }, // Include the title and email in the request params
    });
    return response; // You can modify this based on your API response structure
  } catch (error) {
    throw error;
  }
};
