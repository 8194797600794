/**
 * @author : Amol Rathod
 * @description : This module contains functions for validating user input in a signup form.
 */

import {
  handleSignupEmailValidation,
  handleSignupNameValidation,
  handleSignupPasswordValidation,
} from "../../utils/Errors/Signup/allFieldValidation";

/**
 * Validates user input for a signup form.
 *
 * @param {object} userData - User data containing form field values.
 * @param {function} setErrors - Function to update error messages in the form.
 * @param {boolean} isChecked - Flag indicating whether a checkbox is checked.
 * @returns {object} - An object containing validation errors, if any.
 */
export function validateFormFields(userData, setErrors, isChecked) {
  const validationErrors = {};

  // Validate First Name
  const hasFirstNameValidationErrors = handleSignupNameValidation(
    userData.firstName,
    "firstName",
    setErrors
  );

  if (hasFirstNameValidationErrors) {
    return; // Return if there are name validation errors
  }

  // Validate Last Name
  const hasLastNameValidationErrors = handleSignupNameValidation(
    userData.lastName,
    "lastName",
    setErrors
  );

  if (hasLastNameValidationErrors) {
    return; // Return if there are name validation errors
  }

  // Validate Email
  const hasEmailValidationErrors = handleSignupEmailValidation(
    userData.email,
    setErrors
  );

  if (hasEmailValidationErrors) {
    return; // Return if there are email validation errors
  }

  // Validate Password
  const hasPasswordValidationErrors = handleSignupPasswordValidation(
    userData.password,
    setErrors
  );

  if (hasPasswordValidationErrors) {
    return;
  }

  // Validate Confirm Password
  if (userData.confirmPassword !== userData.password) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: "Passwords do not match",
    }));
    return;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
  }

  // Validate Occupation
  if (userData.occupation === "Select Occupation (Required)") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      occupation: "Please select an occupation",
    }));
    return;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, occupation: "" }));
  }

  // Validate Other Occupation
  if (!userData.otherOccupation) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      otherOccupation: "Please enter a valid occupation",
    }));
    return;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, otherOccupation: "" }));
  }

  // Validate Checkbox
  if (!isChecked) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      checkbox: "To proceed, you must agree to our terms and conditions.",
    }));
    return;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  }

  return validationErrors;
}
