/**
 * @author : Amol Rathod
 * 
 * @description : This function handles the user sign-up process by making a POST request to the server. It takes the user's first name, last name, email, password, confirmed password, and occupation as input. If successful, it returns the server response.
 */



import axios from "./axios";

/**
 * Handles user sign-up process.
 *
 * @param {string} FirstName - The user's first name.
 * @param {string} LastName - The user's last name.
 * @param {string} Email - The user's email address.
 * @param {string} Password - The user's chosen password.
 * @param {string} ConfirmPassword - The confirmed password for validation.
 * @param {string} Occupation - The user's occupation or job title.
 * @returns {Promise} - A promise that resolves to the server response if successful.
 * @throws {Error} - Throws an error if the sign-up process fails.
 */
export const signUp = async (
  FirstName,
  LastName,
  Email,
  Password,
  ConfirmPassword,
  Occupation
) => {
  try {
    // Send a POST request to the server's "/signup" endpoint with user data.
    const response = await axios.post("/signup", {
      FirstName,
      LastName,
      Email,
      Password,
      ConfirmPassword,
      Occupation,
    });

    // Return the server response.
    return response;
  } catch (error) {
    // If an error occurs during the sign-up process, rethrow the error.
    throw error;
  }
};
