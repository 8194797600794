import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";

import StepProgress from "../../components/stepper/StepProgress";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import GenerateQuestionsModal from "../../components/modals/GenerateQuestionsModal";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import queryData from "../../data/questionQuery/queryData";
import { getQuestionAnswer } from "../../api/getQuestionAnswer";
import ContentReadinessModal from "../../components/modals/ContentReadinessModal";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import { commonErrorToastMessage } from "../../components/toastify/AllToastifyErrors";
import { getQuestionsDummyResponse } from "../../data/sampleTextDummyResponse/dummyResponsesSampleText";

const ReviewContent = () => {
  // Set the active step value to 1
  const activeStep = 3;

  const location = useLocation();

  // Extract the data from the query parameter
  let contentReadiness = JSON.parse(
    new URLSearchParams(location.search).get("contentReadiness")
  );

  const navigate = useNavigate();

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [reviewedContent, setReviewedContent] = useState("");

  // State variable for Pronoun Suggestion click in StepProgress
  const [isPronounReplacementClicked, setIsPronounReplacementClicked] =
    useState(true);

  // State variable for Complex Sentence (Lengthy Sentences) click in StepProgress
  const [isLengthySentenceClicked, setIsLengthySentenceClicked] =
    useState(true);

  // State variable for Subjective Sentence click in StepProgress
  const [isSubjectiveSentenceClicked, setIsSubjectiveSentenceClicked] =
    useState(true);

  // State variable for Incomplete Sentence click in StepProgress
  const [isIncompleteSentenceClicked, setIsIncompleteSentenceClicked] =
    useState(true);

  const reviewRef = useRef({ isDismissClicked: false, contentReadiness: 0, dismissedPronoun: false });

  const [title, setTitle] = useState("");

  // Extract the response data came from the query parameter of Keywords Page
  const responseDataFromKeywords = JSON.parse(
    localStorage.getItem("reviewContentResponse")
  );

  // Domain name of the uploaded content
  const mainDomainName = localStorage.getItem("domain");

  // Actual content data that has been uploaded
  const contentData = localStorage.getItem("content");

  // Doc_id of the uploaded content
  const doc_id = localStorage.getItem("doc_id");

  // Title of the uploaded content
  const titleName = localStorage.getItem("title");

  // Selected keywords came from "Keywords Page"
  const selectedKeywords = JSON.parse(
    localStorage.getItem("selectedKeywordsArray")
  );

  // const selectedKeywordsForSampleText = [
  //   "PhD",
  //   "molecular clocks",
  //   "histone acetylation",
  //   "interactions",
  //   "systems",
  //   "molecular",
  //   "threads",
  //   "chromatin",
  //   "biology",
  //   "tapestry",
  //   "intricate",
  //   "evolution",
  //   "trace",
  //   "timelines",
  //   "modifications",
  //   "models",
  //   "data",
  //   "symphony",
  //   "transcriptomics",
  //   "transcends",
  //   "time",
  //   "surface",
  //   "speciation",
  //   "sculpt",
  //   "regions",
  //   "reconstruction",
  //   "reality",
  //   "proteomics",
  //   "passage",
  //   "one",
  //   "novelty",
  //   "migration",
  //   "islands",
  //   "inquiry",
  //   "innovation",
  // ];

  // For Pronoun suggestion dropdown
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [suggestions, setSuggestions] = useState({
    pronoun: "",
    suggestions: [],
  });

  const isSampleButtonClicked = localStorage.getItem("isSampleButtonClicked");

  const fileUpload = localStorage.getItem("fileUpload");

  // Total sentences in Review Page
  let totalSentences =
    responseDataFromKeywords?.data?.reviewContent?.countOfTotalSentences ||
    responseDataFromKeywords?.reviewContent?.countOfTotalSentences;

  // State variable to store total length of Complex Sentences
  let [complexSentenceLength, setComplexSentenceLength] = useState(
    responseDataFromKeywords?.reviewContent?.complexSentence?.length ||
      responseDataFromKeywords?.data?.reviewContent?.complexSentence?.length ||
      0
  );

  // State variable to store total length of Incomplete Sentences
  let [incompleteSentenceLength, setIncompleteSentenceLength] = useState(
    responseDataFromKeywords?.reviewContent?.incompleteSentence?.length ||
      responseDataFromKeywords?.data?.reviewContent?.incompleteSentence
        ?.length ||
      0
  );

  // Pronoun Suggestions
  const pronounSuggestions =
    responseDataFromKeywords?.reviewContent?.pronounSuggestion ||
    responseDataFromKeywords?.data?.reviewContent?.pronounSuggestion ||
    [];

  // I avoided using the logic of trimming unique pronoun suggestions because it was interfering with the readiness meter. I have removed that logic for now.

  // State variable to store total length of Pronoun Suggestions
  let [pronounSuggestion, setPronounSuggestion] = useState(
    pronounSuggestions.length || 0
  );

  // State variable to store total length of Subjective Sentences
  let [subjectiveSentenceLength, setSubjectiveSentenceLength] = useState(
    responseDataFromKeywords?.reviewContent?.subjectiveSentence?.length ||
      responseDataFromKeywords?.data?.reviewContent?.subjectiveSentence
        ?.length ||
      0
  );

  let [subjectiveSentences, setSubjectiveSentences] = useState(
    responseDataFromKeywords?.reviewContent?.subjectiveSentence ||
      responseDataFromKeywords?.data?.reviewContent?.subjectiveSentence
  );

  // Ref to track if the subtraction has already occurred
  const hasSubtractedRef = useRef(false);

  // fix total length of all the sentences.
  let fixTotalLength =
    complexSentenceLength +
    pronounSuggestion +
    subjectiveSentenceLength +
    incompleteSentenceLength;

  let totalSent = totalSentences;
  // Content readiness meter
 if (!reviewRef.current.isDismissClicked) {
    contentReadiness = (fixTotalLength * 100) / totalSentences;
    // Actual percentage of content readiness meter
    contentReadiness = 100 - parseInt(contentReadiness);
  } else {
    contentReadiness = reviewRef.current.contentReadiness;
  }
  // Determine the background color class based on contentReadiness
  let backgroundClass;
  
    useEffect(() => {
    }, [pronounSuggestion, complexSentenceLength, subjectiveSentenceLength, incompleteSentenceLength]);

  // Applying background colors to content readiness meter based on percentage
  if (contentReadiness < 50) {
    backgroundClass = "bg-[#ee6867]";
  } else if (contentReadiness >= 50 && contentReadiness < 90) {
    backgroundClass = "bg-[#f2ba5b]";
  } else if (contentReadiness >= 90 && contentReadiness <= 100) {
    backgroundClass = "bg-[#5de79e]";
  }

  let contentReadinessGreaterThan90 =
    contentReadiness >= 90 && contentReadiness <= 100;

  // Use state to manage the content of the textarea
  const contentText = contentData;
  const [content, setContent] = useState(contentText);

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  // State variable to control the open/close state of the ContentReadinessModal
  const [isContentReadinessModalOpen, setIsContentReadinessModalOpen] =
    useState(false);

  // Taking user's GPT key from local storage
  const usergptKey = localStorage.getItem("userGPTKey");

  // Taking user's email from local storage
  const email = localStorage.getItem("email");

  // Function to handle generating questions
  const handleGenerateQuestions = async () => {
    // Getting remaining user attempts from local storage
    const remainingQuestionSets = localStorage.getItem("UserAttempts");

    // When user exhausted all userAttempts for question generation for a month
    if (remainingQuestionSets == 0 && usergptKey === "LIMITED") {
      commonErrorToastMessage(
        "You have exhausted your monthly 25 attempts. You can upgrade to Unlimited Plan."
      );
      return;
    }

    if (contentReadiness >= 90) {
      try {
        // setIsLoading(true)
        setIsModalOpen(true);
        const isSampleTextClicked = localStorage.getItem(
          "isSampleButtonClicked"
        );

        // getQuestionsDummyResponse is the dummy response which contains static questions for Sample Text
        const questionsForSampleText = {
          response: {
            short_questions: {
              questions: [
                {
                  question:
                    "How do histone acetylation and DNA methylation contribute to the epigenome's sculpting of gene expression?",
                  answer:
                    "Histone acetylation and DNA methylation are key components of the epigenome that modulates gene expression. Histone acetylation modulates chromatin structure making genes more accessible for expression. DNA methylation, on the other hand, adds methyl groups to cytosine residues, creating a dynamic code that influences gene expression by regulating transcriptional access.",
                },
                {
                  question:
                    "In the context of evolution, how do molecular clocks contribute to our understanding of genetic divergence and timelines?",
                  answer:
                    "Molecular clocks measure the accumulation of genetic changes over time, enabling scientists to decipher evolutionary timelines. By comparing gene sequences across different species, researchers can use molecular clocks to date divergence events and infer evolutionary relationships.",
                },
                {
                  question:
                    "How does systems biology transcend traditional reductionist approaches in biology?",
                  answer:
                    "Systems biology integrates multiple levels of molecular and cellular information to create holistic models that reveal emergent properties and dynamic behaviors of cellular networks, thereby transcending the reductionist approach which focuses on individual components of a cell.",
                },
                {
                  question:
                    "Describe how the fields of comparative genomics and molecular evolution contribute to our understanding of life's evolution.",
                  answer:
                    "Comparative genomics and molecular evolution are key tools to understand life's evolution. Comparative genomics reveals the footprints of evolution within the genome by identifying orthologous genes conserved across species, indicating a common ancestry. Regions of conservation and novelty in the genome reflect the ongoing interplay between adaptation and innovation. On the other hand, molecular evolution uses molecular clocks and comparative genomics to elucidate the timelines of genetic divergence. It measures the gradual accumulation of genetic changes, thereby helping date divergence events and infer evolutionary relationships.",
                },
              ],
            },
            mcqs_questions: {
              questions: [
                {
                  question:
                    "What is the role of DNA methylation in gene expression?",
                  options: [
                    "Prevents transcription by making DNA more compact",
                    "Promotes transcription by loosening chromatin structure",
                    "Regulates transcriptional access by recruiting chromatin-modifying enzymes and transcription factors",
                    "No role in gene expression",
                  ],
                  answer:
                    "Regulates transcriptional access by recruiting chromatin-modifying enzymes and transcription factors",
                },
                {
                  question:
                    "How does histone acetylation affect chromatin structure and accessibility?",
                  options: [
                    "Tightens the chromatin structure, preventing transcription.",
                    "Loosens the chromatin structure, promoting transcription.",
                    "Does not affect chromatin structure and accessibility.",
                    "Adds acetyl groups to cytosine residues.",
                  ],
                  answer:
                    "Loosens the chromatin structure, promoting transcription.",
                },
                {
                  question:
                    "What type of genes are conserved across species that bear witness to common ancestry?",
                  options: [
                    "Paralogous genes",
                    "Orthologous genes",
                    "Crossologous genes",
                    "All genes are conserved across species",
                  ],
                  answer: "Orthologous genes",
                },
              ],
            },
            true_false_questions: {
              questions: [
                {
                  question:
                    "Comparative genomics do not reveal any footprints of evolution.",
                  answer: "False",
                },
              ],
            },
            fib_questions: {
              questions: [
                {
                  question:
                    "A dynamic code that influences gene expression is established by the addition of methyl groups to __________ residues.",
                  answer: "cytosine",
                },
                {
                  question:
                    "Systems biology observes the intricate choreography of signaling cascades, metabolic pathways, and gene regulatory networks through __________ modeling.",
                  answer: "computational",
                },
              ],
            },
            descriptive_questions: {
              questions: [],
            },
            notes:
              "Epigenetic changes such as histone acetylation and DNA methylation regulate gene expression by influencing chromatin structure and transcriptional access respectively. Molecular clocks and comparative genomics provide insights into genetic divergence and evolutionary timelines, revealing our relationship with other species. Systems biology, through computational modeling and experimental validation, enables a holistic understanding of cellular interactions and behaviors. Collectively, these approaches are critical in unraveling the intricacies of biology at the molecular, cellular, and evolutionary scales.",
            usage: {
              type: "TextData",
              OEM: "0",
              prompt_tokens: 1196,
              completion_tokens: 831,
              total_tokens: 2027,
            },
            doc_id: "20231128041248549491_4f4661e2f6ae445e90d2f71288e2a1ad",
            email: "agastha@gmail.com",
            no_ques: 10,
          },
          updatedAttempts: parseInt(localStorage.getItem("UserAttempts")),
        };

        if (isSampleTextClicked === "true") {
          // The logic for question generation when 'Sample Text' is clicked
          navigate("/question", {
            state: {
              generatedQuestions: questionsForSampleText,
            },
          });
        } else {
          // Calling the 'getQuestionAnswer' API to generate questions
          try {
            const response = await getQuestionAnswer(
              doc_id,
              content,
              selectedKeywords,
              usergptKey,
              email
              // queryData
            );

            // Storing the response of 'getQuestionAnswer' API in local storage
            localStorage.setItem(
              "reviewContentResponse",
              JSON.stringify(response.data)
            );

            // If questions are successfully generated then navigate to 'Question Page'
            if (response.data.response.no_ques !== 0) {
              // Log the API response
              navigate("/question", {
                state: {
                  generatedQuestions: response.data,
                },
              });
            } else {
              // Show the error message if questions are not generated and don't navigate to 'Question Page'

              console.error("Error while generating questions.");
              commonErrorToastMessage("Error while generating questions.");
              setIsModalOpen(false);

              return;
            }
          } catch (error) {
            console.error("Error while making the API request:", error.message);
            commonErrorToastMessage("Error while generating questions.");

            setIsModalOpen(false);
          }
        }

        setIsModalOpen(false);
        // navigate('/question')
        // setIsLoading(true);
      } catch (error) {
        console.error("Error while generating questions:", error.message);
        setIsModalOpen(false);
        commonErrorToastMessage("Error while generating questions.");
        // setIsLoading(true);
      }
    } else {
      setIsContentReadinessModalOpen(true);
    }
  };

  const generatedQuestionsFromSubmitContent = JSON.parse(
    localStorage.getItem("questionsResponse")
  );

  // Function to handle Pronoun Clicks
  const handlePronounClick = (e) => {
    const { pronoun, suggestions, pronounType } = e.target.dataset;
    if (pronounType === "pronoun-suggestion" && isPronounReplacementClicked) {
      setSuggestions({ pronoun: "", suggestions: [] });
      setAnchorEl(e.target);
      setOpenSuggestion(!openSuggestion);
      setSuggestions({
        pronoun: JSON.parse(pronoun)[0],
        suggestions: JSON.parse(suggestions),
      });
    }
  };

  // const handlePronounClick = (e) => {
  //   const { pronoun, suggestions, pronounType } = e.target.dataset;

  //   if (pronounType === "pronoun-suggestion") {
  //     setSuggestions({ pronoun: "", suggestions: [] });
  //     setAnchorEl(e.target);
  //     setOpenSuggestion(!openSuggestion);
  //     setSuggestions({
  //       pronoun: JSON.parse(pronoun)[0],
  //       suggestions: JSON.parse(suggestions)
  //     });
  //   }
  // }

  // Define a state variable to keep track of complex sentences that have already had their counts reduced
  const [reducedComplexSentences, setReducedComplexSentences] = useState([]);

  // Define a state variable to keep track of subjective sentences that have already had their counts reduced

  const [reducedSubjectiveSentences, setReducedSubjectiveSentences] = useState(
    []
  );

  // -------- Editing of Incomplete Sentence, Complex Sentence and Subjective Sentences STARTS here --------
  // Adding an effect to attach event listeners for incomplete sentence.
  useEffect(() => {
    const incompleteSentences = document.querySelectorAll(
      ".incomplete-sentence"
    );

    let toastTimer;

    const handleContentEdit = (e) => {
      const content = e.target.innerText;
      const endChars = [".", "!", "?"];

      const wordCount = content.split(/\s+/).filter(Boolean).length;

      // console.log("Word Count for Incomplete Sentence : ", wordCount);

      const contentContainer = document.getElementById("contentContainer");

      // TODO: Uncomment this after you get confirmation from team
      // Check if the given sentence has more than 30 words in it.
      // if (wordCount > 30) {
      //   if (!toastTimer) {
      //     // commonErrorToastMessage("Please limit your sentence to 30 words.");
      //     commonErrorToastMessage("Your sentence needs to be under 30 words.");
      //     toastTimer = setTimeout(() => {
      //       clearTimeout(toastTimer);
      //       toastTimer = null;
      //     }, 2000);
      //   }
      // }
      // Log the entire updated content
      if (
        wordCount <= 30 &&
        endChars.some((char) => content.trim().endsWith(char))
      ) {
        // Check if the borderBottom is not already set
        if (e.target.style.borderBottom !== "2px dotted green") {
          // Reduce the count
          setIncompleteSentenceLength((prevCount) => prevCount - 1);
          // Style changes when a punctuation mark is added
       //   e.target.style.background = "none";
          e.target.style.borderBottom = "2px dotted green";
          e.target.style.outline = "none";
          e.target.classList.add('transparent');
        }
      } else if (e.target.style.borderBottom === "2px dotted green") {
        // Reverting style changes if full stop is removed and borderBottom indicates it was previously added
       //  e.target.style.background = "#fcd4f9"; // set to original background color
        e.target.style.borderBottom = "";
        e.target.style.outline = ""; // reset the outline style
        e.target.classList.remove('transparent');
        // Increment the count
        setIncompleteSentenceLength((prevCount) => prevCount + 1);
      }

      if (wordCount === 0) {
        e.target.parentNode.removeChild(e.target);
        setIncompleteSentenceLength((prevCount) => prevCount - 1);
      }
      setReviewedContent(
        contentContainer.innerText || contentContainer.innerHTML
      );
    };

    incompleteSentences.forEach((sentence) => {
      sentence.addEventListener("input", handleContentEdit);
    });

    // Clean up function to remove event listeners
    return () => {
      incompleteSentences.forEach((sentence) => {
        sentence.removeEventListener("input", handleContentEdit);
      });
    };
  }, []); // Empty array means this effect runs once after the component is mounted

  // Adding an effect to attach event listeners for Lengthy (Complex) sentencaes.
  useEffect(() => {
    const complexSentences = document.querySelectorAll(".complex-sentence");

    const handleComplexSentenceEdit = (e) => {
      const content = e.target.innerText;
      const endChars = [".", "!", "?"];

      const contentContainer = document.getElementById("contentContainer");

      // Check if this complex sentence has already been reduced
      if (!reducedComplexSentences.includes(content)) {
        // Count words in the sentence
        const wordCount = content.split(/\s+/).filter(Boolean).length;
        // Count punctuation marks
        const punctuationCount = [...content].filter((char) =>
          endChars.includes(char)
        ).length;

        // Check if word count is less than 30 and there is only one punctuation mark
        if ((wordCount < 30 && punctuationCount === 1) || wordCount === 0) {
          // Check if the borderBottom is not already set
          if (e.target.style.borderBottom !== "2px dotted green") {
            // Reduce the count
            setComplexSentenceLength((prevCount) => prevCount - 1);

            // Style changes when a word count becomes zero
            if (wordCount === 0) {
              // Disable editing when word count becomes zero
              e.target.contentEditable = false;
              e.target.style.background = "none";
              e.target.style.borderBottom = "none";
              e.target.style.outline = "none";
              return;
            }

            // Style changes when a punctuation mark is added
            e.target.style.background = "none";
            e.target.style.borderBottom = "2px dotted green";
            e.target.style.outline = "none";
          }
        } else if (
          e.target.style.borderBottom === "2px dotted green" &&
          punctuationCount !== 1
        ) {
          // Reverting style changes if full stop is removed and borderBottom indicates it was previously added:
          e.target.style.background = "#feefb8"; // set to original background color
          e.target.style.borderBottom = "";
          e.target.style.outline = ""; // reset the outline style

          // Re-enable editing if conditions for disabling no longer apply
          e.target.contentEditable = true;
          // Increment the count
          setComplexSentenceLength((prevCount) => prevCount + 1);
        }
      }

      setReviewedContent(
        contentContainer.innerText || contentContainer.innerHTML
      );
    };

    complexSentences.forEach((sentence) => {
      sentence.addEventListener("input", handleComplexSentenceEdit);
    });

    // Clean up function to remove event listeners
    return () => {
      complexSentences.forEach((sentence) => {
        sentence.removeEventListener("input", handleComplexSentenceEdit);
      });
    };
  }, [reducedComplexSentences]); // Include reducedComplexSentences in the dependency array

  // Adding an effect to attach event listeners for Subjective sentences.
  useEffect(() => {
    const subjectiveSentences = document.querySelectorAll(
      ".subjective-sentence"
    );

    const subjectiveSentence =
      responseDataFromKeywords?.reviewContent?.subjectiveSentence ||
      responseDataFromKeywords?.data?.reviewContent?.subjectiveSentence ||
      [];

    // Extract the 'word' property from each object and place them in a new array
    const extractedWords = subjectiveSentence.map(
      (sentenceObj) => sentenceObj.word
    );

    console.log(`Extracted words : ${extractedWords}`);

    console.log("Subjective Sentences : ", subjectiveSentence);

    // This is to save the first word used as a substitute in a specific subjective sentence.
    let replaceableSubjectiveWord;

    // Function to handle focus event to show the initial word
    const handleFocus = (e) => {
      const content = e.target.innerText;

      // Find the index of the sentence being focused
      const focusedSentenceIndex = findIndexOfFocusedSentence(content);

      console.log(
        "Index of the currently focused sentence: ",
        focusedSentenceIndex
      );

      const wordAtCurrentIndex = getWordAtIndex(focusedSentenceIndex);

      console.log("Word at current index:", wordAtCurrentIndex);

      replaceableSubjectiveWord =
        e.target.querySelector(".subjective-word")?.innerText || "";
      console.log("Focused content: ", replaceableSubjectiveWord);
    };

    // This variable is used to log the toast time
    let toastTimer;

    // Function to check if content includes any of the extracted words
    const doesContentIncludeWord = (content) => {
      for (let word of extractedWords) {
        if (content.includes(word)) {
          return true;
        }
      }
      return false;
    };
    // Function to find the index of the focused subjective sentence
    const findIndexOfFocusedSentence = (focusedContent) => {
      for (let i = 0; i < subjectiveSentence.length; i++) {
        if (subjectiveSentence[i].sentence.includes(focusedContent)) {
          return i;
        }
      }
      return -1; // Return -1 if not found
    };

    // Function to get the 'word' from a sentence at a given index
    const getWordAtIndex = (index) => {
      // Check if the index is within the bounds of the array
      if (index >= 0 && index < subjectiveSentence.length) {
        return subjectiveSentence[index].word;
      } else {
        return null; // Return null if index is out of bounds
      }
    };

    // Function to handle 'Subjective Sentence Edit
    const handleSubjectiveSentenceEdit = (e) => {
      const content = e.target.innerText;
      const endChars = [".", "!", "?"];

      const contentContainer = document.getElementById("contentContainer");

      if (!reducedSubjectiveSentences.includes(content)) {
        // Flag to check if content includes replaceableSubjectiveWord
        const includesInitialWord = content.includes(replaceableSubjectiveWord);

        // Check if content includes any of the words from extractedWords
        const includesExtractedWord = doesContentIncludeWord(content);

        console.log(
          "Does content include any of the extracted words? ",
          includesExtractedWord
        );

        // console.log(
        //   "Does content include the initial word? ",
        //   includesInitialWord
        // );

        // Find the first span with the class 'subjective-word' within the edited sentence
        const subjectiveWordSpan = e.target.querySelector(".subjective-word");

        console.log("subjectiveWordSpan : ", subjectiveWordSpan);

        // Flag to check if isSubjectiveWordDeleted is present or not
        let isSubjectiveWordDeleted = false;

        const subjectiveWordText = subjectiveWordSpan?.innerText || "";

        // if (replaceableSubjectiveWord === subjectiveWordText) {
        //   console.log("SAME");
        // } else {
        //   console.log("NOT SAME");
        // }

        console.log("replaceableSubjectiveWord : ", replaceableSubjectiveWord);
        console.log("includesInitialWord : ", includesInitialWord);
        console.log("Content : ", content);

        // Check if we have 'subject-word' class in the content
        if (subjectiveWordSpan) {
          // Check if the subjective word text is empty

          if (
            subjectiveWordText.trim() === "" ||
            subjectiveWordText.length === 0
          ) {
            isSubjectiveWordDeleted = true;
          } else {
            isSubjectiveWordDeleted = false;
          }
        } else {
          isSubjectiveWordDeleted = true;
        }

        // Check if this complex sentence has already been reduced
        if (!reducedSubjectiveSentences.includes(content)) {
          // Count words in the sentence
          const wordCount = content.split(/\s+/).filter(Boolean).length;

          // Count punctuation marks
          const punctuationCount = [...content].filter((char) =>
            endChars.includes(char)
          ).length;

          // Check if the given sentence has more than 30 words in it.
          if (wordCount > 30) {
            if (!toastTimer) {
              // commonErrorToastMessage("Please limit your sentence to 30 words.");
              commonErrorToastMessage(
                "Your sentence needs to be under 30 words."
              );
              toastTimer = setTimeout(() => {
                clearTimeout(toastTimer);
                toastTimer = null;
              }, 2000);
            }
          }

          // Check if word count is less than 30 and it includes 'subjective-word' class and value inside that class is not deleted
          if (wordCount < 30 && !includesExtractedWord) {
            // if (wordCount < 30 && punctuationCount === 1) {
            // Check if the borderBottom is not already set
            if (e.target.style.borderBottom !== "2px dotted green") {
              setSubjectiveSentenceLength((prevCount) => prevCount - 1);

              // Style changes when a punctuation mark is added
              e.target.style.background = "none";
              e.target.style.borderBottom = "2px dotted green";
              e.target.style.outline = "none";
            }
          } else if (
            e.target.style.borderBottom === "2px dotted green" &&
            includesExtractedWord
          ) {
            // Reverting style changes if full stop is removed and borderBottom indicates it was previously added

            e.target.style.background = "#a4fcf9"; // set to original background color
            e.target.style.borderBottom = "";
            e.target.style.outline = ""; // reset the outline style

            // Increment the count
            setSubjectiveSentenceLength((prevCount) => prevCount + 1);
          }
        }
      }

      setReviewedContent(
        contentContainer.innerText || contentContainer.innerHTML
      );
    };

    subjectiveSentences.forEach((sentence) => {
      sentence.addEventListener("input", handleSubjectiveSentenceEdit);
      sentence.addEventListener("focus", handleFocus); // Add focus event listener
    });

    // Clean up function to remove event listeners
    return () => {
      subjectiveSentences.forEach((sentence) => {
        sentence.removeEventListener("input", handleSubjectiveSentenceEdit);
        sentence.removeEventListener("focus", handleFocus); // Remove focus event listener
      });
    };
  }, [reducedSubjectiveSentences]); // Include reducedComplexSentences in the dependency array
  // -------- Editing of Incomplete Sentence, Complex Sentence and Subjective Sentences ENDS here --------

  // Function to apply styling to all the Sentences (Pronoun, Complex, Subjective, Incomplete)
  const applyStylingToContent = () => {
    let modifiedContent = localStorage.getItem("content"); // Initialize with original content

    // Apply  color to complex Sentence
    const complexSentence =
      responseDataFromKeywords?.reviewContent?.complexSentence ||
      responseDataFromKeywords?.data?.reviewContent?.complexSentence ||
      [];

    complexSentence?.forEach((sentence) => {
      const sentenceRegex = new RegExp(
        sentence.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
        "g"
      );
      modifiedContent = modifiedContent.replace(
        sentenceRegex,
        `<span class='complex-sentence complex-sentence-parent-active'  style="${
          !isLengthySentenceClicked
            ? "padding: 0 2px;"
            : "padding: 0 4px;  background:#feefb8; border-radius: 4px;"
        }" contentEditable="${
          isLengthySentenceClicked ? "true" : "false"
        }">${sentence}</span>`
      );
    });

    // Apply  color to incomplete Sentence
    const incompleteSentence =
      responseDataFromKeywords?.reviewContent?.incompleteSentence ||
      responseDataFromKeywords?.data?.reviewContent?.incompleteSentence ||
      [];

    incompleteSentence.forEach((sentence) => {
      const sentenceRegex = new RegExp(
        sentence.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
        "g"
      );
      modifiedContent = modifiedContent.replace(
        sentenceRegex,
        `<span class="incomplete-sentence incomplete-sentence-active"  style="${
          !isIncompleteSentenceClicked
            ? "padding: 0 2px;"
            : "padding: 0 4px;  border-radius: 4px;"
        } " contentEditable="${
          isIncompleteSentenceClicked ? "true" : "false"
        }">${sentence}</span>`
      );
    });

    // Apply  color to subjective Sentence
    // responseDataFromKeywords.data[0].reviewContent.subjectiveSentence
    const subjectiveSentence =
      responseDataFromKeywords?.reviewContent?.subjectiveSentence ||
      responseDataFromKeywords?.data?.reviewContent?.subjectiveSentence ||
      [];

    // console.log("Subjective Sentences Review Page : ", subjectiveSentence);

    subjectiveSentence.forEach(({ sentence }) => {
      const sentenceRegex = new RegExp(
        sentence.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
        "g"
      );
      modifiedContent = modifiedContent.replace(
        sentenceRegex,
        // `<span class="subjective-sentence subjective-sentence-active" contentEditable="${
        //   isSubjectiveSentenceClicked ? "true" : "true"
        // }" style="padding: 0 4px; background: #a4fcf9; border-radius: 4px;">${sentence}</span>`

        `<span class="subjective-sentence subjective-sentence-active"  style="${
          !isSubjectiveSentenceClicked
            ? "padding: 0 2px;"
            : "padding: 0 4px; background: #a4fcf9;  border-radius: 4px;"
        } " contentEditable="${
          isSubjectiveSentenceClicked ? "true" : "false"
        }">${sentence}</span>`
      );
    });

    subjectiveSentence.forEach(({ sentence, word }) => {
      // Regex to match the specific word, but only once
      const wordRegex = new RegExp(`\\b${word}\\b`);

      modifiedContent = modifiedContent.replace(sentence, (matchedSentence) => {
        return matchedSentence.replace(
          wordRegex,
          `<span class='subjective-word' data-original-word="originalWord" style="color: none; border-bottom : 3px solid #56a8fc; ">${word}</span>`
        );
      });
    });

    // Apply  color to pronoun suggestions in sentences
    const pronounSuggestions =
      responseDataFromKeywords?.reviewContent?.pronounSuggestion ||
      responseDataFromKeywords?.data?.reviewContent?.pronounSuggestion ||
      [];

    /// I avoided using the logic of trimming unique pronoun suggestions because it was interfering with the readiness meter. I have removed that logic for now.

    // TODO : This is only for testing pronoun suggestion. (It has nothing to do with actual development)
    // function findAndCountWord(text, word) {
    //   // Create a regular expression to match the word with word boundaries
    //   const regex = new RegExp(`\\b${word}\\b`, "gi");
    //   const matches = text.match(regex);

    //   if (matches) {
    //     console.log("yes");
    //     console.log("Count:", matches.length);
    //   } else {
    //     console.log("no");
    //     console.log("Count: 0");
    //   }
    // }

    // // Example usage
    // findAndCountWord("betheycause they differ from person to person.", "they");

    pronounSuggestions.forEach(({ sentence, pronoun, suggestions }) => {
      let firstOccurrenceReplaced = false;

      modifiedContent = modifiedContent.replace(
        sentence,
        sentence.replace(new RegExp(`\\b${pronoun}\\b`, "gi"), (match) => {
          if (!firstOccurrenceReplaced) {
            firstOccurrenceReplaced = true;
            return `<span class="complex-sentence-active" style="${
              !isPronounReplacementClicked
                ? "cursor: pointer; padding: 0 2px;"
                : "cursor: pointer; padding: 0 4px;  border-radius: 4px;"
            }" data-pronoun-type="pronoun-suggestion" 
            data-pronoun='${JSON.stringify(pronoun)}'
            data-suggestions='${JSON.stringify(suggestions)}' 
            contenteditable="${
              isPronounReplacementClicked ? "true" : "false"
            }">${match}</span>`;
          } else {
            return match;
          }
        })
      );
    });

    // pronounSuggestions.forEach(({ sentence, pronoun, suggestions }, index) => {
    //   if (complexSentence.includes(sentence)) {
    //     const modifiedSentence = sentence.replace(
    //       new RegExp(`(${pronoun})`, "gi"),
    //       `<span class="complex-sentence-active" style="${
    //         !isPronounReplacementClicked
    //           ? "cursor: pointer; padding: 0 2px;"
    //           : "cursor: pointer; padding: 0 4px;   border-radius: 4px;"
    //       }" data-pronoun-type="pronoun-suggestion"
    //         data-pronoun='${JSON.stringify(pronoun)}'
    //         data-suggestions='${JSON.stringify(suggestions)}'
    //         >${pronoun}</span>`
    //     );
    //     modifiedContent = modifiedContent.replace(sentence, modifiedSentence);
    //   }
    // });

    // localStorage.setItem("content", modifiedContent);
    return modifiedContent;
  };

  // Pronoun suggestion dropdown working
  const applySuggestions = (value) => () => {
    if (anchorEl) {
      // Check if the pronoun suggestion has not been applied before to this span
      const isFirstTimeApplying = !anchorEl.dataset.appliedSuggestion;
      const isDismissed = anchorEl.dataset.dismissed;
      const contentContainer = document.getElementById("contentContainer");
      // Create a new span element to replace the current one
      const newSpan = document.createElement("span");
      newSpan.innerText = value;
      newSpan.dataset.pronounType = "pronoun-suggestion"; // Add the data attributes back
      newSpan.dataset.pronoun = JSON.stringify([value]); // Assuming the replaced word is the new pronoun
      newSpan.dataset.suggestions = JSON.stringify(suggestions.suggestions); // Re-attach the suggestions
      newSpan.onclick = handlePronounClick; // Attach the click event listener

      // Add the desired styles
      newSpan.style.borderBottom = "2px dotted green";
      newSpan.style.cursor = "pointer";
      // Make the span content editable
      newSpan.contentEditable = "true";

      // Mark the new span as having an applied suggestion

      newSpan.dataset.appliedSuggestion = "true";
      newSpan.dataset.dismissed = isDismissed;
      reviewRef.current.isDismissClicked = isDismissed === "true" ? true : false;
      reviewRef.current.contentReadiness = contentReadiness;
      // Only decrement the count if it's the first time applying a suggestion to this anchor
      if (isFirstTimeApplying) {
        setPronounSuggestion((prevCount) => prevCount - 1);
      }
      // Replace the old span (with background) with the new one (with the desired styles)
      anchorEl.parentNode.replaceChild(newSpan, anchorEl);
    }
    setOpenSuggestion(false);
    reviewRef.current.dismissedPronoun = false;
  };

  // State variable to keep track of dismissed pronoun suggestions
  const [dismissedSuggestions, setDismissedSuggestions] = useState([]);
  // State variable to track if a pronoun suggestion has been selected
  const [pronounSuggestionSelected, setPronounSuggestionSelected] =
    useState(false);

  // Function to handle dismissing a pronoun suggestion
  const handleDismissButton = () => {
    if (anchorEl) {
      const isFirstTimeApplying = !anchorEl.dataset.appliedSuggestion;

      // console.log("isFirstTimeApplying : ", isFirstTimeApplying);

      if (isFirstTimeApplying && isPronounReplacementClicked) {
        // Increment the count
          reviewRef.current.isDismissClicked = !0;
          reviewRef.current.dismissedPronoun = !0;
          reviewRef.current.contentReadiness = contentReadiness;
        setPronounSuggestion((prevCount) => prevCount - 1); 
          // Mark the pronoun suggestion as dismissed
          setDismissedSuggestions((prevDismissed) => [
            ...prevDismissed,
            anchorEl.dataset.pronoun,
          ]);

          // console.log("Dismiss Clicked");

          // Remove background and give a green border for the first dismissal
          anchorEl.style.background = "none";
          anchorEl.style.borderBottom = "2px dotted green";
          anchorEl.classList.remove("complex-sentence-active");

          // Prevent further updates to setPronounSuggestion
          anchorEl.dataset.appliedSuggestion = "true";
          anchorEl.dataset.dismissed = "true";
          // !anchorEl.dataset.dismissed ? 
          // (anchorEl.setAttribute("data-dismissed", "true")) : 
          // (anchorEl.dataset.dismissed = "true");
      }
      // Close the suggestions popover
      setOpenSuggestion(false);
    }
  };

  // Function to handle selecting a pronoun suggestion
  const handlePronounSuggestionSelect = (e) => {
    // Mark that a pronoun suggestion has been selected
    setPronounSuggestionSelected(true);

    // Handle the pronoun suggestion as needed
    const { pronoun, suggestions, pronounType } = e.target.dataset;
    // ... Handle the suggestion

    // Close the suggestions popover
    setOpenSuggestion(false);
  };

  // StepProgress Logic written by Piyush
  useEffect(() => {
    const reviewContentStep = document.querySelectorAll(
      "button[data-review-content]"
    );

    function onReviewStepClick(e) {
      const { reviewContent } = e.currentTarget.dataset;
      const isPressed = e.currentTarget.getAttribute("aria-pressed");
      const rcComplex = e.currentTarget.querySelector("#RC_complex");
      isPressed === "false" && !rcComplex.classList.contains("bg-transparent")
        ? rcComplex.classList.add("bg-transparent")
        : rcComplex.classList.remove("bg-transparent");
      switch (reviewContent) {
        case "lengthy-sentence":
          const complexSentence =
            document.querySelectorAll(".complex-sentence");
          complexSentence &&
            complexSentence.forEach((c) =>
              c.classList.toggle("complex-sentence-parent-active")
            );
          break;
        case "subjective-sentence":
          const subjectiveSentence = document.querySelectorAll(
            ".subjective-sentence"
          );
          subjectiveSentence &&
            subjectiveSentence.forEach((c) =>
              c.classList.toggle("subjective-sentence-active")
            );
          break;
        case "incomplete-sentence":
          const incompleteSentence = document.querySelectorAll(
            ".incomplete-sentence"
          );
          incompleteSentence &&
            incompleteSentence.forEach((i) =>
              i.classList.toggle("incomplete-sentence-active")
            );
          break;
        default:
          const pronounType = document.querySelectorAll(
            '[data-pronoun-type="pronoun-suggestion"]'
          );
          pronounType.forEach((p) => {
            !p.hasAttribute("data-applied-suggestion") &&
              p.classList.toggle("complex-sentence-active");
          });
          break;
      }
      e.currentTarget.setAttribute(
        "aria-pressed",
        isPressed === "false" ? "true" : "false"
      );
    }

    if (reviewContentStep.length) {
      for (let reviewContent of reviewContentStep) {
        reviewContent.addEventListener("click", onReviewStepClick);
      }
    }
    return () => {
      for (let reviewContent of reviewContentStep) {
        reviewContent.removeEventListener("click", onReviewStepClick);
      }
    };
  }, []);

  const modifiedContent = applyStylingToContent();

  // For Canvas
  const isCanvas = useCanvasDetection();

  return (
    <div className="">
      <AppNavigation />
      <div className="flex h-[81vh]">
        <div className="w-full h-auto px-6 pt-3 rounded-2xl">
          <div className=" pb-2">
            {/* Text area for content input starts here */}
            <div className="px-8 pb-1 mx-auto h-[7vh]">
              <p className="text-[#5e626b] text-[18px] py-2">{titleName}</p>
            </div>
            <div
              className={` border-t-[1px] border-b-[1px] mx-auto  animate-fade-slide-down content-container leading-7 ${
                isCanvas ? "h-[68vh]" : "h-[60vh]"
              }`}
              style={{
                // maxHeight: "60vh",
                width: "98%",
              }}
            >
              {/* <textarea
                className={
                  "w-full h-[50vh] resize-none bg-transparent border-none outline-none custom-scrollbar"
                }
                value={content}
                onChange={handleTextAreaChange}
                readOnly
              ></textarea> */}

              <div
                className={`p-5 w-full  ${
                  isCanvas ? "h-[70vh]" : "h-[60vh]"
                } resize-none bg-transparent border-none overflow-auto  outline-none custom-scrollbar text-[#777e8d]`}
              >
                <p className="py-2">Title : {titleName}</p>
                <p className="py-2">Domain : {mainDomainName}</p>
                {/* <p className="py-2">{contentData}</p> */}
                <div
                  id="contentContainer"
                  className="white-space-pre-line"
                  onClick={handlePronounClick}
                  aria-describedby="popover-suggestions"
                  dangerouslySetInnerHTML={{ __html: modifiedContent }}
                ></div>
              </div>
            </div>

            <div className="flex  justify-between  mx-3 ">
              <div className="content-readiness-meter-circle  my-3 ">
                <div
                  className={`percent-circle ${backgroundClass} text-[#666363]`}
                >
                  <span>
                    <span>{contentReadiness}</span>%
                  </span>
                </div>
                <div className="mx-3">
                  <p className="font-semibold text-[15px]">
                    Content Readiness Meter
                  </p>
                  <p
                    className="text-[#777e8d] text-[13px]"
                    id="percent-circleText"
                  >
                    {contentReadinessGreaterThan90 ? (
                      <span>
                        Great! Your content is now perfect for the Quillionz AI
                        engine to process.
                      </span>
                    ) : (
                      <span>
                        Your content needs some work before it is ready for
                        question generation.
                      </span>
                    )}
                  </p>
                </div>
              </div>

              <div>
                <Tooltip
                  title="Get Question and Notes Ideas"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="rounded flex place-content-end align-middle  justify-center  hover:opacity-80 bg-[#f4833d] text-white  px-4 py-2 mt-4 mb-1 ml-auto"
                    onClick={handleGenerateQuestions}
                  >
                    Get Questions and Notes Ideas
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Render the GenerateQuestionsModal component if the modal is open */}
          {isModalOpen && (
            <GenerateQuestionsModal
              isOpen={isModalOpen} // Pass the state to control whether the modal is open or not
              onClose={handleModalClose} // Pass the function to close the modal and redirect
              // Pass the time taken to generate the response as a prop
            />
          )}

          {/* Render the ContentReadinessModal component if the modal is open */}
          {isContentReadinessModalOpen && (
            <ContentReadinessModal
              isOpen={isContentReadinessModalOpen}
              onClose={() => setIsContentReadinessModalOpen(false)}
              contentData={contentData}
              // contentDataFromSubmitContent={contentDataFromSubmitContent}
              selectedKeywords={selectedKeywords}
            />
          )}
        </div>

        {/* Stepper component(present on the rightmost side) */}
        <div className="w-auto step-progress">
          <StepProgress
            activeStep={activeStep}
            pronounSuggestion={pronounSuggestion}
            complexSentence={complexSentenceLength}
            subjectiveSentence={subjectiveSentenceLength}
            incompleteSentence={incompleteSentenceLength}
            isPronounReplacementClicked={isPronounReplacementClicked}
            setIsPronounReplacementClicked={setIsPronounReplacementClicked}
            isLengthySentenceClicked={isLengthySentenceClicked}
            setIsLengthySentenceClicked={setIsLengthySentenceClicked}
            isSubjectiveSentenceClicked={isSubjectiveSentenceClicked}
            setIsSubjectiveSentenceClicked={setIsSubjectiveSentenceClicked}
            isIncompleteSentenceClicked={isIncompleteSentenceClicked}
            setIsIncompleteSentenceClicked={setIsIncompleteSentenceClicked}
          />
        </div>

        {/* For pronoun dropdown */}
        <Popover
          id="popover-suggestions"
          open={openSuggestion}
          anchorEl={anchorEl}
          placement={"bottom"}
          transition
          onClose={() => setOpenSuggestion(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "8px",
              border: "1px solid #d5d5d8",
              transition: "box-shadow 0.3s ease-in-out", // Smooth transition for the shadow
              "&:hover": {
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)", // Shadow appears on hover
              },
            },
          }}
        >
          <Paper className="">
            <Typography
              padding="8px 16px 8px 16px"
              fontWeight={600}
              fontSize="16px"
              textAlign="center"
              className="border-b bg-[#f7f7f7]"
            >
              Replace with
            </Typography>
            <List className="max-h-40  overflow-auto custom-scrollbar text-center hover:rounded-xl">
              {suggestions.suggestions.map((value) => (
                <p
                  onClick={applySuggestions(value)}
                  className="cursor-pointer hover:text-[#f4833d] rounded-lg px-3  transition duration-300 ease-in-out  hover:bg-[#f2f3f5]"
                >
                  <ListItemText
                    primary={value}
                    className="flex align-middle place-content-center text-center   items-center "
                  />
                </p>
              ))}
            </List>
            <div className="flex align-middle place-content-center text-center   items-center border-t bg-red-50 hover:opacity-80">
              <button
                className="px-4 py-2 cursor-pointer  text-red-600 font-bold"
                onClick={handleDismissButton}
              >
                Dismiss
              </button>
            </div>
          </Paper>
        </Popover>
      </div>
      <MainFooter />
    </div>
  );
};

export default ReviewContent;
