import { useEffect, useState } from "react";

export const useTrueOrFalseQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [trueFalseQuestions, setTrueFalseQuestions] = useState([]);

  // const handleTrueOrFalseQuestionsEdit = (index) => {
  //   const updatedQuestions = [...trueFalseQuestions];
  //   if (updatedQuestions[index]) {
  //     updatedQuestions[index].editing = !updatedQuestions[index].editing;
  //     setTrueFalseQuestions(updatedQuestions);
  //   }
  // };

  const handleTrueOrFalseQuestionsEdit = (index) => {
    const updatedQuestions = [...trueFalseQuestions];
    updatedQuestions[index].editing = !updatedQuestions[index].editing;

    if (updatedQuestions[index].editing) {
      // After setting editing to true, focus on the contentEditable div for the question
      setTimeout(() => {
        const contentEditableElement = document.getElementById(
          `question-${index}`
        ); // Add an ID to your contentEditable element
        if (contentEditableElement) {
          contentEditableElement.focus();
        }
      }, 0);
    }

    setTrueFalseQuestions(updatedQuestions);
  };

  const handleTrueOrFalseQuestionDelete = (index) => {
    let updatedQuestions = [...trueFalseQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    updatedQuestions = updatedQuestions.map((u, i) => ({ ...u, index: i }));
    setTotalQuestionNumber(totalQuestionNumber - 1);
    setTrueFalseQuestions(updatedQuestions);
  };

  const handleTrueOrFalseQuestionChange = (index, newValue) => {
    console.log(trueFalseQuestions);
    const updatedQuestions = [...trueFalseQuestions];
    updatedQuestions[index].question = newValue;
    setTrueFalseQuestions(updatedQuestions);
  };

  const handleTrueOrFalseAnswersEdit = (index) => {
    const updatedQuestions = [...trueFalseQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setTrueFalseQuestions(updatedQuestions);
    }
  };

  const handleTrueOrFalseAnswerChange = (index, newValue) => {
    const updatedQuestions = [...trueFalseQuestions];
    updatedQuestions[index].answer = newValue;
    setTrueFalseQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      let trueFalse =  generatedQuestionsFromSubmitContent.response?.true_false_questions
      ?.questions ||
      generatedQuestionsFromSubmitContent.response?.true_false_questions ||
      generatedQuestionsFromSubmitContent.questionset?.true_false_questions
        ?.questions ||
      generatedQuestionsFromSubmitContent.questionset
        ?.true_false_questions ||
      [];
      trueFalse = trueFalse.map((t, index) => ({ ...t, category: "truefalse", index }))
      setTrueFalseQuestions(trueFalse);
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    trueFalseQuestions,
    handleTrueOrFalseQuestionsEdit,
    handleTrueOrFalseQuestionDelete,
    handleTrueOrFalseQuestionChange,
    handleTrueOrFalseAnswersEdit,
    handleTrueOrFalseAnswerChange,
  };
};
