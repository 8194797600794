import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "../overlays/LoadingOverlay";

const GoPremiumModal = ({ open, onClose, onSubmit }) => {
  const location = useLocation();

  const navigate = useNavigate();
  // Create a state variable to manage the input value
  const [youtubeLink, setYoutubeLink] = useState("");

  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  // Create a state variable to manage the error message
  const [errorMessage, setErrorMessage] = useState("");

  // Function to handle changes in the input value
  const handleInputChange = (event) => {
    setYoutubeLink(event.target.value);
  };

  // Function to handle the "Submit" button click
  const handleModalSubmit = async () => {};

  // Function to handle the "Close" button click
  const handleModalClose = () => {
    onClose(); // Close the modal without processing the input
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      // Apply the style to the modal container
      className="flex place-content-center items-center fixed"
      // Disable clicking on the backdrop and Escape key
      BackdropProps={{
        style: { pointerEvents: "none" },
      }}
      disableEscapeKeyDown
    >
      <div className="bg-white shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-4/5 resize-none bg-transparent border-none overflow-auto  outline-none custom-scrollbar h-[98vh]">
        <div className="flex justify-end cursor-pointer text-2xl mb-2 text-red-600  "></div>

        <div className="  float-right px-2 mt-[-25px]">
          <Tooltip
            title="Close"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
              onClick={handleModalClose}
            >
              <i className="fa-solid fa-xmark"></i>{" "}
            </button>
          </Tooltip>
        </div>

        <div className="flex border border-black">
          {/* Yearly Subscription */}
          <div className="year w-1/3 px-4 ">
            <div>
              <h1 className="py-3 text-center text-[#112a38] text-[19px] border-b">
                Yearly Subscription
              </h1>
            </div>

            <div className="py-3 px-3">
              <div className=" text-[#666666] text-[15px] text-left">
                <p className="pb-2">
                  Generate MCQ's, Recall, True/False, Short answers, Fill-in
                  type, Wh, and Interpretive questions
                </p>

                <p className="pb-2">Create Notes</p>

                <p className="pb-2">
                  Generate and save unlimited question sets per day
                </p>

                <p className="pb-2">Input content through text or pdf format</p>

                <p className="pb-2">
                  Use express mode to generate questions rapidly
                </p>

                <p className="pb-2">
                  Export questions into popular formats like .pdf, .txt, .doc,
                  and QTI
                </p>

                <p className="pb-2">Enjoy ad-free experience</p>

                <p className="pb-2">Share questions as QuilliQuiz</p>

                <p className="pb-2">View question context</p>
              </div>

              <div className="text-center">
                {/* Subscribe ($99.99/Year) Button */}
                <Tooltip
                  title="Subscribe ($99.99/Year)"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="mt-2 hover:opacity-90 
                bg-[#f4833d]
               text-white px-4 py-2 rounded"
                  >
                    Subscribe ($99.99/Year)
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Quarterly Subscription */}
          <div className="month bg-orange-50 border border-[#ef7920] w-1/3  flex-1 flex flex-col mt-[-15px] mb-[-15px] shadow-xl">
            <div>
              <span className="bg-[#F15F02] text-sm text-white px-4 py-1">
                Offer
              </span>

              <h1 className="pb-3 text-center text-[#112a38] text-[19px] border-b">
                Quarterly Subscription
              </h1>
            </div>

            <div className="py-3  h-auto px-4">
              <div className=" text-[#666666] text-[15px] text-left">
                <p className="pb-2">
                  Generate MCQ's, Recall, True/False, Short answers, Fill-in
                  type, Wh, and Interpretive questions
                </p>

                <p className="pb-2">Create Notes</p>

                <p className="pb-2">
                  Generate and save unlimited question sets per day
                </p>

                <p className="pb-2">Input content through text or pdf format</p>

                <p className="pb-2">
                  Use express mode to generate questions rapidly
                </p>

                <p className="pb-2">
                  Export questions into popular formats like .pdf, .txt, .doc,
                  and QTI
                </p>

                <p className="pb-2">Enjoy ad-free experience</p>

                <p className="pb-2">Share questions as QuilliQuiz</p>

                <p className="pb-2">View question context</p>
              </div>

              <div className="text-center pt-6">
                {/* Subscribe ($29.99/Quarter) Button*/}
                <Tooltip
                  title="Subscribe ($29.99/Quarter)"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="mt-2 hover:opacity-90 
                bg-[#f4833d]
               text-white px-4 py-2 rounded"
                  >
                    Subscribe ($29.99/Quarter)
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Monthly Subscription */}
          <div className="year w-1/3  flex-1 flex flex-col">
            <div>
              <span className="bg-[#F15F02] text-sm text-white px-4 py-1">
                Offer
              </span>

              <h1 className="pb-3 text-center text-[#112a38] text-[19px] border-b">
                Monthly Subscription
              </h1>
            </div>

            <div className="py-3 px-6">
              <div className=" text-[#666666] text-[15px] text-left">
                <p className="pb-2">
                  Generate MCQ's, Recall, True/False, Short answers, Fill-in
                  type, Wh, and Interpretive questions
                </p>

                <p className="pb-2">Create Notes</p>

                <p className="pb-2">
                  Generate and save unlimited question sets per day
                </p>

                <p className="pb-2">Input content through text or pdf format</p>

                <p className="pb-2">
                  Use express mode to generate questions rapidly
                </p>

                <p className="pb-2">
                  Export questions into popular formats like .pdf, .txt, .doc,
                  and QTI
                </p>

                <p className="pb-2">Enjoy ad-free experience</p>

                <p className="pb-2">Share questions as QuilliQuiz</p>

                <p className="pb-2">View question context</p>
              </div>

              <div className="text-center pt-2">
                {/* Subscribe ($14.99/Month) Button*/}
                <Tooltip
                  title="Subscribe ($14.99/Month)"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="mt-2 hover:opacity-90 
                bg-[#f4833d]
               text-white px-4 py-2 rounded"
                  >
                    Subscribe ($14.99/Month)
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default GoPremiumModal;
