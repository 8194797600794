import React from "react";
import "../../../../assets/styles/Common.css";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import quillonz_logo from "../../../../assets/images/quillionz_website_logo.png";
import Select from "@mui/material/Select";
import InviteAFriendModal from "../../modals/InviteAFriendModal";
import { Tooltip, Zoom } from "@mui/material";

const WebsiteNavigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown's visibility
  };

  const handleOptionClick = (option) => {
  };

  return (
    <div className="">
      {/* Navigatin bar starts here */}
      <nav className="md:h-[78px] lg:h-[76px] border shadow-lg py-4 px-8 flex justify-between items-center md:flex sm:grid ">
        <div className="flex items-center">
          {/* Quillionz Logo */}
          <Link>
            <img src={quillonz_logo} alt="Logo" className=" " />
          </Link>
        </div>

        {/* Hamburger menu button (visible only on small devices) */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            <svg
              className="w-6 h-6 "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        {/* Menu items (visible on larger devices) */}
        <div
          className={`md:flex items-center ${showMenu ? "block" : "hidden"}`}
        >
          <ul className="md:flex space-x-4 ">
            <li className="nav-item ">
              <button className="web-nav-button ">Home</button>
            </li>

            {/* Dropdown menu for "Username" starts here */}
            <li className="nav-item relative text-start" ref={dropdownRef}>
              <button className="web-nav-button" onClick={toggleDropdown}>
                Products{" "}
                <span className=" align-middle">
                  <i className="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {showDropdown && ( // Conditionally render the dropdown content
                <div className=" animate-fade-slide-down absolute bg-white border p-3 shadow-lg ">
                  <button className="dropdown-item">What is Quillionz?</button>

                  <button className="dropdown-item">
                    What is Quillionz Pro?
                  </button>

                  <button className="dropdown-item">QuilliQuiz</button>

                  <button className="dropdown-item">Quillionz API</button>

                  <button className="dropdown-item">How It Works?</button>

                  <button className="dropdown-item">Expert Reviews</button>

                  <button className="dropdown-item">FAQs</button>

                  <button className="dropdown-item">
                    Is Quillionz Right for Me?
                  </button>

                  <button className="dropdown-item">Curation Service</button>
                </div>
              )}
            </li>

            <li className="nav-item">
              <button className="web-nav-button">Developer</button>
            </li>

            <li className="nav-item">
              <button className="web-nav-button">Pricing</button>
            </li>

            <li className="nav-item">
              <button className="web-nav-button">About Us</button>
            </li>

            <li className="nav-item">
              <button className="web-nav-button">Blog</button>
            </li>

            <li className="nav-item">
              <button className="web-nav-button">Contact Us</button>
            </li>

            <li className="nav-item">
              <Tooltip
                title="Login"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <Link to="/">
                  <button className="web-nav-button">Login</button>
                </Link>
              </Tooltip>
            </li>

            <li className=" nav-item ">
              <Tooltip
                title="Sign up"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <Link to="/signup">
                  <button className="web-nav-button-signup  px-4 py-2">
                    Sign Up
                  </button>
                </Link>
              </Tooltip>
            </li>
          </ul>
        </div>
      </nav>
      {/* Navigation bar ends here */}
    </div>
  );
};

export default WebsiteNavigation;
