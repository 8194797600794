import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import LoadingOverlay from "../overlays/LoadingOverlay";
import { shareYourFeedback } from "../../api/shareYourFeedback";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
  feedbackSubmittedSuccessfully,
} from "../toastify/AllToastifyErrors";
import { rateYourQuestions } from "../../api/rateYourQuestions";

const RateYourQuestionsModal = ({ open, onClose, onSubmit }) => {
  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  // State variable to track the selected star count
  const [selectedStars, setSelectedStars] = useState(0);

  // State variable to track the rating error state
  const [showRatingError, setShowRatingError] = useState(false);

  // State variable to track the error state for the textarea
  const [showTextareaError, setShowTextareaError] = useState(false);

  // State variable to store the textarea input
  const [ratingMessage, setRatingMessage] = useState("");

  // Function to handle typing in the textarea and update the state
  const handleTextareaChange = (event) => {
    const newText = event.target.value;
    setRatingMessage(newText);
    if (newText.trim() !== "") {
      setShowTextareaError(false);
    }
  };

  // Function to handle clicking on a star
  const handleStarClick = (starCount) => {
    setSelectedStars(starCount);
    setShowRatingError(false);
  };

  // Function to handle the "Close" button click
  const handleModalClose = () => {
    setSelectedStars(0);
    setShowTextareaError(false);
    setShowRatingError(false);
    onClose(); // Close the modal without processing the input
  };

  // Function to handle the form submission
  const handleFeedbackSubmit = async () => {
    if (selectedStars === 0) {
      setShowRatingError(true);
      return;
    }
    setShowRatingError(false);

    if (ratingMessage.trim() === "") {
      setShowTextareaError(true);
      return;
    }
    setShowTextareaError(false);

    try {
      const email = localStorage.getItem("email");
      const doc_id = localStorage.getItem("doc_id");

      const response = await rateYourQuestions(email, selectedStars, doc_id);

      commonSuccessToastMessage(
        "Thank you for your rating! Your feedback has been recorded."
      );

      setRatingMessage("");
      setSelectedStars(0);

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error : ", error);
      commonErrorToastMessage("Error while giving rating");
      onClose();
      setRatingMessage("");
      setSelectedStars(0);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      // Apply the style to the modal container
      className="flex place-content-center items-center fixed"
      // Disable clicking on the backdrop and Escape key
      BackdropProps={{
        style: { pointerEvents: "none" },
      }}
      disableEscapeKeyDown
    >
      <div className="bg-white shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-[35vw]">
        <div className="flex place-content-between items-center border-b pb-5">
          <h2 className=" text-xl font-semibold ">
            How would you rate this question set?
          </h2>
          <Tooltip
            title="Close"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
              onClick={handleModalClose}
            >
              <i className="fa-solid fa-xmark"></i>{" "}
            </button>
          </Tooltip>
        </div>

        <div className="pt-4  ">
          {/* <p className="text-black font-semibold text-[15px] mb-2">
            Share your feedback.
          </p> */}

          <div className="">
            {[1, 2, 3, 4, 5].map((starCount) => (
              <span
                key={starCount}
                className={`text-2xl cursor-pointer ${
                  selectedStars >= starCount ? "text-orange-400" : ""
                }`}
                onClick={() => handleStarClick(starCount)}
              >
                <i className="fa-solid fa-star mx-2"></i>
              </span>
            ))}
            {showRatingError && (
              <p className="text-red-500 mt-2">Please give ratings!</p>
            )}

            <div className=" my-3 py-2 ">
              {selectedStars >= 4 && (
                <div>
                  <p className="text-black font-semibold text-[15px] mb-2">
                    Tell your friends about Quillionz!
                  </p>

                  <div className="flex space-x-2 text-2xl gap-4">
                    <Tooltip
                      title="Facebook"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <a
                        href="https://www.facebook.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa-brands fa-facebook hover:opacity-80 text-[#2660c5]"></i>
                        {""}
                      </a>
                    </Tooltip>

                    <Tooltip
                      title="Twitter"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <a
                        href="https://www.twitter.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa-brands fa-square-x-twitter"></i>
                        {""}
                      </a>
                    </Tooltip>

                    <Tooltip
                      title="Linkedin"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <a
                        href="https://www.linkedin.com/sharing/share-offsite/?url=www.quillionz.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i
                          className="fa-brands fa-linkedin hover:opacity-80 text-[#275bb4]"
                          aria-hidden="true"
                        ></i>
                        {""}
                      </a>
                    </Tooltip>

                    <Tooltip
                      title="Pinterest"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <a
                        href="https://www.pinterest.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa-brands fa-pinterest hover:opacity-80 text-[#f02d2d]"></i>
                        {""}
                      </a>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="  ">
          <p className="text-black font-semibold text-[15px] mb-2">
            Share your feedback.
          </p>
          <textarea
            className={`w-full resize-none bg-transparent outline-1 border custom-scrollbar overflow-none p-2 ${
              showTextareaError ? "placeholder-red-500" : ""
            }`}
            placeholder="Please type something here..."
            onChange={handleTextareaChange}
          ></textarea>
        </div>

        <div className="flex place-content-center gap-8">
          <Tooltip
            title="Submit your feedback"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="mt-4 px-4 py-3 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
              onClick={handleFeedbackSubmit}
            >
              Submit
            </button>
          </Tooltip>

          <Tooltip
            title="Share your feedback later"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="mt-4 px-4 py-3  text-[#f4833d] rounded hover:bg-[#6c757d] hover:border-[#6c757d] border border-[#f4833d] hover:text-white"
              onClick={handleModalClose}
            >
              Later
            </button>
          </Tooltip>
        </div>

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default RateYourQuestionsModal;
