/**
 * @author : Amol Rathod
 * @description : Sends a request to reset the user's password by sending a reset email.
 */

import axios from "../api/axios";

/**
 * @param {string} email - The email address of the user requesting a password reset.
 * @returns {Promise} - A Promise that resolves to the response from the server.
 * @throws {Error} - If an error occurs during the password reset process.
 */


export const forgetPassword = async (email) => {
  try {
    // Send a POST request to the "/forgot_Password" endpoint with the provided email.
    const response = await axios.post("/forgot_Password", {
      email,
    });

    // Return the response from the server.
    return response;
  } catch (error) {
    // If an error occurs during the password reset process, re-throw the error.
    throw error;
  }
};
