/**
 * @author : Amol Rathod
 * @description : Function to update error object with a specific field and error message.
 */
import { passwordPattern } from "../../Patterns/validationPatterns";

// Common error messages for password field
export const passwordErrorMessages = {
  requiredField: "Please enter a password",
  invalidPassword: "Please enter a valid password.",
};

/**
 * Function to update errors object.
 *
 * @param {Object} errors - The current errors object.
 * @param {string} field - The field name to update.
 * @param {string} message - The error message to set for the field.
 * @returns {Object} - The updated errors object.
 */
export const setError = (errors, field, message) => ({
  ...errors,
  [field]: message,
});

/**
 * Handle password validation and update errors for the password field.
 *
 * @param {string} value - The password value to validate.
 * @param {function} setErrors - The function to set the errors in the state.
 */
export const handlePasswordValidationAndSetErrors = (value, setErrors) => {
  const errors = {};

  if (!value) {
    errors.password = passwordErrorMessages.requiredField;
  } else if (!passwordPattern.test(value)) {
    errors.password = passwordErrorMessages.invalidPassword;
  } else {
    errors.password = "";
  }

  setErrors((prevErrors) => ({
    ...prevErrors,
    ...errors,
  }));
};

// Common error messages for new password field
export const newPasswordErrorMessages = {
  requiredField: "Please enter a password",
  invalidPassword:
    "The password should be at least 8 characters long without any spaces, and should be a mix of letters, numbers, and special characters.",
  
};

/**
 * Handle new password validation and update errors for the new password field.
 *
 * @param {string} value - The new password value to validate.
 * @param {function} setErrors - The function to set the errors in the state.
 */
export const handleNewPasswordValidationAndSetErrors = (value, setErrors) => {
  const errors = {};

  if (!value) {
    errors.newPassword = newPasswordErrorMessages.requiredField;
  } else if (!passwordPattern.test(value)) {
    errors.newPassword = newPasswordErrorMessages.invalidPassword;
  } else {
    errors.newPassword = "";
  }

  setErrors((prevErrors) => ({
    ...prevErrors,
    ...errors,
  }));
};
