import { useEffect } from "react";

const useTotalQuestionIdeas = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber
) => {
  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      const totalQuestionIdeas =
        (generatedQuestionsFromSubmitContent.response?.mcqs_questions?.questions
          ?.length ||
          generatedQuestionsFromSubmitContent.response?.mcqs_questions
            ?.length ||
          0) +
        (generatedQuestionsFromSubmitContent.questionset?.mcqs_questions
          ?.questions?.length ||
          generatedQuestionsFromSubmitContent.questionset?.mcqs_questions
            ?.length ||
          0) +
        (generatedQuestionsFromSubmitContent.response?.fib_questions?.questions
          ?.length ||
          generatedQuestionsFromSubmitContent.response?.fib_questions?.length ||
          0) +
        (generatedQuestionsFromSubmitContent.questionset?.fib_questions
          ?.questions?.length ||
          generatedQuestionsFromSubmitContent.questionset?.fib_questions
            ?.length ||
          0) +
        // (generatedQuestionsFromSubmitContent.response?.short_questions
        //   ?.questions?.length ||
        //   generatedQuestionsFromSubmitContent.response?.short_questions
        //     ?.length ||
        //   0) +
        // (generatedQuestionsFromSubmitContent.questionset?.short_questions
        //   ?.questions?.length ||
        //   generatedQuestionsFromSubmitContent.questionset?.short_questions
        //     ?.length ||
        //   0) +
        (generatedQuestionsFromSubmitContent.response?.true_false_questions
          ?.questions?.length ||
          generatedQuestionsFromSubmitContent.response?.true_false_questions
            ?.length ||
          0) +
        (generatedQuestionsFromSubmitContent.questionset?.true_false_questions
          ?.questions?.length ||
          generatedQuestionsFromSubmitContent.questionset?.true_false_questions
            ?.length ||
          0);
      // (generatedQuestionsFromSubmitContent.response?.descriptive_questions
      //   ?.questions?.length ||
      //   generatedQuestionsFromSubmitContent.response?.descriptive_questions
      //     ?.length ||
      //   0) +
      // (generatedQuestionsFromSubmitContent.questionset?.descriptive_questions
      //   ?.questions?.length ||
      //   generatedQuestionsFromSubmitContent.questionset?.descriptive_questions
      //     ?.length ||
      //   0);

      setTotalQuestionNumber(totalQuestionIdeas);
    }
  }, [generatedQuestionsFromSubmitContent]);
};

export default useTotalQuestionIdeas;
