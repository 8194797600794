/**
 * Author : Amol Rathod
 * 
 * Description: Custom hook for accessing authentication context.
 */


import { useContext } from "react";
import AuthContext from "../components/context/AuthProvider";

/**
 * A custom hook that provides access to the authentication context.
 * @returns {Object} The authentication context object.
 */
const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
