import { useEffect, useState } from "react";

export const useShortQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [shortQuestions, setShortQuestions] = useState([]);

  const handleShortQuestionChange = (index, newValue) => {
    const updatedQuestions = [...shortQuestions];
    updatedQuestions[index].question = newValue;
    setShortQuestions(updatedQuestions);
  };

  const handleShortAnswerChange = (index, newValue) => {
    const updatedQuestions = [...shortQuestions];
    updatedQuestions[index].answer = newValue;
    setShortQuestions(updatedQuestions);
  };

  const handleShortQuestionsEdit = (index) => {
    const updatedQuestions = [...shortQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editing = !updatedQuestions[index].editing;
      setShortQuestions(updatedQuestions);
    }
  };

  const handleShortAnswersEdit = (index) => {
    const updatedQuestions = [...shortQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setShortQuestions(updatedQuestions);
    }
  };

  const handleShortQuestionDelete = (index) => {
    let updatedQuestions = [...shortQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    setTotalQuestionNumber(totalQuestionNumber - 1);
    setShortQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      setShortQuestions(
        generatedQuestionsFromSubmitContent.response?.short_questions
          ?.questions ||
          generatedQuestionsFromSubmitContent.response?.short_questions ||
          generatedQuestionsFromSubmitContent.questionset?.short_questions
            ?.questions ||
          generatedQuestionsFromSubmitContent.questionset?.short_questions ||
          []
      );
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    shortQuestions,
    handleShortQuestionChange,
    handleShortAnswerChange,
    handleShortQuestionsEdit,
    handleShortAnswersEdit,
    handleShortQuestionDelete,
  };
};
