/**
 * @author : Amol Rathod
 * @description : This module defines a function for submitting review content to the server using Axios.
 */

import axios from "../api/axios";

/**
 * Submit review content to the server.
 * @param {string} doc_id - The ID of the document being reviewed.
 * @param {string} title - The title of the review.
 * @param {string} content - The textual content of the review.
 * @returns {Promise} - A promise that resolves to the server response.
 * @throws {Error} - If an error occurs during the submission process.
 */
export const reviewContent = async (doc_id, title, content) => {
  try {
    // Send a POST request to the server to submit the review content.
    const response = await axios.post("/reviewContent", {
      doc_id,
      title,
      text: content,
    });

    // Return the response from the server.
    return response;
  } catch (error) {
    // If an error occurs, throw the error for handling at a higher level.
    throw error;
  }
};
