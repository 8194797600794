import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "../overlays/LoadingOverlay";
import { inviteAFriend } from "../../api/inviteAFriend";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
} from "../toastify/AllToastifyErrors";
import { upgradeToUnlimited } from "../../api/upgradeToUnlimited";
import QuillionzProUpgradeModal from "./QuillionzProUpgradeModal";
import {
  monthlyForm,
  quaterlyForm,
  yearlyForm,
} from "../../utils/SubmitContent/PaypalForms";

const GoForUnlimitedQuestionsModal = ({
  open,
  onClose,
  onSubmit,
  hideFirstPartForSubmitPage,
  setShowGoForUnlimitedModal,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  // 1. Create two state variables
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [message, setMessage] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [showUnlimitedModal, setShowUnlimitedModal] = useState(false);

  const [openAIKey, setOpenAIKey] = useState("");
  const [confirmOpenAIKey, setConfirmOpenAIKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [upgradeToUnlimitedClicked, setUpgradeToUnlimitedClicked] =
    useState(false);

  const remainingQuestionSets = 25 - localStorage.getItem("UserAttempts");
  const isGptActive = localStorage.getItem("isGptActive");
  const userQuestionsAttempts = localStorage.getItem("UserAttempts");
  const remainingDaysInMonth = localStorage.getItem("remainingDaysInMonth");
  const usedQuestionSets = localStorage.getItem("UserAttempts");
  const monthEndDate = localStorage.getItem("monthEndDate");

  const getFormattedMonth = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", year: "numeric", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Assuming the value of monthEndDate is in 'MM/DD/YYYY' format
  const formattedMonthEndDate = getFormattedMonth(monthEndDate);

  const handleEmailChange = (e) => {
    setEmail("");
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Function to handle the "Submit" button click
  const handleInviteAFriendButton = async () => {};

  // Function to handle the "Close" button click
  const handleModalClose = () => {
    // setEmail("");
    setIsFront(true);
    setMessage("");
    onClose(); // Close the modal without processing the input
  };

  const [isFront, setIsFront] = useState(true); // Add this state to manage front/back view

  const handleGoUnlimited = () => {
    setUpgradeToUnlimitedClicked(true);
    setIsFront(false); // Switch to the back view when clicking "Unlimited" button
    setErrorMessage("");
    setOpenAIKey("");
    setConfirmOpenAIKey("");
  };

  const handleQuillionzProModal = () => {
    // setShowUnlimitedModal(true);
    setUpgradeToUnlimitedClicked(false);
    setIsFront(false);
  };

  useEffect(() => {
    if (openAIKey && confirmOpenAIKey) {
      setErrorMessage(
        openAIKey === confirmOpenAIKey
          ? ""
          : "Open AI Key and Confirm Open AI Key does not match."
      );
    }
  }, [openAIKey, confirmOpenAIKey]);
  const handleGoBack = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const handleGoBack2 = () => {
    setIsFront(true); // Switch to the back view when clicking "Unlimited" button
  };

  const handleOpenAIKeySubmit = async () => {
    if (openAIKey === confirmOpenAIKey) {
      try {
        setIsLoading(true);
        setIsFront(false); // Switch to the back view when clicking "Unlimited" button

        const response = await upgradeToUnlimited(
          openAIKey,
          confirmOpenAIKey,
          email
        );

        const unlimitedAttempts = 2000;

        localStorage.setItem("userOpenAIKey", openAIKey);
        // localStorage.setItem("UserAttempts", unlimitedAttempts);

        localStorage.setItem("userGPTKey", openAIKey);
        localStorage.setItem("isGptActive", "1");

        navigate("/submitcontent");

        // Navigate to '/submitcontent', then reload the page
        // navigate("/submitcontent", { replace: true }).then(() =>
        //   window.location.reload()
        // );

        commonSuccessToastMessage(
          "Success! Your OpenAI key has been successfully verified and saved. You now have unlimited questions sets per month."
        );
        setIsLoading(false);
        setErrorMessage("");
        setOpenAIKey("");
        setConfirmOpenAIKey("");
        onClose();
      } catch (error) {
        setIsLoading(false);
        commonErrorToastMessage("Please provide a valid OpenAI key.");

        console.log("Error while submitting your GPT key : ", error);
        setErrorMessage("");
        setOpenAIKey("");
        setConfirmOpenAIKey("");
      }
    } else {
      commonErrorToastMessage(
        "The OpenAI key you entered does not match the confirmed OpenAI key."
      );
    }
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlipped = () => {
    setIsFlipped(!isFlipped);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const dummyPasswordField = (
    <input
      type="password"
      autoComplete="new-password"
      style={{ display: "none" }}
      readOnly
    />
  );

  const isPlanExpired = localStorage.getItem("isPlanExpired");

  // Plan expired message logic :
  const [subscriptionPlan, setSubscriptionPlan] = useState("");

  // Effect hook to update subscription plan name based on the value
  useEffect(() => {
    const planNumber = parseInt(localStorage.getItem("subscriptionPlan"));
    let planName = "";

    switch (planNumber) {
      case 1:
        planName = "Monthly";
        break;
      case 3:
        planName = "Quarterly";
        break;
      case 12:
        planName = "Annual";
        break;
      default:
        planName = "Unknown Plan";
        break;
    }

    setSubscriptionPlan(planName);
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts.

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      // Apply the style to the modal container
      className="flex place-content-center items-center fixed"
      // Disable clicking on the backdrop and Escape key
      BackdropProps={{
        style: { pointerEvents: "none" },
      }}
      disableEscapeKeyDown
    >
      <div
        className={`rounded-lg animate-fade-slide-down modal-content shadow-xl hover:shadow-myShadow2 ${
          isFront ? "unflipped" : "flipped"
        }`} /* Updated this line */
      >
        {isFront ? (
          <div
            className={`bg-white shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-max`}
          >
            <div className="flex justify-end cursor-pointer text-2xl mb-2 text-red-600  "></div>

            <div className="flex place-content-between border-b pb-5">
              <h2 className=" text-xl font-semibold ">
                Available Question Sets
              </h2>
              <Tooltip
                title="Close"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full transition-all ease-in-out duration-500"
                  onClick={handleModalClose}
                >
                  <i className="fa-solid fa-xmark"></i>{" "}
                </button>
              </Tooltip>
            </div>

            <div className="py-6 border-b ">
              <p className="">
                <span>
                  <span>
                    <i className="fa-solid fa-circle-info text-blue-400 pe-2"></i>
                  </span>
                  {userQuestionsAttempts !== 0 && isPlanExpired == "false" ? (
                    <span>
                      You have{" "}
                      <span className="font-semibold">
                        <span className="font-semibold">
                          {usedQuestionSets}
                        </span>{" "}
                        out of 25 questions sets
                      </span>{" "}
                      available for this month till{" "}
                      <span className="font-semibold">
                        {formattedMonthEndDate}.
                      </span>{" "}
                    </span>
                  ) : (
                    <span>
                      You've hit the{" "}
                      <span className=" font-semibold text-red-500">
                        25 sets/month
                      </span>{" "}
                      limit for question generation.
                    </span>
                  )}
                </span>
                {/* 
                <span>
                  You have <span className="font-semibold">23 out of 25</span>{" "}
                  questions sets available for this month till 24/05/2023.{" "}
                </span> */}
                {""}
              </p>

              <p className="mt-5">
                <span>
                  <span>
                    <i className="fa-solid fa-circle-info text-blue-400 pe-2"></i>
                  </span>
                  For more questions sets, upgrade to the{" "}
                  <span className=" font-semibold">Unlimited plan</span> by
                  sharing your OpenAI Secured Key.
                </span>
              </p>
            </div>

            <div className="flex place-content-between gap-8">
              <Tooltip
                title="Manage Subscription"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] transition-all ease-in-out duration-500"
                  onClick={handleQuillionzProModal}
                >
                  Manage Subscription
                </button>
              </Tooltip>

              <Tooltip
                title="Upgrade to Unlimited"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] transition-all ease-in-out duration-500"
                  onClick={handleGoUnlimited}
                >
                  Upgrade to Unlimited
                </button>
              </Tooltip>
            </div>
          </div>
        ) : remainingQuestionSets != 0 && !upgradeToUnlimitedClicked ? (
          <div className="upgradeToProBackContent  ">
            <div className="bg-white  shadow-2xl rounded  animate-fade-slide-down px-2 pt-2 pb-5">
              <div className="flex place-content-between mx-2 p-3 border-b">
                <h2 className="text-xl font-semibold">
                  <Tooltip
                    title="Go Back"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <button
                      className="text-[#6c757d] text-lg pe-2 transition-all ease-in-out duration-500"
                      onClick={handleGoBack}
                    >
                      <span>
                        <i className="fa-solid fa-arrow-left pe-3"></i>{" "}
                      </span>
                    </button>{" "}
                  </Tooltip>
                  <span>Subscribe To : </span>
                </h2>

                <Tooltip
                  title="Close"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full transition-all ease-in-out duration-500"
                    onClick={handleModalClose}
                  >
                    <i className="fa-solid fa-xmark"></i>{" "}
                  </button>
                </Tooltip>
              </div>

              {/* TODO : Piyush ji, the actual Paypal Payment Links for subscriptions have not been incorporated yet. */}
              {/* Subscribe ($99.99/Year) Button */}
              <div className="  text-center px-6 py-2 ">
                <div>
                  <div className="w-full">
                    <Tooltip
                      title="Annual ($99.99)"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      {yearlyForm(email)}
                    </Tooltip>
                  </div>

                  <div className="mt-1">
                    ---------------- OR -----------------
                  </div>
                </div>

                {/* Subscribe ($29.99/Quarter) Button*/}
                <div>
                  <div>
                    <Tooltip
                      title="Quarterly ($29.99)"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      {quaterlyForm(email)}
                    </Tooltip>
                  </div>

                  <div className="mt-1">
                    ---------------- OR -----------------
                  </div>
                </div>

                {/* Subscribe ($14.99/Month) Button*/}
                <div className="pb-3">
                  <Tooltip
                    title=" Monthly ($14.99)"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    {monthlyForm(email)}
                    {/* <button
                      className="mt-2 hover:opacity-90 
                bg-[#f4833d]
               text-white px-4 py-2 rounded w-full"
                    >
                      Monthly ($14.99)
                    </button> */}
                  </Tooltip>
                </div>
              </div>

              {/* Loading overlay */}
              {isLoading && <LoadingOverlay />}
            </div>
          </div>
        ) : (
          <div className="bg-white back-content shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-[35vw]">
            {/* <div className="flex justify-end cursor-pointer text-2xl mb-2 text-red-600  "></div> */}

            <div className="flex place-content-between  pb-5">
              <h2 className="text-xl font-semibold">
                <Tooltip
                  title="Go Back"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="text-[#6c757d] text-lg pe-2 transition-all ease-in-out duration-500"
                    onClick={handleGoBack}
                  >
                    <span>
                      <i className="fa-solid fa-arrow-left pe-3"></i>{" "}
                    </span>
                  </button>{" "}
                </Tooltip>
                <span>Upgrade to unlimited plan </span>
              </h2>
              <div style={{ display: "inline-block" }}>
                <Tooltip
                  title="Close"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                  // Add the following two lines
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <button
                    className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full transition-all ease-in-out duration-500"
                    onClick={handleModalClose}
                  >
                    <i className="fa-solid fa-xmark"></i>{" "}
                  </button>
                </Tooltip>
              </div>
            </div>

            <div className="pt-1">
              <p className="opacity-60 italic pb-3">
                <span>
                  <i className="fa-solid fa-circle-info text-blue-400 pe-2"></i>
                </span>
                For unlimited questions sets, kindly provide your OpenAI Key
                below. This key will be used securely under your existing
                subscription plan.
              </p>

              <p className="mt-3">
                <span className="">Email : </span>
                <span className="px-2 font-semibold">{email}</span>
              </p>
              {dummyPasswordField}
              <p className="flex px-2 mt-5 border border-[#fcd6bf] rounded-lg outline-[#f4833d] ">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter Your Open AI Key"
                  className="p-2 outline-none w-full bg-none"
                  value={openAIKey}
                  onChange={(e) => setOpenAIKey(e.target.value)}
                />

                <Tooltip
                  title={isPasswordVisible ? "Hide API Key" : "Show API Key"}
                  arrow
                  key={isPasswordVisible ? "visible" : "hidden"} // add this line
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <button
                    onClick={togglePasswordVisibility}
                    className="text-[#f4833d] px-3 transition-all ease-in-out duration-500"
                    key={isPasswordVisible ? "visible" : "hidden"} // add this line
                  >
                    {isPasswordVisible ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </button>
                </Tooltip>
              </p>

              <p
                className={`flex mt-5 px-2 border border-[#fcd6bf] rounded-lg outline-[#f4833d] `}
              >
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm Your Open AI Key"
                  className="p-2 outline-none w-full"
                  value={confirmOpenAIKey}
                  onChange={(e) => setConfirmOpenAIKey(e.target.value)}
                />

                <Tooltip
                  title={
                    isConfirmPasswordVisible ? "Hide API Key" : "Show API Key"
                  }
                  arrow
                  key={isConfirmPasswordVisible ? "visible" : "hidden"} // add this line
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <button
                    onClick={toggleConfirmPasswordVisibility}
                    className="text-[#f4833d] px-3 transition-all ease-in-out duration-500"
                    key={isConfirmPasswordVisible ? "visible" : "hidden"}
                  >
                    {isConfirmPasswordVisible ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </button>
                </Tooltip>
              </p>
              {errorMessage && (
                <p className="text-red-400 mb-6 text-sm pt-1 animate-fade-slide-down transition-all ease-in-out duration-500 ">
                  <i className="fa-solid fa-circle-exclamation  text-red-500   text-[13px] mt-2"></i>
                  <span className="px-2 text-red-500   text-[13px] mt-2">
                    {errorMessage}
                  </span>
                </p>
              )}
            </div>

            <div className="flex place-content-end gap-8">
              <Tooltip
                title="Cancel"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`rounded mt-4 px-6 py-2 hover:opacity-90 text-[#6c757d] hover:bg-[#6c757d] hover:border-[#6c757d] hover:text-white border border-[#6c757d]  transition-all ease-in-out duration-500`}
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </Tooltip>

              <Tooltip
                title="Submit"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] transition-all ease-in-out duration-500"
                  onClick={handleOpenAIKeySubmit}
                >
                  Submit
                </button>
              </Tooltip>
            </div>
          </div>
        )}

        {/* Loading overlay */}
        {isLoading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default GoForUnlimitedQuestionsModal;
