/**
 * @author : Amol Rathod
 * @description :  This function allows users to upload text content along with a title to the server. It utilizes the Axios library to make a POST request to the designated endpoint.
 */

import axios from "../api/axios"; // Importing the Axios library for making HTTP requests.

/**
 * Uploads text content along with a title to the server.
 * @param {string} content - The text content to be uploaded.
 * @param {string} title - The title associated with the uploaded content.
 * @returns {Promise} - A Promise that resolves to the response from the server.
 */
export const uploadText = async (content, title, email) => {
  // Making a POST request using Axios to upload the text content.
  const response = await axios.post(
    "/upload_text", // The endpoint URL for uploading text content.
    { raw: content },
    // The content payload in the request body.
    {
      params: { title, email }, // Adding the title as a query parameter.
    }
  );

  return response; // Returning the response from the server.
};
