import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { useTrueOrFalseQuestions } from "../../../hooks/QuestionPage/useTrueOrFalseQuestions";

function TrueOrFalseQuestionsRenderer({
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber,
  activeStep,
  handleTrueOrFalseQuestionsEdit,
  handleTrueOrFalseQuestionDelete,
  handleTrueOrFalseQuestionChange,
  question,
  index,
}) {

  return (
    <>
      {/* {trueFalseQuestions.map((trueFalseQuestion, index) => ( */}
      <div
        key={index}
        className="mb-4  border-slate-100 hover:border-[#f4833d] border-2 hover:border-dotted rounded question-answer-container p-4"
      >
        {/* Question goes here */}
        <div className="flex place-content-between">
          <div className="flex">
            <span>{index + 1}. </span>{" "}
            {question.editing ? (
              // <input
              //   type="text"
              //   ref={(inputRef) => inputRef && inputRef.focus()}
              //   className="w-[65vw]"
              //   value={trueFalseQuestion.question}
              //   onChange={(e) =>
              //     handleTrueOrFalseQuestionChange(index, e.target.value)
              //   }
              //   onBlur={() => handleTrueOrFalseQuestionsEdit(index)} // Exit editing mode on blur
              // />

              <div
                contentEditable="true"
                id={`question-${question.index}`}
                className="wh-question-editable "
                onBlur={(e) => {
                  handleTrueOrFalseQuestionChange(
                    question.index,
                    e.target.textContent
                  );
                  handleTrueOrFalseQuestionsEdit(question.index);
                }}
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
              />
            ) : (
              <span
                onClick={() => handleTrueOrFalseQuestionsEdit(question.index)}
                className="cursor-pointer"
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
              />
            )}
          </div>


          <div className="flex edit-delete-icons">
            {/* Edit Icon */}
            <Tooltip
              title="Edit"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleTrueOrFalseQuestionsEdit(question.index)}
                className="text-indigo-500 mr-2"
              >
                <i className="fa-solid fa-pen-to-square"></i>{" "}
              </button>
            </Tooltip>

            {/* Delete Icon */}
            <Tooltip
              title="Delete"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={() => handleTrueOrFalseQuestionDelete(question.index)}
                className="text-red-500"
              >
                <i className="fa-solid fa-trash"></i>{" "}
              </button>
            </Tooltip>
          </div>
        </div>

        {/* True or False Option */}
        <div className=" p-2 rounded ">
          <p
            className={`w-2/3 p-2 mb-1 rounded ${
              "True" === question.answer ? "bg-[#c0f0e2]" : "bg-[#fdecf6]"
            }`}
          >
            True {question.true_option}
          </p>
          <p
            className={`w-2/3 p-2 mb-1 rounded ${
              "False" === question.answer ? "bg-[#c0f0e2]" : "bg-[#fdecf6]"
            }`}
          >
            False {question.false_option}
          </p>
        </div>

        {/* Correct Answer */}
        <p>
          <strong>Answer:</strong> {question.answer}
        </p>
      </div>
      {/* ))} */}
    </>
  );
}

export default TrueOrFalseQuestionsRenderer;
