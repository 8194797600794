/**
 * @author Amol Rathod
 */

// Import the 'axios' library to make HTTP requests to the API.
import axios from "../api/axios";

/**
 * Function to initiate the process of changing a user's password.
 *
 * @param {string} id - The user's ID or identifier.
 * @param {string} oldPassword - The user's current or old password.
 * @param {string} newPassword - The desired new password.
 * @param {string} confirmPassword - The confirmation of the new password.
 * @returns {Promise} - A promise representing the asynchronous password change request.
 * @throws {Error} - If there is an issue during the password change process.
 */
export const changePassword = async (
  id,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    // Send an HTTP PUT request to the '/change_password' endpoint to change the password.
    const response = await axios.put("/change_password", {
      Id: id,
      oldPassword,
      newPassword,
      confirmPassword,
    });

    // Return the response data as the result of the password change process.
    return response;
  } catch (error) {
    // If an error occurs during the password change process, re-throw the error.
    throw error;
  }
};
