import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";

function InterpretiveQuestionsRenderer({
  questions,
  handleInterpretiveQuestionChange,
  handleInterpretiveAnswerChange,
  handleInterpretiveQuestionsEdit,
  handleInterpretiveAnswersEdit,
  handleInterpretiveQuestionDelete,
}) {
  let totalQuestion = 1;

  return (
    <>
      {questions.map((interpretiveQuestion, index) => (
        <div
          key={index}
          className="mb-4 h-auto  border-slate-100 hover:border-[#f4833d] border-2 hover:border-dotted rounded question-answer-container p-4"
        >
          <div className="flex place-content-between">
            <p className="flex">
              <span>{totalQuestion++}. </span>{" "}
              {interpretiveQuestion.editing ? (
                <div
                  contentEditable
                  id={`question-${index}`}
                  className=""
                  onBlur={(e) => {
                    handleInterpretiveQuestionChange(
                      index,
                      e.target.textContent
                    );
                    handleInterpretiveQuestionsEdit(index);
                  }}
                  dangerouslySetInnerHTML={{
                    __html: interpretiveQuestion.question,
                  }}
                />
              ) : (
                <span
                  onClick={() => handleInterpretiveQuestionsEdit(index)}
                  className="cursor-pointer"
                  dangerouslySetInnerHTML={{
                    __html: interpretiveQuestion.question,
                  }}
                />
              )}
            </p>
            <div className="flex edit-delete-icons">
              <Tooltip
                title="Edit"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  onClick={() => handleInterpretiveQuestionsEdit(index)}
                  className="text-indigo-500 mr-2"
                >
                  <i className="fa-solid fa-pen-to-square"></i>{" "}
                </button>
              </Tooltip>

              <Tooltip
                title="Delete"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  onClick={() => handleInterpretiveQuestionDelete(index)}
                  className="text-red-500"
                >
                  <i className="fa-solid fa-trash"></i>{" "}
                </button>
              </Tooltip>
            </div>
          </div>
          <div>
            <p className="font-bold">Answer Source:</p>{" "}
            {interpretiveQuestion.editingAnswer ? (
              <div
                contentEditable
                className="editable-answer"
                onBlur={(e) => {
                  handleInterpretiveAnswerChange(index, e.target.textContent);
                  handleInterpretiveAnswersEdit(index);
                }}
                dangerouslySetInnerHTML={{
                  __html: interpretiveQuestion.answer,
                }}
              />
            ) : (
              <span
                onClick={() => handleInterpretiveAnswersEdit(index)}
                className="cursor-pointer"
                dangerouslySetInnerHTML={{
                  __html: interpretiveQuestion.answer,
                }}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default InterpretiveQuestionsRenderer;
