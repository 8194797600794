/*
  Author: Amol Rathod
  Description: This file contains an array of occupation options used for selection in a form.
*/

// Define an array of occupation options with corresponding values and labels.
export const occupations = [
  {
    value: "Select Occupation (Required)",
    label: "Select Occupation (Required)",
  },
  {
    value: "Teacher",
    label: "Teacher",
  },
  {
    value: "K12 Teacher",
    label: "K12 Teacher",
  },
  {
    value: "Higher Ed Professor",
    label: "Higher Ed Professor",
  },
  {
    value: "Corporate Trainer",
    label: "Corporate Trainer",
  },
  {
    value: "Private Tutor",
    label: "Private Tutor",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Assessment Head",
    label: "Assessment Head",
  },
  {
    value: "L&D Department",
    label: "L&D Department",
  },
  {
    value: "Educator",
    label: "Educator",
  },
  {
    value: "Corporate Trainer",
    label: "Corporate Trainer",
  },
  {
    value: "University Head",
    label: "University Head",
  },
  {
    value: "Sales Trainer",
    label: "Sales Trainer",
  },
  {
    value: "Management",
    label: "Management",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "General sciences",
    label: "General sciences",
  },
  {
    value: "Other",
    label: "Other",
  },
];
