import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import {  useNavigate } from "react-router-dom";
import LoadingOverlay from "../overlays/LoadingOverlay";
import { yearlyForm, quaterlyForm, monthlyForm } from "../../utils/SubmitContent/PaypalForms";

export default function QuillionzProUpgradeModal({ isOpen, onClose }) {
  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);
  const email = localStorage.getItem("email");
  // Function to handle closing the modal
  const handleModalClose = () => {
    onClose(); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        className="bg-[00000080]"
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",

              //   backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // Set the height to 100% of the viewport height
            }}
          >
            <div className="bg-white  shadow-2xl rounded-lg  animate-fade-slide-down w-max">
              <div className="flex place-content-between mx-2 p-3 border-b">
                <h2 className="text-lg font-semibold text-center">
                  Subscribe to :
                </h2>
                <Tooltip
                  title="Close"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <button
                    className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
                    onClick={handleModalClose}
                  >
                    <i className="fa-solid fa-xmark"></i>{" "}
                  </button>
                </Tooltip>
              </div>

              {/* TODO : Piyush ji, the actual Paypal Payment Links for subscriptions have not been incorporated yet. */}
              {/* Subscribe ($99.99/Year) Button */}
              <div className="text-center px-6 py-2">
                <div>
                  <div className="w-auto">
                    <Tooltip
                      title="Annual ($99.99)"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      {yearlyForm(email)}
                    </Tooltip>
                  </div>

                  <div className="mt-1">
                    ---------------- OR -----------------
                  </div>
                </div>

                {/* Subscribe ($29.99/Quarter) Button*/}
                <div>
                  <div>
                    <Tooltip
                      title="Quarterly ($29.99)"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      {quaterlyForm(email)}
                    </Tooltip>
                  </div>

                  <div className="mt-1">
                    ---------------- OR -----------------
                  </div>
                </div>

                {/* Subscribe ($14.99/Month) Button*/}
                <div className="pb-3">
                  <Tooltip
                    title=" Monthly ($14.99)"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    {monthlyForm(email)}
                  </Tooltip>
                </div>
              </div>

              {/* Loading overlay */}
              {isLoading && <LoadingOverlay />}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
