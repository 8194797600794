/**
 * @author Amol Rathod
 */

import {
  emailPattern,
  passwordPattern,
} from "../../Patterns/validationPatterns";

/**
 * Handles email validation for sign-up.
 *
 * @param {string} email - The email address to validate.
 * @param {function} setErrorCallback - Callback function to set validation errors in the state.
 * @returns {boolean} - True if there are validation errors, false otherwise.
 */
export const handleSignupEmailValidation = (email, setErrorCallback) => {
  if (!email.match(emailPattern)) {
    setErrorCallback((prevErrors) => ({
      ...prevErrors,
      email: "Please enter a valid email address.",
    }));
    return true; // Indicates that there are validation errors
  } else {
    setErrorCallback((prevErrors) => ({ ...prevErrors, email: "" }));
    return false; // Indicates that there are no validation errors
  }
};

/**
 * Handles password validation for sign-up.
 *
 * @param {string} password - The password to validate.
 * @param {function} setErrorCallback - Callback function to set validation errors in the state.
 * @returns {boolean} - True if there are validation errors, false otherwise.
 */
export const handleSignupPasswordValidation = (password, setErrorCallback) => {
  if (!password.match(passwordPattern)) {
    setErrorCallback((prevErrors) => ({
      ...prevErrors,
      password:
        "The password should be at least 8 characters long without any spaces, and should be a mix of letters, numbers, and special characters.",
    }));
    return true; // Indicates that there are validation errors
  } else {
    setErrorCallback((prevErrors) => ({ ...prevErrors, password: "" }));
    return false; // Indicates that there are no validation errors
  }
};

/**
 * Handles name validation for sign-up.
 *
 * @param {string} name - The name (first or last) to validate.
 * @param {string} errorKey - The key to identify which name (first or last) is being validated.
 * @param {function} setErrorCallback - Callback function to set validation errors in the state.
 * @returns {boolean} - True if there are validation errors, false otherwise.
 */
export const handleSignupNameValidation = (
  name,
  errorKey,
  setErrorCallback
) => {
  if (!name.match(/[a-zA-Z]/)) {
    setErrorCallback((prevErrors) => ({
      ...prevErrors,
      [errorKey]: `Please enter your ${
        errorKey === "firstName" ? "first" : "last"
      } name.`,
    }));
    return true; // Indicates that there are validation errors
  } else {
    setErrorCallback((prevErrors) => ({ ...prevErrors, [errorKey]: "" }));
    return false; // Indicates that there are no validation errors
  }
};
