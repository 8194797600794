import axios from "./axios";

export const Privacy_Policy = async (email) =>{
    try {
      const response = await axios.post('/privacypolicy',{
        email,
    })
    return response
    }catch(err){
        throw err;
    }
}