import { Link, useLocation, useNavigate } from "react-router-dom";

import pageNotFoundImg from "../../../assets/images/page-not-found.png";
import quillionzAppLogo from "../../../assets/images/quillionz_app_logo.png";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { AxiosError } from "axios";

const MissingPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const isNetworkError = JSON.parse(
    new URLSearchParams(location.search).get("isNetworkError")
  );

  const goBack = () => navigate(-1);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isServerDown, setIsServerDown] = useState(false);

  const checkServerStatus = async () => {
    try {
      // Replace this with actual server check logic
      const response = await axios();
      if (!response.ok) {
        setIsServerDown(true);
      } else {

        setIsServerDown(false);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setIsServerDown(true);
      }

    }
  };

  useEffect(() => {
    // Check server status when the component mounts
    checkServerStatus();

    // Listen for online/offline events
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);


  return (
    <section className="flex justify-center items-center h-screen">
      <div className="bg-white w-1/3 h-screen flex justify-center items-center">
        <img src={pageNotFoundImg} alt="" />
      </div>

      <div className="bg-[#0c3f59] w-2/3 h-screen flex flex-col justify-center items-center">
        <div className="flex ">
          <Link to="/">
            <Tooltip
              title="Go to Home Page"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <img className="w-[150px]" src={quillionzAppLogo} alt="" />
            </Tooltip>
          </Link>
        </div>

        <div className="text-white text-center">
          <div className="flex flex-col justify-center items-center">
            <p className="text-[80px] font-thin">Oops,</p>
            {isOnline ? (
              isServerDown ? (
                <div className="w-3/4 text-center ">
                  <p className="text-[20px] font-thin pb-4 my-4">
                    We're having trouble connecting to our server. This could be
                    due to network issues or server downtime.
                  </p>

                  <p className="text-[16px] font-medium">
                    Please check your internet connection and try again or
                    return to the
                    <Tooltip
                      title="Go to Previous Page"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <span
                        className="ps-1 text-[#47d2ff] cursor-pointer hover:opacity-80 transition ease-in-out delay-150   hover:underline"
                        onClick={goBack}
                      >
                        previous page.
                      </span>
                    </Tooltip>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-[30px] font-thin">
                    looks like this page doesn’t exist,
                  </p>
                  <p className="text-[30px] font-thin pb-2">sorry!</p>

                  <p className="text-[16px] font-medium">
                    Maybe you could check the URL you entered? Or just head over
                    to our{" "}
                    <Link to="/">
                      <Tooltip
                        title="Go to Home Page"
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                      >
                        <span className="text-[#47d2ff] cursor-pointer hover:opacity-80 transition ease-in-out delay-150   hover:underline">
                          home page.
                        </span>
                      </Tooltip>
                    </Link>
                  </p>
                </div>
              )
            ) : (
              <div className="w-3/4 text-center ">
                <p className="text-[25px] font-light my-5 ">
                  You are currently offline. Please ensure your internet
                  connection and try again.
                </p>

                <p className="text-[20px] ">
                  <span>Return to</span>
                  <Link className="/">
                    <Tooltip
                      title="Go to Home Page"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <span className="ps-1 text-[#47d2ff] cursor-pointer hover:opacity-80 transition ease-in-out delay-150   hover:underline">
                        home page.
                      </span>
                    </Tooltip>
                  </Link>
                </p>
              </div>
            )}
          </div>

          {/* <div className="flex-grow">
            <button onClick={goBack}>Go Back</button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default MissingPage;
