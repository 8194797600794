import axios from "../api/axios";

/**
 * Retrieves user GPT keys from the server.
 * @param {string} userGptKey - The user's GPT key.
 * @param {string} confirmUserGptKey - The confirmation GPT key.
 * @param {string} email - The user's email address.
 * @returns {Promise<object>} - A Promise that resolves to the response from the server, which contains the user GPT keys.
 * @throws {Error} - If an error occurs during the API request.
 */
export const upgradeToUnlimited = async (
  Usergpt_key,
  ConfirmUsergpt_key,
  email
) => {
  try {
    // Send a POST request to the server to get user GPT keys.
    const response = await axios.post("/getusergptkey", {
      Usergpt_key,
      ConfirmUsergpt_key,
      email,
    });

    // Return the response object, which contains the user GPT keys.
    return response; // Alternatively, you can extract and return specific data from the response.
  } catch (error) {
    // If an error occurs during the API request, re-throw the error.
    throw error;
  }
};
